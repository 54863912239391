<div class="container-fluid p-4">
  <div class="row">
    <div class="col ps-0">
      <ul class="nav nav-tabs border-bottom-0">
        <li class="nav-item">
          <a class="nav-link active">Reports</a>
        </li>
      </ul>
    </div>
    <div class="col col-auto pe-0">
      <input
        type="text"
        class="form-control"
        placeholder="Search..."
        style="min-width: 300px"
        [(ngModel)]="searchTerm"
        (input)="searchReports()"
      />
    </div>
  </div>
  <div class="row shadow position-relative p-2">
    <table
      class="table table-borderless table-striped table-hover"
      style="font-size: 18px"
    >
      <thead class="border-bottom">
        <tr>
          <th role="button" (click)="sort('name')">Name</th>
          <th>Date</th>
          <th role="button" (click)="sort('type')">Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let report of pagedReports | orderBy : key : reverse"
          class="border-bottom"
        >
          <td>{{ report.name }}</td>
          <td>{{ report.date }}</td>
          <td>{{ report.type }}</td>
          <td>
            <button
              class="btn btn-green ps-3 pe-3 pt-2 pb-2"
              (click)="viewReport(report.type)"
            >
              View
            </button>
            <button
              class="btn btn-green ps-3 pe-3 pt-2 pb-2 ms-3"
              (click)="sendReport(report.name)"
            >
              Send
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
