<div class="container-fluid p-4">
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" href="#">Demo</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="row shadow position-relative">
        <div class="p-2 bg-white border-top">
            <div class="col">
                <div class="row">
                    <div class="col">
                        <h2>Demo Setting</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-auto">
                        <button class="btn btn-green" (click)="authService.changeUser('1')">Delete All</button>
                    </div>
                    <div class="col col-auto">
                        <button class="btn btn-green" (click)="authService.changeUser('2')">Reset to Default</button>
                    </div>
                    <div class="col col-auto">
                        <button class="btn btn-green" (click)="authService.changeUser('3')">Import Data</button>
                    </div>
                    <div class="col col-auto">
                      <button class="btn btn-green"> <a class="btn-green" href="https://demo.api.rbc.asvin.io/swagger" target="_blank">APIs</a></button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
