<div class="row">
    <div class="col">
        <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Filter:</label>
                <div class="col-auto me-auto">
                    <input
                            id="table-complete-search"
                            type="text"
                            class="form-control"
                            name="searchTerm"
                            [(ngModel)]="service.searchTerm"
                    />
                </div>
                <div class="col-auto align-self-end">
                    <ngb-pagination
                            [collectionSize]="(total$ | async)!"
                            [(page)]="service.page"
                            [pageSize]="service.pageSize"
                            [maxSize]="10"
                            [rotate]="true"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
            </div>

            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col" sortable="Score" (sort)="onSort($event)">RBC-Index</th>
                    <th scope="col" sortable="Name" (sort)="onSort($event)">Name</th>
                    <th scope="col" sortable="High" (sort)="onSort($event)">
                        <img [ngSrc]="imgSourceForLevelWithRisk('high')" alt="vulnerability-high"
                             height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Medium" (sort)="onSort($event)">
                        <img [ngSrc]="imgSourceForLevelWithRisk('medium')" alt="vulnerability-medium"
                             height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Low" (sort)="onSort($event)">
                        <img [ngSrc]="imgSourceForLevelWithRisk('low')" alt="vulnerability-low"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let facility of facilities$|async; index as i">
                    <td>{{ facility.Score | number: '1.1-1' }}/10</td>
                    <td>
                        <div  class="text-green asset-detail">
                            <ngb-highlight role="button" (click)="facilityWasClicked($event)" [result]=" facility.Name" [term]="service.searchTerm" [attr.data-facility] = facility.id></ngb-highlight>
                        </div>
                    </td>
                    <td>{{ facility.High | number }}</td>
                    <td>{{ facility.Medium | number }}</td>
                    <td>{{ facility.Low | number }}</td>
                    <td>{{ facility.Low! + facility.Medium! + facility.High!}} </td>
                </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>
