import { Component } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { Subscription } from 'rxjs';
import { SnapshotData } from 'src/app/core/models/snapshot-data';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-risk-level-history-chart',
  templateUrl: './risk-level-history-chart.component.html',
  styleUrls: ['./risk-level-history-chart.component.scss']
})
export class RiskLevelHistoryChartComponent {
  private riskLevelHistoryChart!: Chart<any>;
  private snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' };
  private riskLevelSnapshotSubscription!: Subscription;
  public isChartReady = false;

  constructor(
    private apiService: ApiService,
  ) {
    Chart.register(...registerables);
  }

  ngOnInit() {
    this.riskLevelSnapshotSubscription = this.apiService.getRiskLevelSnapshot().subscribe(snapshot => {
      if (Object.keys(snapshot).length != 0) {
        this.snapshotData = snapshot;
        this.drawRiskLevelHistoryChart();
      }
    })
  }
  ngOnDestroy() {
    this.riskLevelHistoryChart?.destroy()
    this.riskLevelSnapshotSubscription!.unsubscribe();
    this.apiService.changeRiskLevelSnapshot({} as SnapshotData);
  }

  private drawRiskLevelHistoryChart() {
    this.riskLevelHistoryChart != undefined ? this.riskLevelHistoryChart.destroy() : '';
    const ctx2 = <HTMLCanvasElement>document.getElementById('riskLevelHistoryChart')!;
    this.riskLevelHistoryChart = new Chart(ctx2, {
      type: 'bar',
      data: {
        labels: environment.DEMO_ENABLE ? HelperService.getLastWeekDates() : this.snapshotData.dates,
        datasets: [
          {
            label: 'High',
            data: this.snapshotData.high,
            backgroundColor: [
              '#D10C15',
            ],
            stack: 'stack'
          },
          {
            label: 'Medium',
            data: this.snapshotData.medium,
            backgroundColor: [
              '#F19100',
            ],
            stack: 'stack'
          },
          {
            label: 'Low',
            data: this.snapshotData.low,
            backgroundColor: [
              '#AFCA0B'
            ],
            stack: 'stack'
          },
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
          },
          tooltip: {
            mode: 'index'
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Last one week'
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: `Number of ${this.snapshotData.type}`
            }
          }
        },
        // animation: {
        //   onComplete: (e) => {
        //     const canvasDataUrl = ctx2.toDataURL('image/png');
        //     console.log(canvasDataUrl);
        //   }
        // }
      }
    });
    this.isChartReady = true;
  }
}
