import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Chart, registerables } from "chart.js";
import { Subscription } from "rxjs";
import { SnapshotData } from 'src/app/core/models/snapshot-data';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import { Location } from "../../core/models/location";
import { ApiService } from "../../services/api.service";
import { LoadingService } from "../../services/loading.service";
import { ToastService } from "../../services/toast.service";
import { PdfViewerComponent } from 'src/app/components/pdf-viewer/pdf-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RiskLevel } from 'src/app/core/models/risk-level';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  activeTab = 1;
  isFacilityEnable: boolean = environment.FACILITY_ENABLE;
  isGlobalDoughnutChartReady = false;
  // used in table placeholder
  public numberArray: number[] = Array.from({ length: 2 }, (_, index) => index + 1);

  private locationSubscription!: Subscription;
  private facilitySubscription!: Subscription;
  private graph1!: Chart<any>;
  private locationHistoryDashboardChart!: Chart<any>;
  private locationSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
  public locationRanking: RiskLevel = { 'high': 0, 'medium': 0, 'low': 0, type: '' }

  private _locations: Location[] = [];
  get locations(): Location[] {
    return this._locations;
  }

  set locations(value: Location[]) {
    if (value !== this._locations) {
      this._locations = value;
      this.updateAllLocationsSnapshotData(HelperService.getDateString(7), HelperService.getDateString());
      this.locationRanking = { 'high': 0, 'medium': 0, 'low': 0, type: ''};
      for (let location in value) {
        var riskLevel = HelperService.RBCIndexToRiskLevel(value[location].Score!);
        if (riskLevel == "high") {
          this.locationRanking.high++
        } else if (riskLevel == "medium") {
          this.locationRanking.medium++
        } else {
          this.locationRanking.low++
        }
      }
      this.locationRanking.type = 'location'
      //this.drawDoughnutChart()
      this.apiService.changeRiskLevelDoughnut(this.locationRanking)
    }
  }

  private _selectedLocation?: Location
  get selectedLocation(): Location | undefined {
    return this._selectedLocation;
  }

  set selectedLocation(value: Location | undefined) {
    this._selectedLocation = value;
    this.apiService.changeLocation(value);
    if (value != undefined) {
      this.activeTab = 2
    } else {
      this.activeTab = 1
    }
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private modalService: NgbModal
  ) {
    Chart.register(...registerables);
  }


  ngOnInit(): void {
    this.locationSubscription = this.apiService.currentLocations.subscribe(
      response => {
        if (response.length > 0) {
          this.locations = response;
          //var graph = HelperService.formateGraphData(response, "Locations");
          var map = HelperService.formateWorldData(response);
          //this.apiService.changeGraph(graph);
          this.apiService.changeMap(map);
        } else {
          this.refreshData()
        }
      }
    )
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
    if (!this.isFacilityEnable && this.facilitySubscription)
      this.facilitySubscription.unsubscribe();
  }

  refreshData(manual = false) {
    this.loadingService.addRequest()
    this.apiService.getAllLocations()
      .subscribe(response => {
        this.apiService.changeLocations(response.Data);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        }
        this.loadingService.removeRequest()
      });
  }

  updateSelectedLocationSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getLocationSnapshotByLocationIdAndDate(this.selectedLocation!.id, fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          var locationSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
          for (const snap in response.reverse()) {
            locationSnapshotData.dates.push(response[snap]['Day'].substring(5))
            if (this.isFacilityEnable) {
              locationSnapshotData.low.push(response[snap]['Low'])
              locationSnapshotData.medium.push(response[snap]['Medium'])
              locationSnapshotData.high.push(response[snap]['High'])
            }
            locationSnapshotData.score.push(response[snap]['Score'])
          }
          this.apiService.changeIndexSnapshot(locationSnapshotData)
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
  }

  updateAllLocationsSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getAllLocationSnapshotByDate(fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          var locationSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
          for (const snap in response) {
            locationSnapshotData.dates.push(response[snap]['Day'].substring(5))
            locationSnapshotData.low.push(response[snap]['Low'])
            locationSnapshotData.medium.push(response[snap]['Medium'])
            locationSnapshotData.high.push(response[snap]['High'])
          }
          locationSnapshotData.type = "Locations"
          this.apiService.changeRiskLevelSnapshot(locationSnapshotData)
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
  }

  indexToImgSrc(score: number, type: string) {
    return HelperService.RBCIndexToImageSrc(score, type);
  }

  selectLocation($event: any) {
    var locationId: any
    if ($event.target)
      locationId = $event.target.getAttribute('data-location')
    else
      locationId = $event.locationId
    this.apiService.getLocationById(locationId)
      .subscribe((response) => {
        this.selectedLocation = response.Data;
        this.updateSelectedLocationSnapshotData(HelperService.getDateString(7), HelperService.getDateString())
        // get 1st facility of the location if facility level is disabled
        if (!this.isFacilityEnable) {
          this.facilitySubscription = this.apiService.getFacilityById(this.selectedLocation!.FacilityList[0].id).subscribe(resFacility => {
            this.apiService.changeFacility(resFacility.Data);
          })
        }
      })
  }
  selectFacility($event: any) {
    this.router.navigate(['/dashboard/', this.selectedLocation!.id, $event.target.getAttribute('data-facility')])
  }

  // used when Facility level is disabled
  selectSegment($event: any) {
    this.router.navigate(['/dashboard/', this.selectedLocation!.id, this.selectedLocation!.FacilityList[0].id, $event.target.getAttribute('data-segment')])
  }

  // private drawDoughnutChart() {
  //   this.graph1 != undefined ? this.graph1.destroy() : '';

  //   const ctx = <HTMLCanvasElement>document.getElementById('doughnutDashboardChart')!;

  //   this.graph1 = new Chart(ctx, {
  //     type: 'doughnut',
  //     data: {
  //       labels: ['High', 'Medium', 'Low'],
  //       datasets: [{
  //         data: [this.locationRanking.high, this.locationRanking.medium, this.locationRanking.low],
  //         borderWidth: 1,
  //         backgroundColor: [
  //           '#D10C15',
  //           '#F19100',
  //           '#AFCA0B'
  //         ],
  //         hoverOffset: 4
  //       }]
  //     },
  //     plugins: [{
  //       id: "doughnutDashboardChart",
  //       beforeInit: function (chart, args, options) {
  //         const datasets = chart.data.datasets!;
  //         if (chart.canvas.id === "doughnutDashboardChart") {
  //           const ul = document.createElement('ul');
  //           chart?.data?.labels?.forEach((label, index) => {
  //             const labelString = label as string;
  //             ul.innerHTML += `
  //             <li>
  //               <img src="assets/images/location-${labelString.toLowerCase()}.svg" style="width:30px; height:30px"></img>
  //               ${label}: ${datasets[0].data[index]}
  //             </li>
  //           `;
  //           });
  //           return document!.getElementById("donut-dashboard-legend")!.innerHTML = ul.outerHTML;
  //         }

  //         return;
  //       }
  //     }],
  //     options: {
  //       plugins: {
  //         legend: {
  //           display: false
  //         }
  //       }
  //     }
  //   });
  //   this.isGlobalDoughnutChartReady = true;
  // }

  generateReport(): void {
    const modalRef = this.modalService.open(PdfViewerComponent);
    modalRef.componentInstance.reportUrl = '/assets/reports/RiskByContext_Report_Global.pdf';
  }
  // private drawLocationHistoryChart() {
  //   this.locationHistoryDashboardChart != undefined ? this.locationHistoryDashboardChart.destroy() : '';
  //   const ctx2 = <HTMLCanvasElement>document.getElementById('locationHistoryDashboardChart')!;
  //   this.locationHistoryDashboardChart = new Chart(ctx2, {
  //     type: 'bar',
  //     data: {
  //       labels: this.locationSnapshotData.dates,
  //       datasets: [
  //         {
  //           label: ' High',
  //           data: this.locationSnapshotData.high,
  //           backgroundColor: [
  //             '#D10C15',
  //           ],
  //           stack: 'stack'
  //         },
  //         {
  //           label: 'Medium',
  //           data: this.locationSnapshotData.medium,
  //           backgroundColor: [
  //             '#F19100',
  //           ],
  //           stack: 'stack'
  //         },
  //         {
  //           label: 'Low',
  //           data: this.locationSnapshotData.low,
  //           backgroundColor: [
  //             '#AFCA0B'
  //           ],
  //           stack: 'stack'
  //         },
  //       ]
  //     },
  //     options: {
  //       responsive: true,
  //       plugins: {
  //         legend: {
  //           display: false
  //         },
  //         title: {
  //           display: true,
  //         },
  //         tooltip: {
  //           mode: 'index'
  //         },
  //       },
  //       interaction: {
  //         mode: 'nearest',
  //         axis: 'x',
  //         intersect: false
  //       },
  //       scales: {
  //         x: {
  //           title: {
  //             display: true,
  //             text: 'Last one week'
  //           }
  //         },
  //         y: {
  //           stacked: true,
  //           title: {
  //             display: true,
  //             text: this.isFacilityEnable ? "Number of Facilities" : "Number of Segments"
  //           }
  //         }
  //       }
  //     }
  //   });
  // }

  /**
   * Sums up segments in a location
   * @param location - Location
   * @returns {number} - Number of segments in a given location
   */
  segmentsInLocation(location: any): number {
    var segments = 0;
    if (location.FacilityList) {
      location.FacilityList.forEach((facility: any) => {
        if (facility.Segments)
          segments = segments + facility.Segments.length;
      })
    }
    return segments;
  }
}
