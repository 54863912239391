import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Chart, registerables } from 'chart.js';
import Konva from "konva";
import { Subscription } from "rxjs";
import { DeviceParam } from "../../core/models/device-param";
import { Segment } from "../../core/models/segment";
import { SegmentHistory } from "../../core/models/segment-history";
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { LoadingService } from '../../services/loading.service';
import { ToastService } from "../../services/toast.service";

@Component({
  selector: 'app-dashboard-old',
  templateUrl: './dashboard-old.component.html',
  styleUrls: ['./dashboard-old.component.scss']
})

export class DashboardOldComponent implements AfterViewInit, OnInit, OnDestroy {
  sources = {
    0: '/assets/images/segment-circle-low.svg',
    1: '/assets/images/segment-circle-medium.svg',
    2: '/assets/images/segment-circle-high.svg'
  };
  active = 1;
  loading = false

  locationName = 'Stuttgart';
  rbcImage?: string;
  segmentHistory?: SegmentHistory;

  private segmentRanking = { 'critical': 0, 'medium': 0, 'low': 0 }

  private _segments: Array<Segment> = [];
  set segments(value: Array<Segment>) {
    if (value !== this._segments) {
      this._segments = value;
      for (let segment in value) {
        if (value[segment].Score > 7) {
          this.segmentRanking.critical++
        } else if (value[segment].Score > 3) {
          this.segmentRanking.medium++
        } else {
          this.segmentRanking.low++
        }
      }
      this.loadImages(this.sources)
      this.drawDashboardGraphs()
    }
  }

  get segments(): Array<Segment> {
    return this._segments;
  }

  private _selectedSegment?: Segment
  set selectedSegment(value: Segment | undefined) {
    this._selectedSegment = value;
    this.apiService.changeSegment(value);
    this.rbcImage = undefined;
    if (value != undefined) {
      this.active = 2;
      this.historySubscription = this.apiService.getLastSegmentHistoryBySegmentId(value.id).subscribe(
        response => {
          response.Parameters.sort(function (a: DeviceParam, b: DeviceParam) {
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
          })
          this.segmentHistory = response
        }
      )
    } else {
      this.active = 1;
    }

  }
  get selectedSegment(): Segment | undefined {
    return this._selectedSegment;
  }


  private allNetworksTab!: HTMLElement;
  private highNetworksTab!: HTMLElement
  private mediumNetworksTab!: HTMLElement
  private lowNetworksTab!: HTMLElement

  private circles: { low: Array<Konva.Node>, medium: Array<Konva.Node>, critical: Array<Konva.Node> } = { critical: [], medium: [], low: [] }

  private segmentSubscription!: Subscription;
  private historySubscription!: Subscription;

  //Dashboard Variables
  width = 0;
  height = 0;
  stage!: Konva.Stage;
  mainLayer!: Konva.Layer;
  gPositionX = 0;
  gPositionY = 0;
  positionX = 0;
  positionY = 0;
  offsetY = 0;
  rowHeight = 0;

  private _zoomValue = 1;
  set zoomValue(value: number) {
    this._zoomValue = value;
    this.stage.scale({ x: value, y: value })
  }
  get zoomValue(): number {
    return this._zoomValue;
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
    Chart.register(...registerables);
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.segmentSubscription = this.apiService.currentSegments.subscribe(
      response => {
        if (response.length > 0) {
          this.segments = response
        } else {
          this.refreshData();
        }
      }
    )

    this.allNetworksTab = document.getElementById('all-networks-tab')!;
    this.highNetworksTab = document.getElementById('filter-high-tab')!;
    this.mediumNetworksTab = document.getElementById('filter-medium-tab')!;
    this.lowNetworksTab = document.getElementById('filter-low-tab')!;
  }

  ngOnDestroy() {
    this.graph1?.destroy()
    this.graph2?.destroy()

    this.segmentSubscription.unsubscribe()
  }

  refreshData(manual = false) {
    this.loading = true;
    this.gPositionX = 0;
    this.gPositionY = 0;
    this.positionX = 0;
    this.positionY = 0;
    this.offsetY = 0;
    this.rowHeight = 0;
    this.segmentRanking = { 'critical': 0, 'medium': 0, 'low': 0 }

    this.apiService.getAllSegments()
      .subscribe(response => {
        this.segments = response
        this.apiService.changeSegments(response);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        }
        this.loading = false;
      });

  }

  selectSegment($event: any) {
    this.router.navigate(['/dashboard/segment/', this.selectedSegment!.id, $event.target.getAttribute('data-asset')])
  }

  loadImages(sources: Record<number, string>): void {
    this.loadingService.addRequest();

    let images: Record<string, HTMLImageElement> = {};
    let loadedImages = 0;
    let numImages = 0;
    const returnImages: Array<Record<string, HTMLImageElement>> = []

    let sizes = [{ w: 50, h: 50 }, { w: 75, h: 75 }, { w: 100, h: 100 }]

    for (const src in sources) {
      for (let size in sizes) {
        numImages++;
      }
    }
    for (const src in sources) {
      for (let size in sizes) {
        images[size] = new Image(sizes[size].w, sizes[size].h);
        images[size].onload = () => {
          if (++loadedImages >= numImages) {
            this.buildStage(returnImages)
          }
        };
        images[size].src = sources[src];

      }
      returnImages[src] = images
      images = {}
    }

    this.loadingService.removeRequest();
  }

  buildStage(images: Array<Record<string, HTMLImageElement>>) {
    const canvas = document.getElementById('dashboard-canvas');

    if (canvas !== null) {
      this.width = canvas!.offsetWidth;
      this.height = canvas!.offsetHeight;

      this.stage = new Konva.Stage({
        width: this.width,
        height: this.height,
        container: 'dashboard-canvas',
        draggable: true,
        scale: { x: this.zoomValue, y: this.zoomValue }
      });

      this.mainLayer = new Konva.Layer();
      this.stage.add(this.mainLayer);
      this.mainLayer.draw();

      let cGroups: Konva.Group[] = []

      this._segments.sort(function (a: Segment, b: Segment) {
        if (a.NetworkLocation < b.NetworkLocation) { return -1; }
        if (a.NetworkLocation > b.NetworkLocation) { return 1; }
        return 0;
      })

      for (let segment in this._segments) {

        let segmentObj = this._segments[segment];
        let segmentSize = segmentObj.Devices!.length;
        let segmentRanking = segmentObj.Score;
        let imgSize = segmentSize > 50 ? 2 : segmentSize > 30 ? 1 : 0;
        let imgRanking = segmentRanking > 7 ? 2 : segmentRanking > 3 ? 1 : 0;

        let group = cGroups.filter(obj => {
          return obj.name() == segmentObj.NetworkLocation;
        })[0]
        if (group == undefined) {
          this.calculateGroupPosition()

          group = new Konva.Group({
            x: this.gPositionX,
            y: this.gPositionY,
            draggable: false,
            name: segmentObj.NetworkLocation,
          })
          cGroups.push(group)
          this.positionX = 0;
          this.positionY = 0;
        }

        let tempImg = images[imgRanking][imgSize];

        const circle = new Konva.Image({
          x: this.positionX,
          y: this.positionY,
          image: tempImg,
          id: this._segments[segment].id,
          // width: tempImg.width / 4,
          // height: tempImg.height / 4,
        });


        this.calculateCirclePosition(circle);

        circle.x(this.positionX)
        circle.y(this.positionY)

        circle.on('click', () => {
          this.selectedSegment = this.segments.filter(obj => {
            return obj.id === circle.id()
          })[0]
        })
        group.add(circle);

        this.positionX += circle.width();
        switch (imgRanking) {
          case 0:
            group.setAttr('low', true);
            this.circles.low.push(circle);
            break;
          case 1:
            group.setAttr('medium', true);
            this.circles.medium.push(circle);
            break;
          case 2:
            group.setAttr('critical', true);
            this.circles.critical.push(circle);
            break;
        }
      }
      for (let group in cGroups) {
        let attr = cGroups[group].getClientRect()
        let groupColor = "#fff"
        let textColor = "#fff"
        let rect = new Konva.Rect({
          x: attr.x - 5,
          y: attr.y - 5,
          width: attr.width + 10,
          height: attr.height + 10,
          stroke: groupColor
        })
        let text = new Konva.Text({
          x: attr.x - 5,
          y: attr.y - 5,
          height: 10,
          text: cGroups[group].name(),
          fontSize: 10,
          fill: groupColor,
        });

        let textW = text.width()
        let textH = 10
        let rectW = attr.width + 10
        while (textW > rectW) {
          textH += 10
          textW -= rectW
        }
        text.width(rectW)
        text.height(textH)
        text.y(text.y() - textH)

        if (cGroups[group].getAttr('low')) {
          this.circles.low.push(text);
          this.circles.low.push(rect);
          groupColor = '#cccccc'
          textColor = '#999999'
        }
        if (cGroups[group].getAttr('medium')) {
          this.circles.medium.push(text);
          this.circles.medium.push(rect);
          groupColor = '#F19100'
          textColor = '#F19100'
        }
        if (cGroups[group].getAttr('critical')) {
          this.circles.critical.push(text);
          this.circles.critical.push(rect);
          groupColor = '#D10C15'
          textColor = '#D10C15'
        }
        rect.stroke(groupColor)
        text.fill(textColor)

        this.mainLayer.add(text)
        this.mainLayer.add(rect)
        this.mainLayer.add(cGroups[group])
      }

      var menuNode = document.getElementById('menu')!;
      var segmentName = document.getElementById('detail-segment-name')!;
      var locationName = document.getElementById('detail-location-name')!;
      var rbcIndex = document.getElementById('detail-rbc-index')!;
      var assetCount = document.getElementById('detail-segment-assets')!;

      this.stage.on('mouseover', (e) => {
        // prevent default behavior
        //e.evt.preventDefault();
        if (e.target === this.stage) {
          // if we are on empty place of the stage we will do nothing
          return;
        }

        // ignore if clicked not on shape
        const clickOnShape = e.target instanceof Konva.Image;
        if (!clickOnShape) {
          return;
        }
        this.stage.container().style.cursor = 'pointer';

        let selectedSegment = this._segments.filter(obj => {
          return obj.id === e.target.id();
        })[0]

        locationName.innerText = selectedSegment.NetworkLocation
        segmentName.innerText = selectedSegment.Network
        assetCount.innerText = String(selectedSegment.Devices.length)
        rbcIndex.innerText = selectedSegment.Score.toLocaleString('en-us', { maximumFractionDigits: 1 })

        // show menu
        menuNode.style.display = 'initial';
        menuNode.style.left =
          (e.target.getClientRect().x + e.target.getClientRect().width) + 'px';
        menuNode.style.top =
          (e.target.getClientRect().y + e.target.getClientRect().height) + 'px';
      });

      this.stage.on('mouseout', (e) => {
        if (e.target === this.stage) {
          // if we are on empty place of the stage we will do nothing
          return;
        }
        this.stage.container().style.cursor = 'default';
        menuNode.style.display = 'none';
      });

    }
  }

  calculateCirclePosition(image: Konva.Image) {
    this.positionX += HelperService.getRandomArbitrary(20, 50);

    // if (this.positionX + image.width() >= this.stage.width()) {
    //   this.positionX = HelperService.getRandomArbitrary(10, 75);
    //   this.offsetY = this.rowHeight;
    // }
    this.positionY = HelperService.getRandomArbitrary(40 + this.offsetY, 75 + this.offsetY);
    if (this.rowHeight < this.positionY + image.height()) {
      this.rowHeight = this.positionY + image.height()
    }
  }

  calculateGroupPosition() {
    if (this.gPositionX + 300 >= this.stage.width()) {
      this.gPositionX = 0
      this.gPositionY += this.rowHeight
    } else {
      this.gPositionX += this.positionX;
    }
  }

  graph1!: Chart<any>
  graph2!: Chart<any>

  drawDashboardGraphs() {
    this.graph1 != undefined ? this.graph1.destroy() : '';
    this.graph2 != undefined ? this.graph2.destroy() : '';

    const ctx = <HTMLCanvasElement>document.getElementById('doughnutDashboardChart')!;
    const ctx2 = <HTMLCanvasElement>document.getElementById('historyDashboardChart')!;

    this.graph1 = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['High', 'Medium', 'Low'],
        datasets: [{
          data: [this.segmentRanking.critical, this.segmentRanking.medium, this.segmentRanking.low],
          borderWidth: 1,
          backgroundColor: [
            '#D10C15',
            '#F19100',
            '#AFCA0B'
          ],
          hoverOffset: 4
        }]
      },
      plugins: [{
        id: "doughnutDashboardChart",
        beforeInit: function (chart, args, options) {
          const datasets = chart.data.datasets!;
          if (chart.canvas.id === "doughnutDashboardChart") {
            const ul = document.createElement('ul');
            chart?.data?.labels?.forEach((label, index) => {
              const labelString = label as string;
              ul.innerHTML += `
              <li>
                <img src="assets/images/segment-circle-${labelString.toLowerCase()}.svg" style="width:30px; height:30px"></img>
                ${label}: ${datasets[0].data[index]}
              </li>
            `;
            });
            return document!.getElementById("donut-dashboard-legend")!.innerHTML = ul.outerHTML;
          }

          return;
        }
      }],
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }

      //       labels: {
      //         generateLabels: (chart) => {
      //           const datasets = chart.data.datasets!;
      //           const meta = chart.getDatasetMeta(0);
      //           return datasets[0].data.map((data, i) => ({
      //             text: `${chart.data.labels![i]} ${data}`,
      //             fillStyle:  meta.controller.getStyle(i,true)['backgroundColor'],
      //             strokeStyle:  meta.controller.getStyle(i,true)['borderColor'],
      //             index: i
      //           }))
      //         }
      //       },
      //       position: "right"
      //     }
      //   }
      // }
    });

    this.graph2 = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: ['September', 'October', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August'],
        datasets: [
          {
            label: ' Critical',
            data: [12, 19, 3, 4, 6, 6, 7, 8, 9, 5, 6, 11],
            fill: true,
            backgroundColor: [
              '#D10C15',
            ],
          },
          {
            label: 'Medium',
            data: [3, 10, 13, 4, 6, 5, 7, 8, 4, 5, 6, 11],
            fill: true,
            backgroundColor: [
              '#F19100',
            ],
          },
          {
            label: 'Low',
            data: [7, 2, 5, 4, 6, 11, 8, 8, 9, 5, 12, 11],
            fill: true,
            backgroundColor: [
              '#AFCA0B'
            ],
          },
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
          },
          tooltip: {
            mode: 'index'
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            title: {
              display: false,
            }
          },
          y: {
            stacked: true,
            title: {
              display: false,
            }
          }
        }
      }
    });
  }

  showAllNetworks() {
    this.allNetworksTab.classList.add('active')
    this.highNetworksTab.classList.remove('active')
    this.mediumNetworksTab.classList.remove('active')
    this.lowNetworksTab.classList.remove('active')

    this.showCircles(this.circles.low)
    this.showCircles(this.circles.medium)
    this.showCircles(this.circles.critical)
  }

  showLowRiskNetworks() {
    this.allNetworksTab.classList.remove('active')
    this.highNetworksTab.classList.remove('active')
    this.mediumNetworksTab.classList.remove('active')
    this.lowNetworksTab.classList.add('active');

    this.hideCircles(this.circles.medium)
    this.hideCircles(this.circles.critical)
    this.showCircles(this.circles.low)
  }
  showMediumRiskNetworks() {
    this.allNetworksTab.classList.remove('active')
    this.highNetworksTab.classList.remove('active')
    this.mediumNetworksTab.classList.add('active')
    this.lowNetworksTab.classList.remove('active')

    this.hideCircles(this.circles.low)
    this.hideCircles(this.circles.critical)
    this.showCircles(this.circles.medium)
  }
  showHighRiskNetworks() {
    this.allNetworksTab.classList.remove('active')
    this.highNetworksTab.classList.add('active')
    this.mediumNetworksTab.classList.remove('active')
    this.lowNetworksTab.classList.remove('active')

    this.hideCircles(this.circles.low)
    this.hideCircles(this.circles.medium)
    this.showCircles(this.circles.critical)
  }

  hideCircles(circles: Konva.Node[]) {
    for (let circle in circles) {
      circles[circle].hide()
    }
  }
  showCircles(circles: Konva.Node[]) {
    for (let circle in circles) {
      circles[circle].show()
    }
  }
}
