<div class="col" *ngIf="segmentParams.length > 0">
    <table class="table" style="table-layout: fixed;">
        <colgroup>
            <col span="1">
            <col span="1" style="width: 25%;">
            <col span="1" >
        </colgroup>
        <thead>
        <tr>
            <th scope="col" size="1">PARAMETER</th>
            <th scope="col" class="text-center" size="1">THRESHOLD</th>
            <th scope="col" size="1">RELEVANCE</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let segmentParam of segmentParams; let i = index">
            <td>
                <div class="row align-items-center">
                    <div class="col-auto form-check">
                        <input
                                class="form-check-input p-0 m-0" type="checkbox" id="visibility_{{i}}"
                                [attr.data-index] = i
                                [(ngModel)]="segmentParam.Active"
                                (input)="changeVisibility($event.target)"
                        />
                    </div>
                    <div class="col">
                        <div class="row align-items-center">
                            <div class="col col-auto">
                                <img src="/assets/images/icon-{{segmentParam.Name}}.svg" width="35" height="35" alt="{{segmentParam.Name | translate}}" [ngClass]="{'img-active' : segmentParam.Active}"/>
                            </div>
                            <div class="col">
                                <h4 class="p-0 m-0">{{segmentParam.Name | translate}}</h4>
                                <p class="p-0 m-0 small text-light-gray">{{segmentParam.Name+'_Description' | translate}}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </td>
            <td>
                <input class="form-control text-center" placeholder="10" id="threshold_{{i}}" type="number"
                       [attr.data-index] = i
                       [(ngModel)]="segmentParam.Threshold"
                />
            </td>
            <td class="text-center">
                <label for="relevance_{{i}}">RELEVANCE:&nbsp;</label>
                <output class="bg-gray rounded ps-1 pe-1">{{segmentParam.Relevance * 100 | number: '1.0-0'}}%</output>
                <input
                        class="form-range" id="relevance_{{i}}"
                        min="0" max="1" step="0.01" type="range"
                        [attr.data-index] = i
                        [disabled]="!segmentParam.Active"
                        [(ngModel)]="segmentParam.Relevance"
                        (input)="updateRelevanceValues($event.target)"
                />
            </td>
        </tr>
    </table>
    <div class="row bg-white p-2 justify-content-end border-bottom">
        <div class="col-auto">
            <button class="btn btn-green" (click)="saveSettings()">Save</button>
        </div>
    </div>
</div>
