import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddInterfaceComponent } from 'src/app/components/add-interface/add-interface.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-interface',
  templateUrl: './interface.component.html',
  styleUrls: ['./interface.component.scss']
})
export class InterfaceComponent {
  interfaces = [{ name: "Asset Management System", createdAt: "03/06/2024 10:18:32 AM", status: true },
  { name: "Directory System", createdAt: "", status: false },
  { name: "Business Continuity Management", createdAt: "", status: false },
  { name: "Security Operation Center", createdAt: "", status: false }
  ];
  interfaceStatus = [true, false, false, false];
  key: string = '';
  reverse: boolean = true;
  searchTerm: string = '';
  filteredInterfaces: { name: string; createdAt: string; status: boolean; }[] = [];
  pagedInterfaces: { name: string; createdAt: string; status: boolean; }[] = [];
  itemsPerPage: number = 5;

  constructor(private modalService: NgbModal,
    private toastService: ToastService,
    private localStorageService: LocalStorageService
  ) {
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB');
    const formattedTime = now.toLocaleTimeString();
    console.log(formattedDate)
    var savedInterfaces = JSON.parse(this.localStorageService.get('interfaceStatus')!);
    if (savedInterfaces) {
      this.interfaces = savedInterfaces;
    }
    this.filteredInterfaces = this.interfaces;
    this.setPage(1);
  }


  addInterface(index: number) {
    const modalRef = this.modalService.open(AddInterfaceComponent);
    modalRef.componentInstance.name = this.pagedInterfaces[index].name;
    modalRef.result.then(
      (result) => {
        this.pagedInterfaces[index].status = true;
        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-GB');
        const formattedTime = now.toLocaleTimeString();
        this.pagedInterfaces[index].createdAt = `${formattedDate} ${formattedTime}`
        this.localStorageService.set('interfaceStatus', JSON.stringify(this.pagedInterfaces))
      },
      (reason) => {
        //console.log('Modal dismissed', reason);
      }
    );
  }

  deleteInterface(index: number) {
    this.pagedInterfaces[index].status = false;
    this.pagedInterfaces[index].createdAt = "";
    this.localStorageService.set('interfaceStatus', JSON.stringify(this.pagedInterfaces))
    this.toastService.showSuccess(`${this.pagedInterfaces[index].name} interface deleted successfully`);
  }

  setPage(page: number): void {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.pagedInterfaces = this.filteredInterfaces.slice(startIndex, endIndex);
  }

  sort(key: string): void {
    this.key = key;
    this.reverse = !this.reverse;
    this.setPage(1);
  }

  searchInterfaces(): void {
    const searchTerm = this.searchTerm.toLowerCase();
    this.filteredInterfaces = this.interfaces.filter((report) => {
      const name = report.name.toLowerCase();
      return name.includes(searchTerm);
    });
    this.setPage(1);
  }
}
