import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading: boolean = false;
  private totalRequests = 0;

  constructor() { }

  getLoading(): boolean {
    return this.loading;
  }

  addRequest() {
    this.totalRequests++;
    if (!this.loading) {
      this.loading = true;
    }
  }

  removeRequest() {
    if (this.totalRequests != 0) {
      this.totalRequests--;
    }
    if (this.totalRequests == 0) {
      this.loading = false;
    }
  }
}
