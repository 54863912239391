<div class="container-fluid p-4">
    <!-- menu -->
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs border-bottom-0">
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
                </li>
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link active">< Location</a>
                </li>
                <li class="nav-item" *ngIf="isFacilityEnable">
                    <a class="nav-link disabled" href="#">Facility</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">Segments</a>
                </li>
            </ul>
        </div>
        <div class="col col-auto pe-0">
            <app-search></app-search>
        </div>
    </div>
    <div class="row shadow position-relative">
        <div class="col">
            <!-- location name and reload -->
            <div class="row bg-white p-2 border-top">
                <div class="col-auto" *ngIf="location">
                  <img  [src]="indexToImgSrc(location.Score, 'location')" height="40" width="40" alt="location-image"/>
                </div>
                <div class="col me-auto">
                    <div class="location-name" *ngIf="location">
                        <h3 class="mb-0">{{location.Name}}</h3>
                    </div>
                </div>
                <div class="col col-auto">
                    <app-spinner (onSpinnerClick)="refreshData(true)"></app-spinner>
                </div>
                <div class="col col-auto">
                  <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end" (click)="generateReport()">Generate Report</button>
                </div>
            </div>
            <div class="row p-2 bg-white">
              <!-- location graph -->
              <div class="col-9 position-relative">
                <div class="row">
                  <app-graph [width]="1200" [height]="600" id="node-graph"></app-graph>
                </div>
              </div>
              <!-- right side panel to the location graph -->
              <div class="col-3 shadow bg-gray position-relative" *ngIf="location; else locationMeta">
                  <!-- current location img, name and risk level -->
                  <div class="row mt-3" *ngIf="location">
                      <div class="col-auto">
                        <img  [src]="indexToImgSrc(location.Score, 'location')" height="64" width="64" alt="location-image"/>
                      </div>
                      <div class="col me-auto">
                          <h4 >{{location.Name}}</h4>
                          <h4 *ngIf="location.Score! >= 7" class="text-danger">High Risk</h4>
                          <h4 *ngIf="location.Score! >= 4  && location.Score! < 7" class="text-warning">Medium Risk</h4>
                          <h4 *ngIf="location.Score! < 4" class="text-green">Low Risk</h4>
                      </div>
                  </div>
                  <!-- current location metadata table -->
                  <div class="row mt-2" *ngIf="location">
                      <div class="col">
                          <table class="table table-borderless table-gray">
                              <thead class="border-bottom">
                              <tr>
                                  <th>RBC-Index</th>
                                  <th>{{location!.Score | number: '1.1-1'}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr class="border-bottom" *ngIf="isFacilityEnable">
                                  <td>Facilities:</td>
                                  <td>{{location.FacilityList.length}}</td>
                              </tr>
                              <tr class="border-bottom" *ngIf="facility && !isFacilityEnable">
                                  <td>Segments:</td>
                                  <td>{{facility.Segments.length}}</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div class="row mt-2" id="node-tooltip">
                    <div class="col col-12 mb-12">
                      <div class="card">
                          <div class="card-body">
                              <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3" id="detail-node-name">Name</a>
                              <div class="row mb-4">
                                  <div class="col col-6">
                                      <img src="assets/images/facility-high.svg" class="card-img-top" height="94" width="150" alt="facility-image" id="detail-node-img" />
                                  </div>
                                  <div class="col col-6 ">
                                      <span class="fw-bold">RBC-Index</span>
                                      <h4 class="" id="detail-node-score">Score/10</h4>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col col-auto">
                                      <!-- <p class="mb-0 small" id="detail-node-facility">Facilities: </p> -->
                                      <p class="mb-0 small" id="detail-node-child-number">Segments: </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
              <!-- placeholder right side panel to the location graph  -->
              <ng-template #locationMeta>
                  <div class="col-3 position-relative placeholder-glow">
                    <!-- current location img, name and risk level -->
                    <div class="row mt-3">
                        <div class="col-auto">
                          <span class="placeholder col-12" style="height: 64px; width: 64px;"></span>
                        </div>
                        <div class="col me-auto">
                            <span class="placeholder col-8" >location.Name</span>
                            <span class="placeholder col-6">High Risk</span>
                        </div>
                    </div>
                    <!-- current location metadata table -->
                    <div class="row mt-2">
                        <div class="col">
                            <table class="table table-borderless table-gray">
                                <thead class="border-bottom">
                                <tr>
                                    <th><span class="placeholder col-6">RBC</span></th>
                                    <th><span class="placeholder col-6"></span></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr class="border-bottom" *ngIf="!isFacilityEnable">
                                    <td><span class="placeholder col-6"></span></td>
                                    <td><span class="placeholder col-3"></span></td>
                                </tr>
                                <tr class="border-bottom" *ngIf="isFacilityEnable">
                                  <td><span class="placeholder col-6"></span></td>
                                  <td><span class="placeholder col-3"></span></td>
                              </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <div class="row mt-2" id="node-tooltip">
                      <div class="col col-12 mb-12">
                        <div class="card">
                            <div class="card-body">
                                <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3" id="detail-node-name">Name</a>
                                <div class="row mb-4">
                                    <div class="col col-6">
                                        <img src="assets/images/facility-high.svg" class="card-img-top" height="94" width="150" alt="facility-image" id="detail-node-img" />
                                    </div>
                                    <div class="col col-6 ">
                                        <span class="fw-bold">RBC-Index</span>
                                        <h4 class="" id="detail-node-score">Score/10</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-auto">
                                        <p class="mb-0 small" id="detail-node-child-number">Segments: </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div> -->

                </div>
            </ng-template>
            </div>
            <div class="row bg-white p-2 border-top">
              <div class="col me-auto">
                  <div class="location-name">
                      <h3 class="mb-0" *ngIf = "isFacilityEnable">Most critical facilities to watch</h3>
                      <h3 class="mb-0" *ngIf = "!isFacilityEnable">Most critical segments to watch</h3>
                  </div>
              </div>
            </div>
            <div class="row p-2 bg-white">
                <!-- most critical canvas -->
                <div class="col-8 position-relative">
                    <!-- <div id="location-canvas"></div> -->
                    <!-- most critical facility cards -->
                    <div class="row" id="location-canvas" *ngIf="location && isFacilityEnable">
                      <ng-container *ngFor="let facility of location.FacilityList">
                        <div class="col col-3 mb-3" *ngIf="facility.Score >7">
                            <div class="card" (click)="selectFacility({facilityId: facility.id})"  style="cursor: pointer;">
                                <div class="card-body">
                                    <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3">{{facility.Name}}</a>
                                    <div class="row mb-4">
                                        <div class="col col-6">
                                          <img [src]="indexToImgSrc(facility.Score, 'facility')" class="card-img-top" height="94" width="150" alt="facility-image" />
                                            <!-- <img *ngIf="facility.Score! > 7" src="assets/images/facility-high.svg" class="card-img-top" height="94" width="150" alt="facility-image" />
                                            <img *ngIf="facility.Score! > 3  && facility.Score! <= 7" src="assets/images/facility-medium.svg" class="card-img-top" height="94" width="150" alt="facility-image"/>
                                            <img *ngIf="facility.Score! <= 3" src="assets/images/facility-low.svg" class="card-img-top" height="94" width="150" alt="facility-image"/> -->
                                        </div>
                                        <div class="col col-6 ">
                                            <span class="fw-bold">RBC-Index</span>
                                            <h4 class="">{{facility.Score | number:'1.1-1'}}/10</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-auto">
                                          <p class="mb-0 small">Segments: {{facility.High+facility.Medium+facility.Low}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </ng-container>
                    </div>
                    <!-- most critical segment cards -->
                    <div class="row" id="location-canvas" *ngIf="facility && !isFacilityEnable; else loadingCriticalLocation">
                      <ng-container *ngFor="let segment of facility.SegmentList">
                        <div class="col col-3 mb-3" *ngIf="segment.Score >7">
                            <div class="card" (click)="selectSegment({segmentId: segment.id})"  style="cursor: pointer;">
                                <div class="card-body">
                                    <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3">{{segment.Network}}</a>
                                    <div class="row mb-4">
                                        <div class="col col-6">
                                            <img [src]="indexToImgSrc(segment.Score, 'segment')" class="card-img-top" height="94" width="150" alt="segment-image" />
                                            <!-- <img *ngIf="segment.Score! > 7" src="assets/images/segment-circle-high.svg" class="card-img-top" height="94" width="150" alt="facility-image" />
                                            <img *ngIf="segment.Score! > 3  && segment.Score! <= 7" src="assets/images/segment-circle-medium.svg" class="card-img-top" height="94" width="150" alt="segment-image"/>
                                            <img *ngIf="segment.Score! <= 3" src="assets/images/segment-circle-low.svg" class="card-img-top" height="94" width="150" alt="facility-image"/> -->
                                        </div>
                                        <div class="col col-6 ">
                                            <span class="fw-bold">RBC-Index</span>
                                            <h4 class="">{{segment.Score | number:'1.1-1'}}/10</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-auto">
                                          <!-- <p class="mb-0 small">Segments: {{facility.High+facility.Medium+facility.Low}}</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </ng-container>
                    </div>
                    <!-- placeholder for most critical location cards -->
                    <ng-template #loadingCriticalLocation>
                      <div  div class="row">
                        <div class="col col-3 mb-3" *ngFor="let i of numberArray">
                            <div class="card" style="cursor: pointer;">
                                <div class="card-body placeholder-glow">
                                    <span class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3 placeholder">&nbsp;</span>
                                    <div class="row mb-4">
                                        <div class="col col-6">
                                            <span class="card-img-top placeholder" style="height: 94px">&nbsp;</span>
                                        </div>
                                        <div class="col col-6 ">
                                          <span class="fw-bold placeholder col-7"></span>
                                          <span class="placeholder col-6"></span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-auto">
                                            <span class="mb-0 small placeholder col-12" *ngIf="isFacilityEnable"></span>
                                            <span class="mb-0 small placeholder col-12">Segments: Segments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </ng-template>
                </div>

                <!-- right side panel to the most critical canvas -->
                <div class="col-4 shadow bg-gray position-relative">
                    <!-- Display selected facility on right panel when facility level is enabled -->
                    <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto" *ngIf="selectedFacility && isFacilityEnable">
                        <div class="col mt-3">
                            <div class="row">
                                <div class="col-auto">
                                    <img [ngSrc]="indexToImgSrc(selectedFacility.Score, 'facility')" height="64" width="64" alt="location-image"/>
                                    <!-- <img *ngIf="selectedFacility.Score! > 7" ngSrc="assets/images/facility-high.svg" height="64" width="64" alt="location-image"/>
                                    <img *ngIf="selectedFacility.Score! > 3  && selectedFacility.Score! <= 7" ngSrc="assets/images/facility-medium.svg" height="64" width="64" alt="location-image"/>
                                    <img *ngIf="selectedFacility.Score! <= 3" ngSrc="assets/images/facility-low.svg" height="64" width="64" alt="location-image"/> -->
                                </div>
                                <div class="col me-auto">
                                    <h4>
                                        {{selectedFacility.Name}}
                                    </h4>
                                    <h4 *ngIf="selectedFacility.Score! > 7" class="text-danger">High Risk</h4>
                                    <h4 *ngIf="selectedFacility.Score! > 3  && selectedFacility.Score! <= 7" class="text-warning">Medium Risk</h4>
                                    <h4 *ngIf="selectedFacility.Score! <= 3" class="text-green">Low Risk</h4>
                                </div>
                                <div class="col-auto ">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="selectedFacility=undefined"></button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <table class="table table-borderless table-gray" style="font-size: 22px;">
                                        <thead class="border-bottom">
                                        <tr>
                                            <th>RBC-Index</th>
                                            <th>{{selectedFacility!.Score | number: '1.1-1'}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="border-bottom">
                                            <td>Name:</td>
                                            <td>{{selectedFacility!.Name}}</td>
                                        </tr>
                                        </tbody>
                                        <tbody>
                                          <tr class="border-bottom">
                                              <td>Segments:</td>
                                              <td>{{selectedFacility!.SegmentList.length}}</td>
                                          </tr>
                                          </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-5" >
                                <ng-template #loading>
                                    <div class="placeholder-glow" aria-hidden="true">
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-auto">
                                    <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end mt-3" [routerLink]="['/dashboard/', locationId, selectedFacility!.id]">Show Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Display selected segment on right panel when facility level is disabled -->
                    <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto" *ngIf="selectedSegment && !isFacilityEnable">
                      <div class="col mt-3">
                          <div class="row">
                              <div class="col-auto">
                                  <img [src]="indexToImgSrc(selectedSegment.Score, 'segment')" height="64" width="64" alt="segment-image"/>
                                  <!-- <img *ngIf="selectedSegment.Score! > 7" src="assets/images/segment-circle-high.svg" height="64" width="64" alt="segment-image"/>
                                  <img *ngIf="selectedSegment.Score! > 3  && selectedSegment.Score! <= 7" src="assets/images/segment-circle-medium.svg" height="64" width="64" alt="segment-image"/>
                                  <img *ngIf="selectedSegment.Score! <= 3" src="assets/images/segment-circle-low.svg" height="64" width="64" alt="segment-image"/> -->
                              </div>
                              <div class="col me-auto">
                                  <h4>
                                      {{selectedSegment.Network}}
                                  </h4>
                                  <h4 *ngIf="selectedSegment.Score! > 7" class="text-danger">High Risk</h4>
                                  <h4 *ngIf="selectedSegment.Score! > 3  && selectedSegment.Score! <= 7" class="text-warning">Medium Risk</h4>
                                  <h4 *ngIf="selectedSegment.Score! <= 3" class="text-green">Low Risk</h4>
                              </div>
                              <div class="col-auto ">
                                  <button type="button" class="btn-close" aria-label="Close" (click)="selectedSegment=undefined"></button>
                              </div>
                          </div>
                          <div class="row mt-2">
                              <div class="col">
                                  <table class="table table-borderless table-gray" style="font-size: 22px;">
                                      <thead class="border-bottom">
                                      <tr>
                                          <th>RBC-Index</th>
                                          <th>{{selectedSegment!.Score | number: '1.1-1'}}</th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr class="border-bottom">
                                          <td>Name:</td>
                                          <td>{{selectedSegment!.Network}}</td>
                                      </tr>
                                      </tbody>
                                      <tbody>
                                        <tr class="border-bottom">
                                            <td>Devices:</td>
                                            <td>{{selectedSegment!.DeviceList.length}}</td>
                                        </tr>
                                        </tbody>
                                  </table>
                              </div>
                          </div>
                          <div class="row mt-2" *ngIf="recommendations.length > 0;">
                            <div class="col">
                                <h4>Security Recommendations</h4>
                                <div *ngFor="let recommendation of recommendations">
                                    <div class="row align-items-center">
                                        <div class="col col-auto">
                                            <img src="/assets/images/icon-{{recommendation.Name}}.svg" width="35" height="35" alt="{{recommendation.Name | translate}}"/>
                                        </div>
                                        <div class="col">
                                            <h5 class="p-0 m-0">{{recommendation.Title}}</h5>
                                            <p class="p-0 m-0 text-light-gray">{{recommendation.Description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div class="row mt-5" >
                            <div class="col">
                              <table class="table table-borderless table-gray" *ngIf="segmentHistory != undefined; else history_loading" style="font-size: 22px;">
                                  <tbody >
                                  <tr *ngFor="let sParam of segmentHistory!.Parameters" class="border-bottom">
                                      <td class="col-9">{{sParam.Name | translate}}:</td>
                                      <td class="col-3">
                                        <div class="progress" role="button" (click)="rbcImage = sParam.Name" style="height: 30px; font-size: 16px;">
                                          <div class="progress-bar progress-bar-striped" [style.background-color]="indexToColor(sParam.Score?sParam.Score:0)" role="progressbar" [style.width.%]="sParam.Score?sParam.Score*10:0"  aria-valuemin="0" aria-valuemax="100">{{sParam.Score | number: '1.1-1'}}</div>
                                        </div>
                                          <!-- <span class="param-value">{{sParam.Score | number: '1.1-1'}}</span> -->
                                        <!-- <span class="param-value text-green" role="button" (click)="rbcImage = sParam.Name">{{sParam.Score | number: '1.1-1'}}</span>-->
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>

                              <div class="col-auto m-auto rounded bg-white shadow p-2" *ngIf="rbcImage && rbcImage == 'NETWORK_SEGMENT_DIMENSION'" >
                              </div>
                              <div class="col-auto m-auto rounded bg-white shadow p-2" *ngIf="rbcImage && rbcImage != 'NETWORK_SEGMENT_DIMENSION'">
                                <img src="/assets/images/{{rbcImage}}.jpg" width="200" height="200" alt="{{rbcImage | translate}}"/>
                              </div>

                              <ng-template #history_loading>
                                  <div class="placeholder-glow" aria-hidden="true">
                                      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                  </div>
                              </ng-template>
                          </div>
                          </div>
                          <div class="row justify-content-end">
                              <div class="col-auto">
                                  <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end mt-3" [routerLink]="['/dashboard/', locationId, facility.id, selectedSegment!.id]">Show Details</button>
                              </div>
                          </div>
                      </div>
                    </div>
                    <!-- Display current location details -->
                    <!-- <div class="row mt-3" *ngIf="location">
                        <div class="col-auto">
                          <img  [src]="indexToImgSrc(location.Score, 'location')" height="64" width="64" alt="location-image"/>
                        </div>
                        <div class="col me-auto">
                            <h4 >{{location.Name}}</h4>
                            <h4 *ngIf="location.Score! > 7" class="text-danger">High Risk</h4>
                            <h4 *ngIf="location.Score! > 3  && location.Score! <= 7" class="text-warning">Medium Risk</h4>
                            <h4 *ngIf="location.Score! <= 3" class="text-green">Low Risk</h4>
                        </div>
                    </div>
                    <div class="row mt-2" *ngIf="location">
                      <div class="col">
                          <table class="table table-borderless table-gray">
                              <thead class="border-bottom">
                              <tr>
                                  <th>RBC-Index</th>
                                  <th>{{location!.Score | number: '1.1-1'}}</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr class="border-bottom" *ngIf="isFacilityEnable">
                                  <td>Facilities:</td>
                                  <td>{{location.FacilityList.length}}</td>
                              </tr>
                              <tr class="border-bottom" *ngIf="facility && !isFacilityEnable">
                                  <td>Segments:</td>
                                  <td>{{facility.Segments.length}}</td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                    </div> -->
                    <div class="row mt-3">
                      <div class="col">
                          <h4 class="text-gray">Location RISK-BY-CONTEXT</h4>
                      </div>
                    </div>
                    <!-- location doughnut chart -->
                    <div class="row justify-content-center">
                      <app-doughnut-chart></app-doughnut-chart>
                    </div>
                    <!-- location risk level history chart -->
                    <div class="row mb-3">
                        <div class="col">
                            <app-risk-level-history-chart></app-risk-level-history-chart>
                        </div>
                    </div>
                    <!-- location index history chart -->
                    <div class="row mb-3">
                      <div class="col">
                          <app-index-history-chart></app-index-history-chart>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Canvas Zoom in/out button -->
    <!-- <div class="row" *ngIf="location">
        <div class="col col-auto offset-6">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item">
                    <a class="nav-link pt-1 pb-0" style="height: 42px;">
                        <input class="form-range" type="range" min="0.3" max="3" step="0.01" [(ngModel)]="zoomValue">
                    </a>
                </li>
            </ul>
        </div>
    </div> -->
    <!-- Risk-By-Context Tabs -->
    <div class="row mt-5">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab"  [destroyOnHide]="true" class="nav-tabs">
            <!-- these tabs will be only visible when facility level is enable -->
            <li [ngbNavItem]="1" *ngIf="isFacilityEnable">
                <a ngbNavLink>Facility Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-facility-table (onFacilitySelect)="selectFacility($event)"></app-facility-table>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="selectedFacility != undefined && isFacilityEnable">
                <a ngbNavLink>Segment Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-segment-table (omn)="selectSegment($event)"></app-segment-table>
                </ng-template>
            </li>
            <!-- These tabs will be only visible when facility level is disable -->
            <li [ngbNavItem]="1" *ngIf="!isFacilityEnable">
              <a ngbNavLink>Segment Risk-By-Context</a>
              <ng-template ngbNavContent>
                  <app-segment-table (onSegmentSelect)="selectSegment($event)"></app-segment-table>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="selectedSegment != undefined && !isFacilityEnable">
              <a ngbNavLink>Device Risk-By-Context</a>
              <ng-template ngbNavContent>
                  <app-device-table (onDeviceSelect)="selectDevice($event)"> </app-device-table>
              </ng-template>
            </li>
      </ul>
    </div>
    <div class="row shadow position-relative">
        <div [ngbNavOutlet]="nav" class="pt-2 bg-white border-top"></div>
    </div>

</div>
