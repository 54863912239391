<div ngbDropdown class="dropdown">
    <input type="text"
           class="form-control"
           style="min-width: 500px"
           (keyup)="onSearchInput($event)" ngbDropdownToggle
    />
    <div ngbDropdownMenu class="px-3 py-2 dropdown-menu w-100">
        <div class="row">
            <div class="col col-auto">
                <div class="fw-bold mt-2">Filter</div>
                <form>
                    <div class="form-check mb-2">
                        <input type="checkbox" name="locationCheck" class="form-check-input" id="locationCheck" [(ngModel)]="locationFilter"/>
                        <label class="form-check-label" for="locationCheck">Locations</label>
                    </div>
                    <div class="form-check mb-2">
                        <input type="checkbox" name="facilityCheck" class="form-check-input" id="facilityCheck" [(ngModel)]="facilityFilter"/>
                        <label class="form-check-label" for="facilityCheck">Facilities</label>
                    </div>
                    <div class="form-check mb-2">
                        <input type="checkbox" name="segmentCheck" class="form-check-input" id="segmentCheck" [(ngModel)]="segmentFilter"/>
                        <label class="form-check-label" for="segmentCheck">Segments</label>
                    </div>
                    <div class="form-check mb-2">
                        <input type="checkbox" name="assetCheck" class="form-check-input" id="assetCheck" [(ngModel)]="deviceFilter"/>
                        <label class="form-check-label" for="assetCheck">Assets</label>
                    </div>
                    <div class="form-check mb-2">
                        <input type="checkbox" name="vulnerabilityCheck" class="form-check-input" id="vulnerabilityCheck" [(ngModel)]="vulnerabilityFilter"/>
                        <label class="form-check-label" for="vulnerabilityCheck">Vulnerabilities</label>
                    </div>
                </form>
            </div>
            <div class="col">
                <div *ngIf="searchResult; else loading">
                    <div *ngIf="locationFilter">
                        <div class="fw-bold mt-2">Locations</div>
                        <hr class="mt-0 mb-1">
                        <div *ngFor="let location of searchResult.Locations">
                            <a href="#" (click)="onLocationSelect($event)" [attr.data-id]="location.id">{{ location.Name }}</a>
                        </div>
                    </div>
                    <div *ngIf="facilityFilter">
                        <div class="fw-bold mt-2">Facilities</div>
                        <hr class="mt-0 mb-1">
                        <div *ngFor="let facility of searchResult.Facilities">
                            <a href="#" (click)="onFacilitySelect($event)" [attr.data-id]="facility.id">{{ facility.Name }}</a>
                        </div>
                    </div>
                    <div *ngIf="segmentFilter">
                        <div class="fw-bold mt-2">Segments</div>
                        <hr class="mt-0 mb-1">
                        <div *ngFor="let segment of searchResult.Segments">
                            <a href="#" (click)="onSegmentSelect($event)" [attr.data-id]="segment.id">{{ segment.Network }}</a>
                        </div>
                    </div>
                    <div *ngIf="deviceFilter">
                        <div class="fw-bold mt-2">Devices</div>
                        <hr class="mt-0 mb-1">
                        <div *ngFor="let device of searchResult.Devices" >
                            <a href="#" (click)="onDeviceSelect($event)" [attr.data-id]="device.id">{{ device.DeviceId }}</a>
                        </div>
                    </div>
                    <div *ngIf="vulnerabilityFilter">
                        <div class="fw-bold mt-2">Vulnerabilities</div>
                        <hr class="mt-0 mb-1">
                        <div *ngFor="let vul of searchResult.Vulnerabilities">
                            {{ vul.CveId }}
                        </div>
                    </div>
                </div>
                <ng-template #loading>
                    <div class="fw-bold mt-2">Please enter a search request...</div>
                </ng-template>
            </div>
        </div>

    </div>
</div>


