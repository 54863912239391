import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { round } from "@popperjs/core/lib/utils/math";
import { Chart, registerables } from "chart.js";
import Konva from "konva";
import { Subscription } from "rxjs";
import { Graph } from 'src/app/core/models/graph';
import { environment } from 'src/environments/environment';
import { Device } from "../../core/models/device";
import { DeviceHistory } from "../../core/models/device-history";
import { DeviceParam } from "../../core/models/device-param";
import { Recommendation } from "../../core/models/recommendation";
import { Segment } from "../../core/models/segment";
import { SnapshotData } from "../../core/models/snapshot-data";
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { LoadingService } from '../../services/loading.service';
import { ToastService } from "../../services/toast.service";
import { RiskLevel } from 'src/app/core/models/risk-level';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerComponent } from 'src/app/components/pdf-viewer/pdf-viewer.component';


@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent implements OnInit, OnDestroy {

  finishedDrawing = false
  isFacilityEnable: boolean = environment.FACILITY_ENABLE
  recommendations: Recommendation[] = []
  activeTab: number = 1;
  rbcImage?: string;
  public isSegmentDoughnutReady = false;

  private _segment!: Segment;
  private _selectedAsset?: Device;
  private _assetHistory?: DeviceHistory;
  private segmentSubscription!: Subscription;
  private historySubscription!: Subscription;
  private segmentDoughnutChart!: Chart<any>;
  private segmentScoreHistoryChart!: Chart<any>;
  // private deviceHistoryInSegmentChart!: Chart<any>;

  get segment(): Segment {
    return this._segment;
  }

  set segment(value: Segment) {
    if (value !== this._segment) {
      this._segment = value;
      this.deviceRanking = { 'high': 0, 'medium': 0, 'low': 0, type: '' }
      for (let device in value.DeviceList) {
        if (value.DeviceList[device].Score! > 7) {
          this.deviceRanking.high++
        } else if (value.DeviceList[device].Score! > 3) {
          this.deviceRanking.medium++
        } else {
          this.deviceRanking.low++
        }
      }
      this.deviceRanking.type = 'device';
      this.apiService.changeRiskLevelDoughnut(this.deviceRanking);
      //this.drawSegmentGraph();
      // this.loadImages();
    }
  }

  get selectedAsset(): Device | undefined {
    return this._selectedAsset;
  }

  set selectedAsset(value: Device | undefined) {
    this._selectedAsset = value;
    this.assetHistory = undefined;
    this.rbcImage = undefined;
    if (value != undefined) {
      this.apiService.changeDevice(value);
      this.historySubscription = this.apiService.getLastAssetHistoryByAssetId(value.id).subscribe(
        response => {
          response.Parameters.sort(function (a: DeviceParam, b: DeviceParam) {
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
          })
          this.assetHistory = response

        }
      )
      this.activeTab = 2
    } else {
      this.activeTab = 1
    }
  }

  get assetHistory(): DeviceHistory | undefined {
    return this._assetHistory;
  }

  set assetHistory(value: DeviceHistory | undefined) {
    this._assetHistory = value;
    this.recommendations = []
    if (value != undefined) {
      for (let param in value.Parameters) {
        if (value.Parameters[param].Score! > 7) {
          this.apiService.getDeviceSecurityRecommendationByName(value.Parameters[param].Name)
            .subscribe((response) => {
              this.recommendations.push(response)
            })
        }
      }
    }
  }

  assetId?: string | null;
  segmentId!: string;
  facilityId!: string;
  locationId!: string;
  width = 0;
  height = 0;

  sources: Record<number, string> = {
    0: '/assets/images/asset-low.svg',
    1: '/assets/images/asset-medium.svg',
    2: '/assets/images/asset-high.svg'
  };

  private deviceRanking:RiskLevel = { 'high': 0, 'medium': 0, 'low': 0, type: '' }
  private snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private modalService: NgbModal
  ) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.segmentSubscription = this.apiService.currentSegment.subscribe(
      response => {
        this.locationId = this.route.snapshot.paramMap.get('locationId')!
        this.facilityId = this.route.snapshot.paramMap.get('facilityId')!
        this.segmentId = this.route.snapshot.paramMap.get('segmentId')!
        this.assetId = this.route.snapshot.paramMap.get('assetId');
        if (response != undefined && response.id != undefined && response.id == this.segmentId) {
          this.segment = response
          var graph = HelperService.formateGraphData(response, "Segment");
          this.apiService.changeGraph(graph);
          if (this.assetId) {
            this.selectedAsset = this.segment.DeviceList.filter(obj => {
              return obj.id === this.assetId;
            })[0]
          }
          this.updateSnapshotData(HelperService.getDateString(7), HelperService.getDateString())
        } else {
          this.refreshData()
        }
      }
    )
  }

  ngOnDestroy() {
    this.segmentDoughnutChart != undefined ? this.segmentDoughnutChart.destroy() : '';
    this.segmentScoreHistoryChart != undefined ? this.segmentScoreHistoryChart.destroy() : '';

    this.segmentSubscription.unsubscribe();
    if (this.historySubscription != undefined) {
      this.historySubscription.unsubscribe();
    }
    this.apiService.changeGraph({} as Graph);
  }

  generateReport(): void {
    const modalRef = this.modalService.open(PdfViewerComponent);
    modalRef.componentInstance.reportUrl = '/assets/reports/RiskByContext_Report_Segment.pdf';
  }

  selectAsset($event: any) {
    this.selectedAsset = this.segment.DeviceList.filter(obj => {
      if ($event.target)
        return obj.id === $event.target.getAttribute('data-asset');
      else
        return obj.id === $event.nodeId;
    })[0]
  }

  refreshData(manual = false) {
    this.loadingService.addRequest()
    this.activeTab = 1;
    this.positionX = HelperService.getRandomArbitrary(20, 50);
    this.positionY = HelperService.getRandomArbitrary(10, 50);
    this.firstX = this.positionX;
    this.rowHeight = 0;

    this.finishedDrawing = false;
    // // this.selectedAsset = undefined

    this.apiService.getSegmentById(this.segmentId)
      .subscribe(response => {
        this.apiService.changeSegment(response);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        } else {
          this.selectedAsset = this.segment.DeviceList.filter(obj => {
            return obj.id === this.assetId;
          })[0]
        }
        this.loadingService.removeRequest()
      });
  }

  updateSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getSegmentSnapshotBySegmentIdAndDate(this.segmentId, fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          var snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
          for (const snap in response.reverse()) {
            snapshotData.dates.push(response[snap]['Day'].substring(5))
            snapshotData.low.push(response[snap]['Low'])
            snapshotData.medium.push(response[snap]['Medium'])
            snapshotData.high.push(response[snap]['High'])
            snapshotData.score.push(response[snap]['Score'])
          }
          //this.drawHistoryGraph();
          //this.drawSegmentScoreHistoryChart()
          snapshotData.type = "Devices";
          this.apiService.changeRiskLevelSnapshot(snapshotData);
          this.apiService.changeIndexSnapshot(snapshotData);
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
  }

  indexToImgSrc(score: number, type: string) {
    return HelperService.RBCIndexToImageSrc(score, type);
  }

  indexToColor(score: number) {
    return HelperService.RBCScoreToRiskColor(score);
  }


  stage!: Konva.Stage;
  mainLayer!: Konva.Layer;
  arrow!: Konva.Arrow;
  positionX = HelperService.getRandomArbitrary(20, 50);
  firstX = this.positionX
  positionY = HelperService.getRandomArbitrary(10, 50);
  rowHeight = 0;
  imageWidth = 34;
  imageHeight = 34;
  offsetX = this.imageWidth + this.imageWidth / 2 + 4;
  oldX = 0;
  oldY = 0;

  loadImages(): void {
    const images: Record<string, HTMLImageElement> = {};
    let loadedImages = 0;
    let numImages = 0;
    for (const src in this.sources) {
      numImages++;
    }
    for (const src in this.sources) {
      images[src] = new Image(this.imageWidth * 4, this.imageHeight * 4);
      images[src].onload = () => {
        if (++loadedImages >= numImages) {
          this.buildStage(images)
        }
      };
      images[src].src = this.sources[src];
    }
  }

  buildStage(images: Record<string, HTMLImageElement>) {
    const canvas = document.getElementById('segment-canvas');
    if (canvas !== null) {

      this.width = canvas!.offsetWidth;
      this.height = canvas!.offsetHeight;

      this.stage = new Konva.Stage({
        width: this.width,
        height: this.height,
        container: 'segment-canvas',
        draggable: true,
      });

      this.mainLayer = new Konva.Layer();
      this.stage.add(this.mainLayer);
      this.mainLayer.draw();

      for (let asset in this.segment.DeviceList) {
        // for (let n = 0; n < 250; n++)  {
        let currentAsset = this.segment.DeviceList[asset];
        this.calculatePosition();
        let assetRanking = this.segment.DeviceList[asset].Score!;
        let imgRanking = assetRanking > 7 ? 2 : assetRanking > 3 ? 1 : 0;
        let arrowColor = assetRanking > 7 ? "#D10C15" : assetRanking > 3 ? "#F19100" : "#AFCA0B";
        const cube = new Konva.Image({
          x: this.positionX,
          y: this.positionY,
          image: images[imgRanking],
          width: this.imageWidth * 4,
          height: this.imageHeight * 4,
          id: currentAsset.id,
          scaleX: 0.25,
          scaleY: 0.25
        });
        cube.cache();
        cube.drawHitFromCache();
        cube.on('click', (e) => {
          // if we have scale, just reset
          const zoomed = this.stage.scaleX() !== 1;
          if (zoomed) {
            this.unzoomCanvas()
            return;
          }

          this.selectedAsset = this.segment.DeviceList.filter(obj => {
            return obj.id === e.target.id();
          })[0]

          // // ignore if clicked not on shape
          // const clickOnShape = e.target instanceof Konva.Shape;
          // if (!clickOnShape) {
          //   return;
          // }
          // // where shape is placed
          // const box = e.target.getClientRect();
          // // how much do we need to zoom for that
          // const scale = 4;
          // this.oldX = this.stage.position().x;
          // this.oldY = this.stage.position().y;
          //
          // this.stage.draggable(false)
          //
          // // let's do it
          // this.stage.to({
          //   x: (-box.x+this.stage.position().x)  * scale + 300,
          //   y: (-box.y+this.stage.position().y)  * scale + 300,
          //   scaleX: scale,
          //   scaleY: scale,
          // })
          //
          // this.arrow = new Konva.Arrow({
          //   points: [
          //     box.x - this.stage.position().x+ box.width/2,
          //     box.y - this.stage.position().y + box.height/2,
          //     (box.x - this.stage.position().x + box.width/2) + 15,
          //     box.y - this.stage.position().y + 60,
          //     (box.x - this.stage.position().x + box.width/2) + 30,
          //     box.y - this.stage.position().y + 60,
          //   ],
          //   fill: 'black',
          //   stroke: arrowColor,
          //   strokeWidth: 1,
          //   pointerAtEnding: false
          // });
          //
          // document.getElementById('asset-detail')!.style.display = 'block';
          //
          // this.mainLayer.add(this.arrow);
        })
        cube.on('mouseenter', () => {
          this.stage.container().style.cursor = 'pointer';
        });

        cube.on('mouseleave', () => {
          this.stage.container().style.cursor = 'default';
        });
        this.mainLayer.add(cube);
      }
    }
    //Canvas finished drawing
    this.finishedDrawing = true;
  }

  unzoomCanvas() {
    this.stage.draggable(true)
    this.stage.to({
      x: this.oldX,
      y: this.oldY,
      scaleX: 1,
      scaleY: 1
    });
    if (this.arrow != undefined) {
      this.arrow.destroy()
    }
    document.getElementById('asset-detail')!.style.display = 'none';
    return;
  }

  calculatePosition() {
    this.positionX += this.offsetX;
    if (this.positionX + this.imageWidth + (round(HelperService.getRandomArbitrary(1, 4)) * this.offsetX) >= this.stage.width()) {
      do {
        const plusOrMinus = Math.random() < 0.75 ? -1 : 1;
        this.positionX =
          (this.firstX + this.imageWidth / 2 + 10) +
          ((round(HelperService.getRandomArbitrary(1, 3)) * this.offsetX) *
            plusOrMinus)
          ;
      } while (this.positionX <= 0 || this.positionX + this.imageWidth >= this.stage.width());


      this.positionY = this.rowHeight - this.imageHeight / 2 + 1;
      this.firstX = this.positionX;
    }

    if (this.rowHeight < this.positionY + this.imageHeight) {
      this.rowHeight = this.positionY + this.imageHeight
    }
  }

  // drawSegmentGraph() {
  //   this.segmentDoughnutChart != undefined ? this.segmentDoughnutChart.destroy() : '';
  //   const ctx = <HTMLCanvasElement>document.getElementById('doughnutSegmentChart')!;
  //   if (ctx) {
  //     this.segmentDoughnutChart = new Chart(ctx, {
  //       type: 'doughnut',
  //       data: {
  //         labels: ['High', 'Medium', 'Low'],
  //         datasets: [{
  //           data: [this.deviceRanking.critical, this.deviceRanking.medium, this.deviceRanking.low],
  //           borderWidth: 1,
  //           backgroundColor: [
  //             '#D10C15',
  //             '#F19100',
  //             '#AFCA0B'
  //           ],
  //           hoverOffset: 4
  //         }]
  //       },
  //       plugins: [{
  //         id: "doughnutSegmentChart",
  //         beforeInit: function (chart, args, options) {
  //           const datasets = chart.data.datasets!;
  //           if (chart.canvas.id === "doughnutSegmentChart") {
  //             const ul = document.createElement('ul');
  //             chart?.data?.labels?.forEach((label, index) => {
  //               const labelString = label as string;
  //               ul.innerHTML += `
  //               <li>
  //                 <img src="assets/images/device-${labelString.toLowerCase()}.svg" style="width:30px; height:30px"></img>
  //                 ${label}: ${datasets[0].data[index]}
  //               </li>
  //             `;
  //             });
  //             return document!.getElementById("donut-segment-legend")!.innerHTML = ul.outerHTML;
  //           }

  //           return;
  //         }
  //       }],
  //       options: {
  //         plugins: {
  //           legend: {
  //             display: false
  //           }
  //         }
  //       }
  //     });
  //     this.isSegmentDoughnutReady = true;
  //   }
  // }
}
