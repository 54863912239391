<div class="container-fluid header fixed-top">
    <div class="p-2">
        <img class="logo" src="assets/images/logo.png" alt="asvin logo">
    </div>
    <div class="flex me-3">
        <a href="https://app.asvin.io/plan/index" target="_blank" class="btn bg-white text-green" >Change plan</a>
        <div class="relative-layout locale-wrapper ms-3">
            <a class="en text-white">English</a>
        </div>
        <img alt="Asvin User Image" class="ms-3 rounded-circle"
             ngSrc="/assets/images/user.svg" height="34" width="31"/>

        <a href="https://app.asvin.io/user/edit-profile" target="_blank" class="ms-2 text-white">
            Admin </a>
    </div>
</div>