import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any[], field: string, reverse: boolean): any[] {
    const order = reverse ? -1 : 1;
    return array.sort((a, b) => (a[field] > b[field] ? order : -order));
  }

}
