import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';


import { DecimalPipe } from '@angular/common';
import { switchMap, tap } from 'rxjs/operators';
import { Device } from "../../core/models/device";
import { Location } from "../../core/models/location";
import { LocationSortColumn, SortDirection } from "../../directives/sortable.directive";
import { ApiService } from "../../services/api.service";
import { Facility } from "../../core/models/facility";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: LocationSortColumn;
  sortDirection: SortDirection;
}

interface LocationSearchResult {
  locations: Location[];
  total: number;
}

const compare = (v1: number | Array<Facility> | Array<string> | string, v2: number | Array<Facility> | Array<string> | string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);



@Injectable({ providedIn: 'root' })
export class LocationTableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _locations$ = new BehaviorSubject<Location[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  LOCATIONS: Location[] = []

  selectedLocationId?: string;
  selectedLocations?: Location[];

  // private locationSubscription!: Subscription;

  private _state: State = {
    page: 1,
    pageSize: 25,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(
    private apiService: ApiService,
    private pipe: DecimalPipe
  ) {

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        switchMap(() => this._search()),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._locations$.next(result.locations);
        this._total$.next(result.total);
      });

    this.apiService.currentLocations.subscribe(
      response => {
        if (response.length > 0) {
          this.LOCATIONS = response;
          this._search$.next();
        }
      }
    )
  }

  get locations$() {
    return this._locations$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: LocationSortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<LocationSearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let locations = this.sort(this.LOCATIONS, sortColumn, sortDirection);

    // 2. filter
    locations = locations.filter((location) => this.matches(location, searchTerm, this.pipe));
    const total = locations.length;

    // 3. paginate
    locations = locations.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ locations: locations, total });
  }

  sort(locations: Location[], column: LocationSortColumn, direction: string): Location[] {
    if (direction === '' || column === '') {
      return [...locations].sort((a, b) => {
        const res = compare(a['Score']!, b['Score']!);
        return -res;
      });
    } else {
      return [...locations].sort((a, b) => {
        const res = compare(a[column]!, b[column]!);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  matches(location: Location, term: string, pipe: PipeTransform) {
    return (
      location.Name.toLowerCase().includes(term)
    );
  }
}
