<div class="container-fluid p-4">
  <!-- menu and search button row start -->
  <div class="row">
    <div class="col ps-0">
      <ul class="nav nav-tabs border-bottom-0">
        <li class="nav-item">
          <a class="nav-link active" href="#">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#">Location</a>
        </li>
        <li class="nav-item" *ngIf="isFacilityEnable">
          <a class="nav-link disabled" href="#">Facility</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#">Segment</a>
        </li>
      </ul>
    </div>
    <div class="col col-auto pe-0">
      <app-search></app-search>
    </div>
  </div>
  <!-- menu and search button row end-->
  <!-- refresh button row start-->
  <div class="row bg-white p-2 border-top">
    <div class="col me-auto">
      <div class="location-name">
        <h3 class="mb-0">Locations Overview</h3>
      </div>
    </div>
    <div class="col col-auto">
      <app-spinner (onSpinnerClick)="refreshData(true)"></app-spinner>
    </div>
    <div class="col col-auto">
      <button
        class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end"
        (click)="generateReport()"
      >
        Generate Report
      </button>
    </div>
  </div>
  <!-- refresh button row end-->
  <!-- world map row start -->
  <div class="row p-2 bg-white">
    <div class="col-9" id="world-map-location">
      <div class="row">
        <app-world-map></app-world-map>
      </div>
    </div>
    <!-- right side panel to the location graph -->
    <div class="col-3 shadow bg-gray position-relative">
      <!-- current location img, name and risk level -->
      <div class="row mt-3">
        <div class="col-auto">
          <img
            src="assets/images/txy-logo.png"
            height="64"
            width="64"
            alt="org-image"
          />
        </div>
        <div class="col me-auto">
          <h4>TXY GmbH</h4>
          <h4 class="text-black">Global Locations</h4>
        </div>
      </div>
      <!-- global metadata table -->
      <div class="row mt-2">
        <div class="col">
          <table
            class="table table-borderless table-gray"
            *ngIf="locationRanking"
          >
            <thead class="border-bottom">
              <tr>
                <th class="text-danger">High:</th>
                <th class="text-danger">{{ locationRanking.high }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-bottom">
                <td class="text-warning">Medium:</td>
                <td class="text-warning">{{ locationRanking.medium }}</td>
              </tr>
              <tr class="border-bottom">
                <td style="color: #92c029">Low:</td>
                <td style="color: #92c029">{{ locationRanking.low }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- node tooltip -->
      <div class="row mt-2" id="node-tooltip">
        <div class="col col-12 mb-12">
          <div class="card">
            <div class="card-body">
              <a
                class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3"
                id="detail-node-name"
                >Name</a
              >
              <div class="row mb-4">
                <div class="col col-6">
                  <img
                    src="assets/images/facility-high.svg"
                    class="card-img-top"
                    height="94"
                    width="150"
                    alt="facility-image"
                    id="detail-node-img"
                  />
                </div>
                <div class="col col-6">
                  <span class="fw-bold">RBC-Index</span>
                  <h4 class="" id="detail-node-score">Score/10</h4>
                </div>
              </div>
              <div class="row">
                <div class="col col-auto">
                  <!-- <p class="mb-0 small" id="detail-node-facility">Facilities: </p> -->
                  <!-- <p class="mb-0 small" id="detail-node-child-number">
                    Segments:
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- world map row end -->

  <!-- placeholder row start-->
  <div class="row bg-white p-2 border-top">
    <div class="col me-auto">
      <div class="location-name">
        <h3 class="mb-0">Widgets</h3>
      </div>
    </div>
    <div class="col col-auto">
      <button
        *ngIf="!isEditingDashboard"
        class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end"
        (click)="editDashboard()"
      >
        Edit Dashboard
      </button>
      <button
        *ngIf="isEditingDashboard"
        class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end"
        (click)="saveDashboard()"
      >
        Save Dashboard
      </button>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let cell of gridCells">
      <div
        [attr.data-cell]="cell"
        (drop)="onDrop($event, cell)"
        (dragover)="onDragOver($event)"
        class="col"
      >
        <div
          [draggable]="isEditingDashboard"
          (dragstart)="onDragStart($event, cell)"
          class="mt-3"
        >
          <ng-container #widgetContainers></ng-container>
        </div>

        <!-- responsive cell -->
        <div
          class="cell text-light text-center align-items-center d-flex justify-content-center p-1"
          (mouseenter)="onHover()"
          (mouseleave)="onHoverOut()"
          *ngIf="!gridContent[cell]"
          [class.cell]="isEditingDashboard"
        >
          <!-- drop downlist -->
          <div ngbDropdown class="col col-auto" *ngIf="isEditingDashboard">
            <button
              class="btn btn-green btn-lg"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              Add Widget
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="addWidget('cr-loc-ct', cell)">
                Critical Locations Count
              </button>
              <button ngbDropdownItem (click)="addWidget('cr-seg-ct', cell)">
                Critical Segments Count
              </button>
              <button ngbDropdownItem (click)="addWidget('cr-dev-ct', cell)">
                Critical Device Count
              </button>
              <button ngbDropdownItem (click)="addWidget('loc-dnt-crt', cell)">
                Location Doughnut Chart
              </button>
              <button ngbDropdownItem (click)="addWidget('gbl-loc-rl-crt', cell)">
                Locations Risk Level History
              </button>
              <!-- <button ngbDropdownItem (click)="addWidget('gbl-loc-tbl', cell)">
                Global Location Table
              </button> -->
            </div>
          </div>
        </div>
        <button
          *ngIf="gridContent[cell] && isEditingDashboard"
          class="btn btn-danger btn-sm"
          (click)="removeWidgetFromCell(cell)"
        >
          Remove
        </button>
      </div>
    </ng-container>
  </div>
  <!-- placeholder row end-->
</div>
