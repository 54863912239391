<!-- integration using username/password -->
<div class="">
  <div class="step" [class.active]="currentStep === 1">
    <div class="step-header" (click)="setStep(1)">
      <div class="step-number" *ngIf="currentStep === 1">1</div>
      <div class="step-number check-symbol" *ngIf="currentStep === 2"></div>
      <div class="step-title">Interface Details</div>
    </div>
    <div class="step-body" *ngIf="currentStep === 1">
      <form [formGroup]="usernameFormGroup">
        <div class="form-group">
          <label for="serverAddressUsername">Server Address</label>
          <input
            id="serverAddressUsername"
            type="text"
            class="form-control m-1"
            formControlName="serverAddressUsername"
            placeholder="https://my-server.com"
            required
          />
        </div>
        <div
          *ngIf="
            serverAddressUsername!.invalid &&
            (serverAddressUsername!.dirty || serverAddressUsername!.touched)
          "
          class="text-danger"
        >
          <div *ngIf="serverAddressUsername!.errors!['required']">
            server address is required.
          </div>
          <div *ngIf="serverAddressUsername!.errors!['pattern']">
            enter valid https address.
          </div>
        </div>
        <div class="form-group">
          <label for="username">Username</label>
          <input
            id="username"
            type="text"
            class="form-control m-1"
            formControlName="username"
            placeholder="enter your username"
            required
          />
        </div>
        <div
          *ngIf="username!.invalid && (username!.dirty || username!.touched)"
          class="text-danger"
        >
          <div *ngIf="username!.errors!['required']">username is required.</div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            id="password"
            type="text"
            class="form-control m-1"
            formControlName="password"
            placeholder="enter your password"
            required
          />
        </div>
        <div
          *ngIf="password!.invalid && (password!.dirty || password!.touched)"
          class="text-danger"
        >
          <div *ngIf="password!.errors!['required']">password is required.</div>
        </div>
        <div>
          <span class="text-green" *ngIf="interfaceTested"
            >Test successful</span
          >
          <app-spinner
            *ngIf="!interfaceTested && testingInterface"
          ></app-spinner>
        </div>
        <button
          type="button"
          class="btn btn-green m-2"
          (click)="testInterface()"
          [disabled]="usernameFormGroup.invalid"
        >
          Test
        </button>
        <button
          type="button"
          class="btn btn-green m-2"
          (click)="addInterface()"
          [disabled]="usernameFormGroup.invalid"
        >
          Add
        </button>
      </form>
    </div>
  </div>
  <div class="step mt-2" [class.active]="currentStep === 2">
    <div class="step-header">
      <div class="step-number" *ngIf="!interfaceAdded">2</div>
      <div class="step-number check-symbol" *ngIf="interfaceAdded"></div>
      <div class="step-title">Status</div>
    </div>
    <div class="step-body" *ngIf="currentStep === 2">
      <span class="text-green" *ngIf="interfaceAdded"
        >Interface Added successfully</span
      >
      <app-spinner *ngIf="!interfaceAdded && addingInterface"></app-spinner>
      <!-- <form [formGroup]="secondFormGroup">
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    class="form-control"
                    formControlName="lastName"
                    required
                  />
                </div>
                <button
                  type="button"
                  class="btn btn-green m-2"
                  (click)="previousStep()"
                >
                  Back
                </button>
                <button
                  type="button"
                  class="btn btn-green m-2"
                  (click)="nextStep()"
                >
                  Add
                </button>
              </form> -->
    </div>
  </div>
</div>
