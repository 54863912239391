import { Component, OnDestroy, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { Subscription } from 'rxjs';
import { SnapshotData } from 'src/app/core/models/snapshot-data';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-index-history-chart',
  templateUrl: './index-history-chart.component.html',
  styleUrls: ['./index-history-chart.component.scss']
})
export class IndexHistoryChartComponent implements OnInit, OnDestroy {
  private indexHistoryChart!: Chart<any>;
  private snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' };
  private snapshotSubscription!: Subscription;
  public isChartReady = false;

  constructor(
    private apiService: ApiService,
  ) {
    Chart.register(...registerables);
  }

  ngOnInit() {
    this.snapshotSubscription = this.apiService.getIndexSnapshot().subscribe(snapshot => {
      if (Object.keys(snapshot).length != 0) {
        this.snapshotData = snapshot;
        this.drawIndexHistoryChart();
      }
    })
  }
  ngOnDestroy() {
    this.indexHistoryChart?.destroy()
    this.snapshotSubscription.unsubscribe();
    this.apiService.changeIndexSnapshot({} as SnapshotData);
  }

  getColorArray(): string[] {
    const colors: string[] = this.snapshotData.score.map(score => {
      return HelperService.RBCScoreToRiskColor(score);
    });
    return colors;
  }

  private drawIndexHistoryChart() {
    this.indexHistoryChart != undefined ? this.indexHistoryChart.destroy() : '';
    const ctx2 = <HTMLCanvasElement>document.getElementById('indexHistoryChart')!;
    this.indexHistoryChart = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: environment.DEMO_ENABLE? HelperService.getLastWeekDates(): this.snapshotData.dates,
        datasets: [
          {
            label: ' RBC-Index',
            data: this.snapshotData.score,
            fill: false,
            backgroundColor: [
              '#444444',
            ],
            pointBackgroundColor: this.getColorArray()
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
          },
          tooltip: {
            mode: 'index'
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Last one week"
            }
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: "RBC-Index"
            }
          }
        }
      }
    });
    this.isChartReady = true;
  }
}
