import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DeviceParam } from "../../core/models/device-param";
import { ApiService } from "../../services/api.service";
import { LoadingService } from '../../services/loading.service';
import { ToastService } from "../../services/toast.service";

@Component({
  selector: 'app-device-param-settings',
  templateUrl: './device-param-settings.component.html',
  styleUrls: ['./device-param-settings.component.scss']
})
export class DeviceParamSettingsComponent implements OnInit {

  assetParams: Array<DeviceParam> = [];

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.apiService.getAllAssetParamNames()
      .subscribe(response => {
        const assetParamNames = response;
        this.apiService.getAllAssetParams()
          .subscribe(response => {
            for (let assetParam in response) {
              const index = assetParamNames.indexOf(response[assetParam].Name, 0);
              if (index > -1) {
                assetParamNames.splice(index, 1);
              }
            }
            let tmpAssetParams = response
            if (assetParamNames.length > 0) {
              for (let name in assetParamNames) {
                this.apiService.createAssetParam({
                  Active: false, Name: assetParamNames[name], Relevance: 0, Threshold: 0
                }).subscribe(response => {
                  const index = assetParamNames.indexOf(response.Data.Name, 0);
                  if (index > -1) {
                    assetParamNames.splice(index, 1);
                  }
                  tmpAssetParams.push(response.Data)
                  if (assetParamNames.length < 1) {
                    {
                      this.finishLoading(tmpAssetParams)
                    }
                  }
                })
              }
            } else {
              this.finishLoading(tmpAssetParams)
            }
          });
      });
  }

  finishLoading(tmpAssetParams: any) {
    tmpAssetParams.sort(function (a: DeviceParam, b: DeviceParam) {
      if (a.Name < b.Name) { return -1; }
      if (a.Name > b.Name) { return 1; }
      return 0;
    })
    this.assetParams = tmpAssetParams
  }
  updateRelevanceValues(e: any) {
    const inputs = Array.from(document.querySelectorAll('input[type="range"]:not([disabled])')) as HTMLInputElement[];
    let sum = inputs.reduce((sum, input) => sum + input.valueAsNumber, 0);
    if (sum > 1) {
      let excess = sum - 1;
      e.value = (parseFloat(e.value) - excess).toFixed(2)
      const dataIndex = (e.getAttribute('data-index'))
      this.assetParams[dataIndex].Relevance = e.value
    }
  }

  changeVisibility(e: any) {
    if (!e.checked) {
      const dataIndex = (e.getAttribute('data-index'))
      this.assetParams[dataIndex].Relevance = 0
    }
  }

  saveSettings() {
    const inputs = Array.from(document.querySelectorAll('input[type="range"]:not([disabled])')) as HTMLInputElement[];
    let sum = this.assetParams.reduce((sum, assetParam) => +sum + +assetParam.Relevance, 0);
    if (sum != 1 && inputs.length > 0) {
      this.toastService.showDanger('Please update the relevance values so the total is 100%!');
    } else {
      if (environment.DEMO_ENABLE) {
        this.toastService.showSuccess('Saved asset params successfully');
      }
      else {
        this.apiService.saveAssetParam(this.assetParams)
          .subscribe({
            next: (v) => {
              this.toastService.showSuccess('Saved asset params successfully');
              this.apiService.createHistory().subscribe();
              this.loadingService.removeRequest(); // createHistory is a slow request and should not block UI
            },
            error: (e) => this.toastService.showDanger('Could not save asset params'),
          });
      }
    }
  }
}
