import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PDFGeneratorService } from 'src/app/services/pdfgenerator.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent {
  @Input() reportUrl!: string;
  pdfDataUri: string = "";
  @Input() safeUrl: SafeResourceUrl = "";
  private pdfSubscription!: Subscription;
  constructor(public activeModal: NgbActiveModal, private pdfGeneratorService: PDFGeneratorService,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.pdfSubscription = this.pdfGeneratorService.currentPdfGenerated$.subscribe(pdfDataUri => {
      //console.log("hello", pdfDataUri);
      if (pdfDataUri) {
        this.pdfDataUri = pdfDataUri;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfDataUri);
      }
    });
  }
  closeModal(): void {
    this.activeModal.close();
  }

  downloadReport(): void {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];
    const formattedTime = currentDate.toLocaleTimeString().replace(/ /g, '-');
    console.log(formattedDate, formattedTime)
    const fileName = `rbc-report-${formattedDate}_${formattedTime}.pdf`;
    const link = document.createElement('a');
    link.href = this.reportUrl as string;
    link.download = fileName;
    link.click();
  }

  ngOnDestroy(): void {
    this.pdfSubscription!.unsubscribe();
    this.pdfGeneratorService.setCurrentPdfDataUri("")
  }
}
