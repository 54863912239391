import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerComponent } from 'src/app/components/pdf-viewer/pdf-viewer.component';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  reports = [{ name: "global report", date: "04/06/2024 10:58:34 AM", type: "Global" },
  { name: "stuttgart report", date: "03/06/2024 15:28:31 PM", type: "Location" },
  { name: "segment NOC_Production VLAN 58486 report", date: "05/06/2024 17:58:32 PM", type: "Segment" }
  ];
  key: string = '';
  reverse: boolean = true;
  searchTerm: string = '';
  filteredReports: { name: string; date: string; type: string; }[] = [];
  pagedReports: { name: string; date: string; type: string; }[] = [];
  itemsPerPage: number = 5;

  constructor(private modalService: NgbModal,
    private toastService: ToastService
  ) {
    this.filteredReports = this.reports;
    this.setPage(1);
  }
  viewReport(type: string) {
    const modalRef = this.modalService.open(PdfViewerComponent);
    modalRef.componentInstance.reportUrl = `/assets/reports/RiskByContext_Report_${type}.pdf`;
  }

  sendReport(name: string) {
    this.toastService.showSuccess(`${name} report sent successfully`);
  }

  setPage(page: number): void {
    const startIndex = (page - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.pagedReports = this.filteredReports.slice(startIndex, endIndex);
  }

  sort(key: string): void {
    this.key = key;
    this.reverse = !this.reverse;
    this.setPage(1);
  }

  searchReports(): void {
    const searchTerm = this.searchTerm.toLowerCase();
    this.filteredReports = this.reports.filter((report) => {
      const name = report.name.toLowerCase();
      return name.includes(searchTerm);
    });
    this.setPage(1);
  }
}
