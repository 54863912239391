<div class="container-fluid p-4">
  <div class="row justify-content-center mt-5">
      <img class="logo" src="assets/images/asvin-logo.png" style="width: 200px;" alt="asvin logo">
  </div>
  <div class="row justify-content-center mt-4">
    <div class="col-4">
      <div class="card">
        <div class="card-header">
          Login
        </div>
        <div class="card-body">
          <form (ngSubmit)="login()">
            <div class="form-group">
              <label for="email">Email:</label>
              <input type="text" id="email" name="email" class="form-control" [(ngModel)]="email" required>
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input type="password" id="password" name="password" class="form-control" [(ngModel)]="password" required>
            </div>
            <div class="mt-2">
              <button type="submit" class="btn btn-green ps-3 pe-3 pt-2 pb-2 ">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
