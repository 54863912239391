// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_BASE_URL: 'https://demo.api.rbc.asvin.io/api/v0.1',
  BEEHIVE_BASE_URL: 'https://dev.asvin.de',
  SESSION_COOKIE_NAME: 'beehive_sessionId',
  RBC_BASE_URL: 'https://dev.rbc.asvin.de',
  FACILITY_ENABLE: false,
  DEMO_ENABLE: true,
};
