import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';


import { DecimalPipe } from '@angular/common';
import { switchMap, tap } from 'rxjs/operators';
import { Device } from "../../core/models/device";
import { Vulnerability } from "../../core/models/vulnerability";
import { SortDirection, VulnerabilitySortColumn } from "../../directives/sortable.directive";
import { ApiService } from "../../services/api.service";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: VulnerabilitySortColumn;
  sortDirection: SortDirection;
}

interface VulnerabilitySearchResult {
  vulnerabilitys: Vulnerability[];
  total: number;
}

const compare = (v1: number | Array<Device> | Array<string> | string, v2: number | Array<Device> | Array<string> | string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);



@Injectable({ providedIn: 'root' })
export class VulnerabilityTableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _vulnerabilitys$ = new BehaviorSubject<Vulnerability[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  VULNERABILITIES: Vulnerability[] = []

  // private vulnerabilitySubscription!: Subscription;

  private _state: State = {
    page: 1,
    pageSize: 15,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(
    private apiService: ApiService,
    private pipe: DecimalPipe
  ) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        switchMap(() => this._search()),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._vulnerabilitys$.next(result.vulnerabilitys);
        this._total$.next(result.total);
      });

    this.apiService.currentDevice.subscribe(
      response => {
        if (response != undefined && response.id != undefined) {
          this.VULNERABILITIES = response.VulnerabilityList;
          this._search$.next();
        }
      }
    )
  }

  get vulnerabilitys$() {
    return this._vulnerabilitys$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: VulnerabilitySortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<VulnerabilitySearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let vulnerabilitys = this.sort(this.VULNERABILITIES, sortColumn, sortDirection);

    // 2. filter
    vulnerabilitys = vulnerabilitys.filter((vulnerability) => this.matches(vulnerability, searchTerm, this.pipe));
    const total = vulnerabilitys.length;

    // 3. paginate
    vulnerabilitys = vulnerabilitys.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ vulnerabilitys: vulnerabilitys, total });
  }

  sort(vulnerabilitys: Vulnerability[], column: VulnerabilitySortColumn, direction: string): Vulnerability[] {
    if (direction === '' || column === '') {
      return [...vulnerabilitys].sort((a, b) => {
        const res = compare(a['Score']!, b['Score']!);
        return -res;
      });
    } else {
      return [...vulnerabilitys].sort((a, b) => {
        const res = compare(a[column]!, b[column]!);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  matches(vulnerability: Vulnerability, term: string, pipe: PipeTransform) {
    return (
      vulnerability.Score.toString().includes(term) ||
      vulnerability.CveId.toLowerCase().includes(term)
    );
  }
}
