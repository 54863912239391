import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent {
  @Input() type: any;
  @Input() data: any;
  header: string = ""
  imgPath: string = "assets/images/location-high.svg"

  constructor(private apiService: ApiService) {
    // this.apiService.getCounter().subscribe(counterData => {
    //   this.type = counterData.type;
    //   this.data = counterData.data;
    // })
  }

  ngOnInit() {
    // console.log('Type:', this.type);
    // console.log('Count:', this.data);
    this.setHeaderAndImageForType(this.type)
  }

  setHeaderAndImageForType(type: string) {
    if (type == "cr-loc-ct") {
      this.header = "Number of Critical Locations"
      this.imgPath = "assets/images/location-high.svg"
    }
    else if (type == "cr-seg-ct") {
      this.header = "Number of Critical Segment"
      this.imgPath = "assets/images/segment-high.svg"
    }
    else {
      this.header = "Number of Critical Device"
      this.imgPath = "assets/images/device-high.svg"
    }
  }
}
