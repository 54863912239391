import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DecimalPipe, NgOptimizedImage } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as Sentry from "@sentry/angular-ivy";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DeviceParamSettingsComponent } from './components/device-param-settings/device-param-settings.component';
import { DeviceTableComponent } from './components/device-table/device-table.component';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { FacilityTableComponent } from './components/facility-table/facility-table.component';
import { GraphComponent } from './components/graph/graph.component';
import { IndexHistoryChartComponent } from './components/index-history-chart/index-history-chart.component';
import { LocationTableComponent } from './components/location-table/location-table.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { RiskLevelHistoryChartComponent } from './components/risk-level-history-chart/risk-level-history-chart.component';
import { SearchComponent } from './components/search/search.component';
import { SegmentParamSettingsComponent } from './components/segment-param-settings/segment-param-settings.component';
import { SegmentTableComponent } from './components/segment-table/segment-table.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { VulnerabilityTableComponent } from './components/vulnerability-table/vulnerability-table.component';
import { WorldMapComponent } from './components/world-map/world-map.component';
import { ToastContainerComponent } from "./core/components/toast-container/toast-container.component";
import { CoreModule } from "./core/core.module";
import { NgbdSortableHeader } from './directives/sortable.directive';
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { DashboardOldComponent } from './pages/dashboard-old/dashboard-old.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DeviceComponent } from './pages/device/device.component';
import { FacilityComponent } from './pages/facility/facility.component';
import { LocationComponent } from './pages/location/location.component';
import { DemoComponent } from './pages/demo/demo.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SegmentComponent } from './pages/segment/segment.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { GlobalErrorHandler } from './services/error-handling.service';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { WidgetDashboardComponent } from './pages/widget-dashboard/widget-dashboard.component';
import { CounterComponent } from './widgets/counter/counter.component';
import { ReportComponent } from './pages/report/report.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { InterfaceComponent } from './pages/interface/interface.component';
import { AddInterfaceComponent } from './components/add-interface/add-interface.component';
import { IntegrationPasswordComponent } from './components/integration-password/integration-password.component';
import { IntegrationTokenComponent } from './components/integration-token/integration-token.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardOldComponent,
    SegmentComponent,
    DeviceComponent,
    SegmentTableComponent,
    DeviceTableComponent,
    VulnerabilityTableComponent,
    NotFoundComponent,
    ErrorDialogComponent,
    SettingsComponent,
    SegmentParamSettingsComponent,
    DeviceParamSettingsComponent,
    LoginComponent,
    SpinnerComponent,
    LocationComponent,
    DemoComponent,
    FacilityComponent,
    SearchComponent,
    DashboardComponent,
    LocationTableComponent,
    FacilityTableComponent,
    GraphComponent,
    WorldMapComponent,
    IndexHistoryChartComponent,
    RiskLevelHistoryChartComponent,
    PdfViewerComponent,
    DoughnutChartComponent,
    WidgetDashboardComponent,
    CounterComponent,
    ReportComponent,
    OrderByPipe,
    InterfaceComponent,
    AddInterfaceComponent,
    IntegrationPasswordComponent,
    IntegrationTokenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    CoreModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    HttpClientModule,
    NoopAnimationsModule,
    FormsModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastContainerComponent,
    NgbdSortableHeader
  ],
  providers: [
    DecimalPipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // {
    //   provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    // }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
