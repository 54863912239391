import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SegmentParam } from "../../core/models/segment-param";
import { ApiService } from "../../services/api.service";
import { LoadingService } from '../../services/loading.service';
import { ToastService } from "../../services/toast.service";

@Component({
  selector: 'app-segment-param-settings',
  templateUrl: './segment-param-settings.component.html',
  styleUrls: ['./segment-param-settings.component.scss']
})
export class SegmentParamSettingsComponent implements OnInit {

  segmentParams: Array<SegmentParam> = [];

  constructor(
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.apiService.getAllSegmentParamNames()
      .subscribe(response => {
        const segmentParamNames = response;
        this.apiService.getAllSegmentParams()
          .subscribe(response => {
            for (let segmentParam in response) {
              const index = segmentParamNames.indexOf(response[segmentParam].Name, 0);
              if (index > -1) {
                segmentParamNames.splice(index, 1);
              }
            }
            let tmpSegmentParams = response
            if (segmentParamNames.length > 0) {
              for (let name in segmentParamNames) {
                this.apiService.createSegmentParam({
                  Active: false, Name: segmentParamNames[name], Relevance: 0, Threshold: 0
                }).subscribe(response => {
                  const index = segmentParamNames.indexOf(response.Data.Name, 0);
                  if (index > -1) {
                    segmentParamNames.splice(index, 1);
                  }
                  tmpSegmentParams.push(response.Data)
                  if (segmentParamNames.length < 1) {
                    {
                      this.finishLoading(tmpSegmentParams)
                    }
                  }
                })
              }
            } else {
              this.finishLoading(tmpSegmentParams)
            }
          });
      });
  }

  finishLoading(tmpSegmentParams: any) {
    tmpSegmentParams.sort(function (a: SegmentParam, b: SegmentParam) {
      if (a.Name < b.Name) { return -1; }
      if (a.Name > b.Name) { return 1; }
      return 0;
    })
    this.segmentParams = tmpSegmentParams
  }

  updateRelevanceValues(e: any) {
    const inputs = Array.from(document.querySelectorAll('input[type="range"]:not([disabled])')) as HTMLInputElement[];
    let sum = inputs.reduce((sum, input) => sum + input.valueAsNumber, 0);
    if (sum > 1) {
      let excess = sum - 1;
      e.value = (parseFloat(e.value) - excess).toFixed(2)
      const dataIndex = (e.getAttribute('data-index'))
      this.segmentParams[dataIndex].Relevance = e.value
    }
  }

  changeVisibility(e: any) {
    if (!e.checked) {
      const dataIndex = (e.getAttribute('data-index'))
      this.segmentParams[dataIndex].Relevance = 0
    }
  }

  saveSettings() {
    const inputs = Array.from(document.querySelectorAll('input[type="range"]:not([disabled])')) as HTMLInputElement[];
    let sum = this.segmentParams.reduce((sum, segmentParam) => +sum + +segmentParam.Relevance, 0);
    if (sum != 1 && inputs.length > 0) {
      this.toastService.showDanger('Please update the relevance values so the total is 100%!');
    } else {
      if (environment.DEMO_ENABLE) {
        this.toastService.showSuccess('Saved asset params successfully');
      }
      else {
        this.apiService.saveSegmentParam(this.segmentParams)
          .subscribe({
            next: (v) => {
              this.toastService.showSuccess('Saved segment params successfully');
              this.apiService.createHistory().subscribe();
              this.loadingService.removeRequest(); // createHistory is a slow request and should not block UI
            },
            error: (e) => this.toastService.showDanger('Could not save segment params'),
          });
      }
    }
  }
}
