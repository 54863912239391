import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';


import { DecimalPipe } from '@angular/common';
import { switchMap, tap } from 'rxjs/operators';
import { Device } from "../../core/models/device";
import { Segment } from "../../core/models/segment";
import { SegmentSortColumn, SortDirection } from "../../directives/sortable.directive";
import { ApiService } from "../../services/api.service";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SegmentSortColumn;
  sortDirection: SortDirection;
}

interface SegmentSearchResult {
  segments: Segment[];
  total: number;
}

const compare = (v1: number | Array<Device> | Array<string> | string, v2: number | Array<Device> | Array<string> | string) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);



@Injectable({ providedIn: 'root' })
export class SegmentTableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _segments$ = new BehaviorSubject<Segment[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  SEGMENTS: Segment[] = []

  selectedSegmentId?: string;
  selectedSegments?: Segment[];

  // private segmentSubscription!: Subscription;

  private _state: State = {
    page: 1,
    pageSize: 25,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(
    private apiService: ApiService,
    private pipe: DecimalPipe
  ) {

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        switchMap(() => this._search()),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._segments$.next(result.segments);
        this._total$.next(result.total);
      });

    this.apiService.currentFacility.subscribe(
      response => {
        if (response.id != undefined) {
          this.SEGMENTS = response.SegmentList;
          this._search$.next();
        }
      }
    )
  }

  get segments$() {
    return this._segments$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SegmentSortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SegmentSearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let segments = this.sort(this.SEGMENTS, sortColumn, sortDirection);

    // 2. filter
    segments = segments.filter((segment) => this.matches(segment, searchTerm, this.pipe));
    const total = segments.length;

    // 3. paginate
    segments = segments.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ segments: segments, total });
  }

  sort(segments: Segment[], column: SegmentSortColumn, direction: string): Segment[] {
    if (direction === '' || column === '') {
      return [...segments].sort((a, b) => {
        const res = compare(a['Score']!, b['Score']!);
        return -res;
      });
    } else {
      return [...segments].sort((a, b) => {
        const res = compare(a[column]!, b[column]!);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  matches(segment: Segment, term: string, pipe: PipeTransform) {
    return (
      segment.Network.toLowerCase().includes(term) ||
      segment.NetworkGroup.toLowerCase().includes(term) ||
      segment.NetworkLocation.toLowerCase().includes(term)
    );
  }
}
