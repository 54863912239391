import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Chart, registerables } from "chart.js";
import Konva from "konva";
import { Subscription } from "rxjs";
import { Graph } from 'src/app/core/models/graph';
import { Segment } from 'src/app/core/models/segment';
import { environment } from 'src/environments/environment';
import { Facility } from "../../core/models/facility";
import { Location } from "../../core/models/location";
import { SnapshotData } from "../../core/models/snapshot-data";
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { LoadingService } from "../../services/loading.service";
import { ToastService } from "../../services/toast.service";
import { Recommendation } from 'src/app/core/models/recommendation';
import { DeviceParam } from 'src/app/core/models/device-param';
import { SegmentHistory } from 'src/app/core/models/segment-history';
import { RiskLevel } from 'src/app/core/models/risk-level';
import { PdfViewerComponent } from 'src/app/components/pdf-viewer/pdf-viewer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit, OnDestroy {

  activeTab = 1;
  locationId!: string;
  isFacilityEnable: boolean = environment.FACILITY_ENABLE;
  recommendations: Recommendation[] = [];
  public isLocationDoughnutReady = false;
  // used in table placeholder
  public numberArray: number[] = Array.from({ length: 1 }, (_, index) => index + 1);

  private facilityId: string = '';
  private stage!: Konva.Stage;
  private facilityRanking: RiskLevel = { 'high': 0, 'medium': 0, 'low': 0, type: '' }
  private locationSubscription!: Subscription;
  private facilitySubscription!: Subscription;
  private historySubscription!: Subscription;
  private locationDoughnutChart!: Chart<any>;
  private locationScoreHistoryChart!: Chart<any>;
  private facilityOrSegmentHistoryInLocationChart!: Chart<any>;
  private snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
  private facilitySnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }

  private _location!: Location
  private _facility!: Facility
  rbcImage?: string;

  get location(): Location {
    return this._location;
  }

  set location(value: Location) {
    if (value !== this._location) {
      this._location = value;
      this.facilityRanking = { 'high': 0, 'medium': 0, 'low': 0, type: '' }
      if (this.isFacilityEnable) {
        for (let facility in value.FacilityList) {
          if (value.FacilityList[facility].Score! > 7) {
            this.facilityRanking.high++
          } else if (value.FacilityList[facility].Score! > 3) {
            this.facilityRanking.medium++
          } else {
            this.facilityRanking.low++
          }
        }
        this.facilityRanking.type = 'facility';
      }
      else { // facility is disabled. add segment details from 1st facility
        this.facilityRanking.high = value.FacilityList[0].High;
        this.facilityRanking.medium = value.FacilityList[0].Medium;
        this.facilityRanking.low = value.FacilityList[0].Low;
        this.facilityRanking.type = 'segment';
      }
      //this.loadImages()
      this.apiService.changeRiskLevelDoughnut(this.facilityRanking);
    }
  }

  get facility(): Facility {
    return this._facility;
  }

  private _zoomValue = 1;
  set zoomValue(value: number) {
    this._zoomValue = value;
    this.stage.scale({ x: value, y: value })
  }
  get zoomValue(): number {
    return this._zoomValue;
  }

  private _selectedFacility?: Facility
  get selectedFacility(): Facility | undefined {
    return this._selectedFacility;
  }

  set selectedFacility(value: Facility | undefined) {
    this._selectedFacility = value;
    this.apiService.changeFacility(value);
    if (value != undefined) {
      this.activeTab = 2
    } else {
      this.activeTab = 1
    }
  }

  private _segmentHistory?: SegmentHistory;
  get segmentHistory(): SegmentHistory | undefined {
    return this._segmentHistory;
  }

  set segmentHistory(value: SegmentHistory | undefined) {
    this._segmentHistory = value;
    this.rbcImage = undefined;
    this.recommendations = []
    if (value != undefined) {
      for (let param in value.Parameters) {
        if (value.Parameters[param].Score! > 7) {
          this.apiService.getSegmentSecurityRecommendationByName(value.Parameters[param].Name)
            .subscribe((response) => {
              this.recommendations.push(response)
            })
        }
      }
    }
  }

  private _selectedSegment?: Segment
  get selectedSegment(): Segment | undefined {
    return this._selectedSegment;
  }

  set selectedSegment(value: Segment | undefined) {
    this._selectedSegment = value;
    this.apiService.changeSegment(value);
    if (value != undefined) {
      this.activeTab = 2
      this.historySubscription = this.apiService.getLastSegmentHistoryBySegmentId(value.id).subscribe(
        response => {
          response.Parameters.sort(function (a: DeviceParam, b: DeviceParam) {
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
          })
          this.segmentHistory = response
        }
      )
    } else {
      this.activeTab = 1
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private modalService: NgbModal
  ) {
    Chart.register(...registerables);
  }

  ngOnInit() {
    this.locationSubscription = this.apiService.currentLocation.subscribe(
      response => {
        this.locationId = this.route.snapshot.paramMap.get('locationId')!
        if (response != undefined && response.id != undefined && response.id == this.locationId) {
          this.location = response
          this.facilityId = this.location.FacilityList[0].id
          // console.log("location2", this.location);
          this.updateSnapshotData(HelperService.getDateString(7), HelperService.getDateString())
          var graph: any;
          // check if facility level is enabled
          if (this.isFacilityEnable) {
            graph = HelperService.formateGraphData(response, "Location");
            this.apiService.changeGraph(graph);
          }
          else {
            // get 1st facility of the location
            this.facilitySubscription = this.apiService.getFacilityById(response.FacilityList[0].id).subscribe(resFacility => {
              this._facility = resFacility.Data;
              this.apiService.changeFacility(resFacility.Data);
              graph = HelperService.formateGraphData(resFacility.Data, "Facility");
              this.apiService.changeGraph(graph);
            })
          }
        } else {
          this.refreshData()
        }
      }
    )
  }


  ngOnDestroy() {
    this.locationDoughnutChart?.destroy()
    this.locationScoreHistoryChart?.destroy();
    this.facilityOrSegmentHistoryInLocationChart?.destroy();
    this.locationSubscription.unsubscribe();
    this.historySubscription?.unsubscribe()
    if (!this.isFacilityEnable && this.facilitySubscription)
      this.facilitySubscription.unsubscribe();
    this.apiService.changeGraph({} as Graph)
  }

  generateReport(): void {
    const modalRef = this.modalService.open(PdfViewerComponent);
    modalRef.componentInstance.reportUrl = '/assets/reports/RiskByContext_Report_Location.pdf';
  }

  refreshData(manual = false) {
    this.loadingService.addRequest()
    this.apiService.getLocationById(this.locationId)
      .subscribe(response => {
        this.location = response.Data
        this.apiService.changeLocation(response.Data);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        }
        this.loadingService.removeRequest()
      });
  }

  updateSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getLocationSnapshotByLocationIdAndDate(this.locationId, fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          var snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
          for (const snap in response.reverse()) {
            snapshotData.dates.push(response[snap]['Day'].substring(5))
            if (this.isFacilityEnable) {
              snapshotData.low.push(response[snap]['Low'])
              snapshotData.medium.push(response[snap]['Medium'])
              snapshotData.high.push(response[snap]['High'])
            }
            snapshotData.score.push(response[snap]['Score'])
          }
          if (this.isFacilityEnable) {
            snapshotData.type = "Facilities"
            this.apiService.changeRiskLevelSnapshot(snapshotData);
          }
          this.apiService.changeIndexSnapshot(snapshotData);
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
    if (!this.isFacilityEnable) {
      this.apiService
        .getFacilitySnapshotByFacilityIdAndDate(this.facilityId, fromDateString, toDateString)
        .subscribe({
          next: (response) => {
            var facilitySnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
            for (const snap in response.reverse()) {
              facilitySnapshotData.dates.push(response[snap]['Day'].substring(5))
              facilitySnapshotData.low.push(response[snap]['Low'])
              facilitySnapshotData.medium.push(response[snap]['Medium'])
              facilitySnapshotData.high.push(response[snap]['High'])
            }
            facilitySnapshotData.type = "Segments"
            this.apiService.changeRiskLevelSnapshot(facilitySnapshotData);
            this.loadingService.removeRequest()
          },
          error: (e) => this.loadingService.removeRequest()
        });
    }
  }

  indexToImgSrc(score: number, type: string) {
    return HelperService.RBCIndexToImageSrc(score, type);
  }

  indexToColor(score: number) {
    return HelperService.RBCScoreToRiskColor(score);
  }

  selectFacility($event: any) {
    var facilityId: any
    if ($event.target)
      facilityId = $event.target.getAttribute('data-facility')
    else
      facilityId = $event.facilityId

    this.apiService.getFacilityById(facilityId)
      .subscribe((response) => {
        this.selectedFacility = response.Data
      })
  }

  selectSegment($event: any) {
    if (this.isFacilityEnable) {
      this.router.navigate(['/dashboard/', this.locationId, this.selectedFacility!.id, $event.target.getAttribute('data-segment')])
    }
    else { //if facility level is disabled
      var segmentId: any
      if ($event.target)
        segmentId = $event.target.getAttribute('data-segment')
      else
        segmentId = $event.segmentId
      this.apiService.getSegmentById(segmentId)
        .subscribe((response) => {
          this.selectedSegment = response
        })
    }

  }

  // used only if facility level is disabled
  selectDevice($event: any) {
    this.router.navigate(['/dashboard/', this.locationId, this.facilityId, this.selectedSegment!.id, $event.target.getAttribute('data-asset')])
  }


  private loadImages(): void {
    const sources: Record<number, string> = {
      0: '/assets/images/segment-low.svg',
      1: '/assets/images/segment-medium.svg',
      2: '/assets/images/segment-high.svg'
    };

    const images: Record<string, HTMLImageElement> = {};
    let loadedImages = 0;
    let numImages = 3;

    // for (const src in sources) {
    //   images[src] = new Image(25, 25);
    //   images[src].onload = () => {
    //     if (++loadedImages >= numImages) {
    //       this.drawLocationCanvas(images)
    //     }
    //   };
    //   images[src].src = sources[src];
    // }
    //this.drawLocationDoughnutChart()
  }

  // private drawLocationCanvas(images: Record<string, HTMLImageElement>) {
  //   const canvas = document.getElementById('location-canvas');

  //   if (canvas !== null) {
  //     this.stage = new Konva.Stage({
  //       width: canvas!.offsetWidth,
  //       height: canvas!.offsetHeight,
  //       container: 'location-canvas',
  //       draggable: true,
  //     });

  //     const mainLayer = new Konva.Layer();
  //     this.stage.add(mainLayer);

  //     mainLayer.draw();


  //     let polygonPos = { x: 0, y: 150 }
  //     for (let facility in this.location.FacilityList) {
  //       let facilityObj = this.location.FacilityList[facility]
  //       let groupColor = "#cccccc"

  //       if (facilityObj.Score > 3) {
  //         groupColor = '#F19100'
  //       }
  //       if (facilityObj.Score > 7) {
  //         groupColor = '#D10C15'
  //       }

  //       let polygonGroup = new Konva.Group({
  //         name: facilityObj.Name,
  //         id: facilityObj.id,
  //         draggable: false
  //       })

  //       polygonPos = this.calculatePosition(polygonPos)
  //       let hexagon = new Konva.RegularPolygon({
  //         x: polygonPos.x,
  //         y: polygonPos.y,
  //         sides: 6,
  //         radius: 100,
  //         stroke: groupColor,
  //         strokeWidth: 4,
  //         name: facilityObj.Name,
  //       });

  //       polygonGroup.add(hexagon)

  //       let polygonClientRect = hexagon.getClientRect()

  //       let criticalImage = new Konva.Image({
  //         x: polygonClientRect.x + 50,
  //         y: polygonClientRect.y + 40,
  //         image: images[2]
  //       });
  //       polygonGroup.add(criticalImage)

  //       let mediumImage = new Konva.Image({
  //         x: polygonClientRect.x + 50,
  //         y: polygonClientRect.y + 70,
  //         image: images[1]
  //       });
  //       polygonGroup.add(mediumImage)

  //       let lowImage = new Konva.Image({
  //         x: polygonClientRect.x + 50,
  //         y: polygonClientRect.y + 100,
  //         image: images[0]
  //       });
  //       polygonGroup.add(lowImage)

  //       let highText = new Konva.Text({
  //         x: polygonClientRect.x + 85,
  //         y: polygonClientRect.y + 40,
  //         height: 25,
  //         text: `High ${facilityObj.High}`,
  //         fontSize: 12,
  //         fill: '#707070',
  //         verticalAlign: 'middle'
  //       });

  //       polygonGroup.add(highText)

  //       let mediumText = new Konva.Text({
  //         x: polygonClientRect.x + 85,
  //         y: polygonClientRect.y + 70,
  //         height: 25,
  //         text: `Medium ${facilityObj.Medium}`,
  //         fontSize: 12,
  //         fill: '#707070',
  //         verticalAlign: 'middle'
  //       });
  //       polygonGroup.add(mediumText)

  //       let lowText = new Konva.Text({
  //         x: polygonClientRect.x + 85,
  //         y: polygonClientRect.y + 100,
  //         height: 25,
  //         text: `Low ${facilityObj.Low}`,
  //         fontSize: 12,
  //         fill: '#707070',
  //         verticalAlign: 'middle'
  //       });
  //       polygonGroup.add(lowText)


  //       let networkNameText = new Konva.Text({
  //         x: polygonClientRect.x,
  //         y: polygonClientRect.y + 140,
  //         height: 12,
  //         width: polygonClientRect.width,
  //         text: facilityObj.Name,
  //         fontSize: 10,
  //         fill: '#707070',
  //         align: 'center'
  //       });
  //       polygonGroup.add(networkNameText)

  //       polygonGroup.on('click', () => {
  //         this.apiService.getFacilityById(polygonGroup.id())
  //           .subscribe((response) => {
  //             this.selectedFacility = response.Data
  //           })
  //       })
  //       polygonGroup.on('mouseover', () => {
  //         this.stage.container().style.cursor = 'pointer';
  //       })
  //       polygonGroup.on('mouseout', () => {
  //         this.stage.container().style.cursor = 'default';
  //       })
  //       mainLayer.add(polygonGroup)
  //     }
  //   }
  //   //this.drawLocationGraphs()
  //   console.log("hell")
  //   this.drawLocationScoreHistoryGraph()

  // }

  drawLocationDoughnutChart() {
    this.locationDoughnutChart != undefined ? this.locationDoughnutChart.destroy() : '';

    const ctx = <HTMLCanvasElement>document.getElementById('doughnutLocationChart')!;

    this.locationDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['High', 'Medium', 'Low'],
        datasets: [{
          // last index contains status of facility visibility
          data: [this.facilityRanking.high, this.facilityRanking.medium, this.facilityRanking.low, Number(this.isFacilityEnable)],
          borderWidth: 1,
          backgroundColor: [
            '#D10C15',
            '#F19100',
            '#AFCA0B'
          ],
          hoverOffset: 4
        }]
      },
      plugins: [{
        id: "doughnutLocationChart",
        beforeInit: function (chart, args, options) {
          const datasets = chart.data.datasets!;
          if (chart.canvas.id === "doughnutLocationChart") {
            const ul = document.createElement('ul');
            chart?.data?.labels?.forEach((label, index) => {
              const labelString = label as string;
              // check if facility is enabled
              if (datasets[0].data[3]) {
                ul.innerHTML += `
              <li>
                <img src="assets/images/facility-${labelString.toLowerCase()}.svg" style="width:30px; height:30px"></img>
                ${label}: ${datasets[0].data[index]}
              </li>
              `;
              }
              else {
                ul.innerHTML += `
              <li>
                <img src="assets/images/segment-${labelString.toLowerCase()}.svg" style="width:30px; height:30px"></img>
                ${label}: ${datasets[0].data[index]}
              </li>
              `;
              }
            });
            return document!.getElementById("donut-location-legend")!.innerHTML = ul.outerHTML;
          }

          return;
        }
      }],
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
    this.isLocationDoughnutReady = true;
  }

  private calculatePosition(polygonPos: { x: number; y: number }) {
    if (polygonPos.x + 300 >= this.stage.width()) {
      if (polygonPos.x % 178 == 0) {
        polygonPos.x = 267
      } else {
        polygonPos.x = 178
      }
      polygonPos.y += 154
    } else {
      polygonPos.x += 178;
    }

    return polygonPos
  }
}
