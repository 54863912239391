import { Component, ElementRef, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { Subscription } from 'rxjs';
import { RiskLevel } from 'src/app/core/models/risk-level';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent {
  @ViewChild('doughnutCanvas', { static: true }) doughnutCanvas!: ElementRef;

  public isDoughnutChartReady = false;
  private doughnutChart!: Chart<any>;
  private riskLevelDoughnutData: RiskLevel = { high: 0, low: 0, medium: 0, type: '' };
  private riskLevelSubscription!: Subscription;
  private labels = ['High', 'Medium', 'Low'];

  constructor(
    private apiService: ApiService
  ) {
    Chart.register(...registerables);
  }

  private getValueByLabel(label: string): number {
    if (label == "High") {
      return this.riskLevelDoughnutData.high
    }
    else if (label == "Medium") {
      return this.riskLevelDoughnutData.medium
    }
    else {
      return this.riskLevelDoughnutData.low
    }
  }
  ngOnInit() {
    this.riskLevelSubscription = this.apiService.getRiskLevelDoughnut().subscribe((riskLevels: RiskLevel) => {
      if (Object.keys(riskLevels).length != 0) {
        //console.log("doughnut", riskLevels);
        this.riskLevelDoughnutData = riskLevels;
        this.drawDoughnutChart();

        // setTimeout function to trigger change detect event
        setTimeout(() => {
          // add list element to legend div
          const ul = document.createElement('ul');
          this.labels.forEach((label, index) => {
            ul.innerHTML += `
          <li>
            <img src="assets/images/${this.riskLevelDoughnutData.type}-${label.toLowerCase()}.svg" style="width:30px; height:30px"></img>
            ${label}:${this.getValueByLabel(label)}
          </li>
        `;
          });
          document!.getElementById("donut-dashboard-legend")!.innerHTML = ul.outerHTML;
        }, 10);
      }
    })

  }

  ngOnDestroy() {
    this.doughnutChart?.destroy()
    this.riskLevelSubscription!.unsubscribe();
    this.apiService.changeRiskLevelDoughnut({} as RiskLevel);
  }

  private drawDoughnutChart() {

    this.doughnutChart != undefined ? this.doughnutChart.destroy() : '';

    //const ctx = <HTMLCanvasElement>document.getElementById('doughnutDashboardChart')!;
    const ctx = this.doughnutCanvas.nativeElement;
    this.doughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: this.labels,
        datasets: [{
          data: [this.riskLevelDoughnutData.high, this.riskLevelDoughnutData.medium, this.riskLevelDoughnutData.low],
          borderWidth: 1,
          backgroundColor: [
            '#D10C15',
            '#F19100',
            '#AFCA0B'
          ],
          hoverOffset: 4
        }]
      },
      // plugins: [{
      //   id: "doughnutDashboardChart",
      // }],
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
    this.isDoughnutChartReady = true;
  }
}
