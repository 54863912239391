<div class="row">
    <div class="col">
        <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Filter:</label>
                <div class="col-auto me-auto">
                    <input
                            id="table-complete-search"
                            type="text"
                            class="form-control"
                            name="searchTerm"
                            [(ngModel)]="service.searchTerm"
                    />
                </div>
                <div class="col-auto align-self-end">
                    <ngb-pagination
                            [collectionSize]="(total$ | async)!"
                            [(page)]="service.page"
                            [pageSize]="service.pageSize"
                            [maxSize]="10"
                            [rotate]="true"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
            </div>
            <!-- location table -->
            <table class="table table-striped table-hover" *ngIf="(locations$|async)?.length; else loadingTable">
                <thead>
                <tr>
                    <th scope="col" sortable="Score" (sort)="onSort($event)">RBC-Index</th>
                    <th scope="col" sortable="Name" (sort)="onSort($event)">Location</th>
                    <th scope="col" sortable="High" (sort)="onSort($event)">
                        <img [ngSrc]="getImageSrc('high')" alt="high" height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Medium" (sort)="onSort($event)">
                        <img [ngSrc]="getImageSrc('medium')" alt="medium" height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Low" (sort)="onSort($event)">
                        <img [ngSrc]="getImageSrc('low')" alt="low" height="24" width="24"/>
                    </th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let location of locations$|async; index as i">
                    <td>{{ location.Score | number: '1.1-1' }}/10</td>
                    <td>
                        <div  class="text-green asset-detail">
                            <ngb-highlight role="button" [ngStyle]="{'color': indexToColor(location.Score)}" (click)="locationWasClicked($event)" [result]=" location.Name" [term]="service.searchTerm" [attr.data-location] = location.id></ngb-highlight>
                        </div>
                    </td>
                    <td *ngIf="isFacilityEnable">{{ location.High | number }}</td>
                    <td *ngIf="isFacilityEnable">{{ location.Medium | number }}</td>
                    <td *ngIf="isFacilityEnable">{{ location.Low | number }}</td>
                    <td *ngIf="isFacilityEnable">{{ location.Low! + location.Medium! + location.High!}} </td>
                    <td *ngIf="!isFacilityEnable">{{ location.FacilityList[0].High | number }}</td>
                    <td *ngIf="!isFacilityEnable">{{ location.FacilityList[0].Medium | number }}</td>
                    <td *ngIf="!isFacilityEnable">{{ location.FacilityList[0].Low | number }}</td>
                    <td *ngIf="!isFacilityEnable">{{ location.FacilityList[0].Low! + location.FacilityList[0].Medium! + location.FacilityList[0].High!}} </td>
                  </tr>
                </tbody>
            </table>
            <!-- placeholder for location table -->
            <ng-template #loadingTable>
            <table class="table table-striped" >
              <thead>
              <tr>
                  <th scope="col" >RBC-Index</th>
                  <th scope="col" >Location</th>
                  <th scope="col" >
                      <img [ngSrc]="getImageSrc('high')" alt="vulnerability-high" height="24" width="24"/>
                  </th>
                  <th scope="col" >
                      <img [ngSrc]="getImageSrc('medium')" alt="vulnerability-medium"
                           height="24" width="24"/>
                  </th>
                  <th scope="col">
                      <img [ngSrc]="getImageSrc('low')" alt="vulnerability-low"
                           height="24" width="24"/>
                  </th>
                  <th scope="col">Total</th>
              </tr>
              </thead>
              <tbody>
                <tr *ngFor="let i of numberArray" class="placeholder-glow" [class.no-hover]=true aria-hidden="true">
                  <td> <span class="placeholder col-2"></span></td>
                  <td> <span class="placeholder col-3"></span></td>
                  <td> <span class="placeholder col-2"></span></td>
                  <td> <span class="placeholder col-2"></span></td>
                  <td> <span class="placeholder col-2"></span></td>
                  <td> <span class="placeholder col-2"></span></td>
                </tr>
              </tbody>
            </table>
            </ng-template>
        </form>
    </div>
</div>
