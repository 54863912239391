<div class="container-fluid p-4">
    <!-- menu and search button -->
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs border-bottom-0">
                <li class="nav-item">
                    <a class="nav-link active" href="#">Dashboard</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">Location</a>
                </li>
                <li class="nav-item" *ngIf="isFacilityEnable">
                    <a class="nav-link disabled" href="#">Facility</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">Segment</a>
                </li>
            </ul>
        </div>
        <div class="col col-auto pe-0">
            <app-search></app-search>
        </div>
    </div>
    <div class="row shadow position-relative">
        <div class="col">
            <div class="row bg-white p-2 border-top">
                <div class="col me-auto">
                    <div class="location-name">
                        <h3 class="mb-0">Locations Overview</h3>
                    </div>
                </div>
                <div class="col col-auto">
                    <app-spinner (onSpinnerClick)="refreshData(true)"></app-spinner>
                </div>
                <div class="col col-auto">
                  <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end" (click)="generateReport()">Generate Report</button>
                </div>
            </div>

            <div class="row p-2 bg-white">
              <div class="col-9" id="world-map-location">
                <div class="row">
                  <app-world-map></app-world-map>
                </div>
              </div>
              <!-- right side panel to the location graph -->
              <div class="col-3 shadow bg-gray position-relative">
                <!-- current location img, name and risk level -->
                <div class="row mt-3">
                  <div class="col-auto">
                    <img
                      src="assets/images/txy-logo.png"
                      height="64"
                      width="64"
                      alt="org-image"
                    />
                  </div>
                  <div class="col me-auto">
                    <h4>TXY GmbH</h4>
                    <h4 class="text-black">Global Locations</h4>
                  </div>
                </div>
                <!-- global metadata table -->
                <div class="row mt-2">
                  <div class="col">
                    <table
                      class="table table-borderless table-gray"
                      *ngIf="locationRanking"
                    >
                      <thead class="border-bottom">
                        <tr>
                          <th class="text-danger">High:</th>
                          <th class="text-danger">{{ locationRanking.high }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="border-bottom">
                          <td class="text-warning">Medium:</td>
                          <td class="text-warning">{{ locationRanking.medium }}</td>
                        </tr>
                        <tr class="border-bottom">
                          <td style="color: #92c029">Low:</td>
                          <td style="color: #92c029">{{ locationRanking.low }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- node tooltip -->
                <div class="row mt-2" id="node-tooltip">
                  <div class="col col-12 mb-12">
                    <div class="card">
                      <div class="card-body">
                        <a
                          class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3"
                          id="detail-node-name"
                          >Name</a
                        >
                        <div class="row mb-4">
                          <div class="col col-6">
                            <img
                              src="assets/images/facility-high.svg"
                              class="card-img-top"
                              height="94"
                              width="150"
                              alt="facility-image"
                              id="detail-node-img"
                            />
                          </div>
                          <div class="col col-6">
                            <span class="fw-bold">RBC-Index</span>
                            <h4 class="" id="detail-node-score">Score/10</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col col-auto">
                            <!-- <p class="mb-0 small" id="detail-node-facility">Facilities: </p> -->
                            <!-- <p class="mb-0 small" id="detail-node-child-number">
                              Segments:
                            </p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row bg-white p-2 border-top">
              <div class="col me-auto">
                  <div class="location-name">
                      <h3 class="mb-0">Most critical locations to watch</h3>
                  </div>
              </div>
          </div>
            <div class="row p-2 bg-white">
                <!-- most critical location canvas -->
                <div class="col-8 position-relative">
                    <div class="row" *ngIf="locations?.length; else loadingCriticalLocations">
                      <ng-container *ngFor="let location of locations">
                        <div class="col col-3 mb-3" *ngIf="location.Score > 7">
                            <div class="card" (click)="selectLocation({locationId: location.id})"  style="cursor: pointer;">
                                <div class="card-body">
                                    <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3">{{location.Name}}</a>
                                    <div class="row mb-4">
                                        <div class="col col-6">
                                            <img [src]="indexToImgSrc(location.Score, 'location')" class="card-img-top" height="94" width="150" alt="location-image" />
                                        </div>
                                        <div class="col col-6 ">
                                            <span class="fw-bold">RBC-Index</span>
                                            <h4 class="">{{location.Score | number:'1.1-1'}}/10</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-auto">
                                            <p class="mb-0 small" *ngIf="isFacilityEnable">Facilities: {{location.FacilityList.length}}</p>
                                            <p class="mb-0 small" *ngIf="isFacilityEnable">Segments: {{segmentsInLocation(location)}}</p>
                                            <p class="mb-0 small" *ngIf="!isFacilityEnable">Segments: {{location.FacilityList[0].Segments.length}}</p>
                                            <!-- <p class="mb-0 small">Devices: ?</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </ng-container>
                    </div>
                    <!-- placeholder for most critical location cards -->
                    <ng-template #loadingCriticalLocations>
                      <div  div class="row">
                        <div class="col col-3 mb-3" *ngFor="let i of numberArray">
                            <div class="card" style="cursor: pointer;">
                                <div class="card-body placeholder-glow">
                                    <span class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3 placeholder">&nbsp;</span>
                                    <div class="row mb-4">
                                        <div class="col col-6">
                                            <span class="card-img-top placeholder" style="height: 94px">&nbsp;</span>
                                        </div>
                                        <div class="col col-6 ">
                                          <span class="fw-bold placeholder">RBC-Index</span>
                                          <span class="placeholder">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-auto">
                                            <span class="mb-0 small placeholder" *ngIf="isFacilityEnable">Facilities: Facilities</span>
                                            <span class="mb-0 small placeholder">Segments: Segments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </ng-template>
                </div>
                <!-- right side panel to the most critical locations canvas -->
                <div class="col-4 shadow bg-gray position-relative">
                    <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto" *ngIf="selectedLocation">
                        <div class="col mt-3">
                            <div class="row">
                                <div class="col-auto">
                                    <img [src]="indexToImgSrc(selectedLocation.Score, 'location')" height="64" width="64" alt="location-image"/>
                                </div>
                                <div class="col me-auto">
                                    <h4>
                                        {{selectedLocation.Name}}
                                    </h4>
                                    <h4 *ngIf="selectedLocation.Score! >= 7" class="text-danger">High Risk</h4>
                                    <h4 *ngIf="selectedLocation.Score! >= 4  && selectedLocation.Score! < 7" class="text-warning">Medium Risk</h4>
                                    <h4 *ngIf="selectedLocation.Score! < 4" class="text-green">Low Risk</h4>
                                </div>
                                <div class="col-auto ">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="selectedLocation=undefined"></button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <table class="table table-borderless table-gray ">
                                        <thead class="border-bottom">
                                        <tr>
                                            <th>RBC-Index</th>
                                            <th>{{selectedLocation!.Score | number: '1.1-1'}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="border-bottom">
                                            <td>Name:</td>
                                            <td>{{selectedLocation!.Name}}</td>
                                        </tr>
                                        <!-- </tbody>
                                        <tbody> -->
                                          <tr class="border-bottom" *ngIf="isFacilityEnable">
                                              <td>Facilities:</td>
                                              <td>{{selectedLocation!.FacilityList.length}}</td>
                                          </tr>
                                          <tr class="border-bottom" *ngIf="!isFacilityEnable">
                                            <td>Segments:</td>
                                            <td>{{selectedLocation!.FacilityList[0].Segments.length}}</td>
                                          </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- index history chart for selected location -->
                            <div class="row mb-3" *ngIf="selectedLocation">
                              <div class="col">
                                  <app-index-history-chart></app-index-history-chart>
                              </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-auto">
                                    <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end mt-3" [routerLink]="['/dashboard/', selectedLocation!.id]">Show Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h4 class="text-gray">RISK-BY-CONTEXT</h4>
                        </div>
                    </div>

                    <!-- doughnut -->
                    <div class="row justify-content-center" >
                      <app-doughnut-chart></app-doughnut-chart>
                    </div>
                    <!-- global locations risk level history chart -->
                    <div class="row mb-3">
                      <div class="col">
                          <app-risk-level-history-chart></app-risk-level-history-chart>
                      </div>
                   </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="true" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Location Overview</a>
                <ng-template ngbNavContent>
                    <app-location-table (onLocationSelect)="selectLocation($event)"></app-location-table>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="selectedLocation != undefined && isFacilityEnable">
                <a ngbNavLink>Facility Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-facility-table (onFacilitySelect)="selectFacility($event)"> </app-facility-table>
                </ng-template>
            </li>
            <!-- these tabs will be shown when facility level is disable -->
            <li [ngbNavItem]="2" *ngIf="selectedLocation != undefined && !isFacilityEnable">
              <a ngbNavLink>Segment Risk-By-Context</a>
              <ng-template ngbNavContent>
                  <app-segment-table (onSegmentSelect)="selectSegment($event)"></app-segment-table>
              </ng-template>
            </li>
        </ul>
    </div>
    <div class="row shadow position-relative">
        <div [ngbNavOutlet]="nav" class="pt-2 bg-white border-top"></div>
    </div>

</div>
