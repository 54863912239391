import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { Link, Node } from "../../core/models/graph";
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, OnDestroy {
  @Input() width!: number;
  @Input() height!: number;

  private svg: any;
  private facilityId: string = '';
  private simulation: any;
  private nodeTooltip: any;
  private nodes: Node[] = [];
  private links: Link[] = [];
  private svgWidth = 400;
  public svgHeight = 400;
  private nodeText: any;
  public isGraphReady = false;
  //private currentPath:string;

  @Output() onNodeSelect: EventEmitter<any> = new EventEmitter();

  constructor(private el: ElementRef, private apiService: ApiService, private router: Router) {
  }
  private graphSubscription: Subscription = new Subscription();

  ngOnInit(): void {
    if (this.width && this.height) {
      this.svgWidth = this.width;
      this.svgHeight = this.height;
    }

    var graphElement = document.getElementById('node-graph')!;
    if (graphElement) {
      this.svgWidth = graphElement.offsetWidth;
      this.svgHeight = this.height;
    }
    // console.log("graph", graphElement)
    this.graphSubscription = this.apiService.getGraph().subscribe((graph: any) => {
      //console.log("graph", graph)
      if (graph.nodes) {
        //console.log("graph", graph)
        this.links = graph.links;
        this.nodes = graph.nodes;
        this.facilityId = graph.id;
        this.createForceDirectedGraph();
      }

    })

  }

  ngOnDestroy(): void {
    this.graphSubscription.unsubscribe();
  }



  private createForceDirectedGraph(): void {

    // create svg element
    this.svg = d3.select(this.el.nativeElement).append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    // create links
    this.svg.selectAll('.link')
      .data(this.links)
      .enter().append('line')
      .attr('class', 'link')
      .attr("stroke", "orange")
      .attr("stroke-opacity", 0.6);


    // create nodes
    const nodes = this.svg.selectAll('.node')
      .data(this.nodes)
      //.enter().append('g')
      .enter().append('circle')
      .attr('class', 'node')
      .attr('id', (d: Node) => `node-${d.id}`)
      .attr('r', 20)
      .attr("fill", (d: Node) => this.riskLevelToColor(d.riskLevel))
      .attr("stroke", "#fff")
      .attr("stroke-width", 2)
      .on('mouseover', (event: any, d: Node) => this.mouseOverNode(event, d))
      .on('mouseout', (event: any, d: Node) => this.mouseOutNode(event, d))
      .on('click', (event: any, d: Node) => this.clickNode(event, d))
      .call(d3.drag()
        .on('start', (event: any, d: any) => this.dragStarted(event, d))
        .on('drag', (event: any, d: any) => this.dragged(event, d))
        .on('end', (event: any, d: any) => this.dragEnded(event, d))
      );
    this.isGraphReady = true;
    // // Append the custom SVG icon to each node
    // nodes.append('foreignObject')
    //   .attr('width', 30)
    //   .attr('height', 30)
    //   // .style('background-color', 'transparent')
    //   .append('xhtml:body')
    //   .style('background-color', 'transparent')
    //   .html((d: any) => `<img src="${this.getImageSource(d.depth, d.riskLevel)}" width="20" height="20" />`);


    // create a force simulation
    this.simulation = d3.forceSimulation<Node, Link>(this.nodes)
      .force('link', d3.forceLink(this.links).id((d: any) => d.id).distance(80))
      .force('charge', d3.forceManyBody().strength(-100))
      .force('center', d3.forceCenter(this.svgWidth / 2, this.svgHeight / 2).strength(0.01))
      .force('x', d3.forceX().strength(0.1).x(this.svgWidth / 2))
      .force('y', d3.forceY().strength(0.1 * (this.svgWidth / this.svgHeight)).y(this.svgHeight / 2))
      .force('collide', d3.forceCollide().radius(80))
      .on('tick', () => this.ticked());
  }

  private ticked(): void {
    const padding = 10; // Adjust this value as needed

    // Update links
    this.svg.selectAll('.link')
      .attr('x1', (d: any) => Math.max(padding, Math.min(this.svgWidth - padding, d.source.x)))
      .attr('y1', (d: any) => Math.max(padding, Math.min(this.svgHeight - padding, d.source.y)))
      .attr('x2', (d: any) => Math.max(padding, Math.min(this.svgWidth - padding, d.target.x)))
      .attr('y2', (d: any) => Math.max(padding, Math.min(this.svgHeight - padding, d.target.y)))
      .style('stroke', (d: Link) => d.color)
      .style('stroke-width', 2)
      .style('stroke-length', 10);

    // Update nodes with constraints
    this.svg.selectAll('.node')
      .attr('transform', (d: any) => `translate(${Math.max(padding, Math.min(this.svgWidth - padding, d.x))},${Math.max(padding, Math.min(this.svgHeight - padding, d.y))})`);

    // // Update the positions of custom SVG icons
    // this.svg.select('foreignObject')
    //   .attr('x', -15) // Adjust the x-offset for proper positioning
    //   .attr('y', -15) // Adjust the y-offset for proper positioning

  }

  private dragStarted(event: any, d: any): void {
    if (!event.active) this.simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  private dragged(event: any, d: any): void {
    d.fx = event.x;
    d.fy = event.y;
  }

  private dragEnded(event: any, d: any): void {
    if (!event.active) this.simulation.alphaTarget(0);
    d.fx = d.x;
    d.fy = d.y;
  }

  private mouseOverNode(event: any, d: Node): void {
    this.nodeTooltip = document.getElementById('node-tooltip')!;
    var nodeName = document.getElementById('detail-node-name')!;
    var nodeImg = document.getElementById('detail-node-img')! as HTMLImageElement;
    var nodeScore = document.getElementById('detail-node-score')!;
    var nodeChild = document.getElementById('detail-node-child-number')!;
    //var nodeSegment = document.getElementById('detail-node-segment')!;
    nodeName.innerText = d.name;
    nodeImg.src = HelperService.RBCIndexToImageSrc(d.score, d.type!)
    if (d.type == "facility") {
      nodeChild.innerText = "Segments: " + String(d.children);
    }
    else if (d.type == "segment") {
      nodeChild.innerText = "Devices: " + String(d.children);
    }
    else if (d.type == "device") {
      nodeChild.innerText = "Vulnerabilities: " + String(d.children);
    }
    else {
      nodeChild.innerText = "Facilities: " + String(10);
    }
    nodeName.innerText = d.name;
    if (d.score)
      nodeScore.innerText = String(d.score.toFixed(1)) + "/10";
    // show node tooltip
    this.nodeTooltip.style.display = 'initial';

    d3.select(this.el.nativeElement.querySelector(`#node-${d.id}`))
      .transition()
      .attr('r', 25);
  }

  private mouseOutNode(event: any, d: Node): void {
    // disable node tooltip
    this.nodeTooltip.style.display = 'none';
    d3.select(this.el.nativeElement.querySelector(`#node-${d.id}`))
      .transition()
      .attr('r', 20);

  }

  private clickNode(event: any, d: Node): void {

    // console.log('Clicked on node:', d.name);
    if (d.type == "device") {
      const nodeObject = { 'nodeId': d.id };
      this.onNodeSelect.emit(nodeObject);

    }
    else
      if (environment.FACILITY_ENABLE) {
        // navigate to facility level
        this.router.navigate([`${this.router.url}/${d.id}`])
      }
      else { // if facility level is disable, navigate directly to segment level
        this.router.navigate([`${this.router.url}/${this.facilityId}/${d.id}`])
      }
  }

  private getImageSource(depth: number, riskLevel: string): string {
    // Adjust the logic based on your depth levels and corresponding image names
    if (depth == 0)
      return `assets/images/location-${riskLevel}.svg`;
    else if (depth == 1)
      return `assets/images/facility-${riskLevel}.svg`;
    else if (depth == 2)
      return `assets/images/segment-circle-${riskLevel}.svg`;
    else
      return `assets/images/asset-${riskLevel}.svg`;
  }

  // Define a function to map numerical values to string colors
  private numberToColor(numberValue: number | undefined): string {
    // Define a set of colors
    const colors = ['green', 'orange', 'red'];

    // Use the number modulo the length of the colors array to get a color
    return numberValue !== undefined ? colors[numberValue % colors.length] : 'defaultColor';
  }

  /**
  * Map risk level to a color.
  *
  * @param {string} riskLevel - Risk level (low, medium, high).
  * @returns {string} - Color associated with risk level.
  */
  private riskLevelToColor(riskLevel: string | undefined): string {
    // Define a set of colors
    const colors = ['#AFCA0B', '#F19100', '#D10C15'];
    if (riskLevel == "low")
      return colors[0];
    else if (riskLevel == "medium")
      return colors[1];
    else
      return colors[2];
  }

}
