<div class="modal-content bg-white" style="width: 800px; height: 900px">
  <div class="modal-header">
    <h4 class="modal-title">Risk By Context&trade; Report</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <!-- <span aria-hidden="true">&times;</span> -->
    </button>
  </div>
  <div class="modal-body">
    <pdf-viewer
      *ngIf="reportUrl"
      [src]="reportUrl"
      [render-text]="true"
      [original-size]="true"
      style="width: 100%; height: 700px"
    >
    </pdf-viewer>
    <!-- <iframe
      width="100%"
      height="700px"
      [src]="safeUrl"
      frameborder="0"
    ></iframe> -->
    <p *ngIf="!safeUrl && !reportUrl">Generating Report...</p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-green ps-3 pe-3 pt-2 pb-2"
      (click)="downloadReport()"
    >
      Download
    </button>
  </div>
</div>
