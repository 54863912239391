import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { FeatureCollection } from 'geojson';
import { Coordinate, Edge, Vertex } from 'src/app/core/models/map';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';
import * as topojson from 'topojson-client';
//import { Link } from "../../core/models/graph";

// interface Node {
//   id: string;
//   name: string;
//   coordinates: [number, number];
// }

@Component({
  selector: 'app-world-map',
  templateUrl: './world-map.component.html',
  styleUrls: ['./world-map.component.scss']
})
export class WorldMapComponent implements OnInit {
  @ViewChild('map', { static: true }) private mapContainer!: ElementRef;
  @Input() width!: number;
  @Input() height!: number;

  private isFacilityEnable: boolean = environment.FACILITY_ENABLE
  private mapWidth: number = 800;
  public mapHeight: number = 600;
  private svg!: any;
  private projection!: any;
  private zoom!: d3.ZoomBehavior<Element, unknown>;
  private nodeTooltip: any;
  private nodeEdgeGroup: any;
  private countryPathGroup: any;
  private nodeSize = 6;
  private newNodeSize = 4;
  public isMapReady = false;
  // nodes
  nodes: any[] = [];
  // Edges data
  edges: [number, number][][] = []
  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.apiService.getMap().subscribe(
      map => {
        if (map.vertices) {
          this.nodes = map.vertices;
          this.edges = map.edges;
          //console.log("locations", this.nodes);
          this.initializeMap();
          this.drawWorldMap();
          this.setupZoom();
        }
      });
  }

  private initializeMap() {
    if (this.width && this.height) {
      this.mapWidth = this.width;
      this.mapHeight = this.height;
    }
    else{
      var mapElement = document.getElementById('world-map-location')!;
      if (mapElement) {
        this.mapWidth = mapElement.offsetWidth;
        //this.mapHeight = this.height;
      }
    }
    this.svg = d3.select(this.mapContainer.nativeElement)
      .append('svg')
      .attr('width', this.mapWidth)
      .attr('height', this.mapHeight);
    // Create separate 'g' elements for nodes/edges and country paths
    this.countryPathGroup = this.svg.append('g');
    this.nodeEdgeGroup = this.svg.append('g');

    //Disable drag behavior for the SVG container
    // this.svg.call(d3.drag().on('start', null).on('drag', null).on('end', null));



  }

  private drawWorldMap() {
    d3.json('assets/world-110m.json').then((world: any) => {
      this.projection = d3.geoMercator()
        .scale(150)
        .translate([this.mapWidth / 2, this.mapHeight / 2]);

      const path = d3.geoPath().projection(this.projection);
      // @ts-ignore
      const countries: FeatureCollection = topojson.feature(world, world.objects.countries);
      //console.log("geometry", world, countries, countries.features)

      // Draw the countries
      this.countryPathGroup.selectAll('.country-path')
        .data(countries.features)
        .enter().append('path')
        .attr('class', 'country-path')
        .attr('d', path)
        .attr('stroke', 'white')
        .attr('stroke-width', 0.2)
        .attr('fill', 'lightgray');


      const componentView = this.mapContainer.nativeElement;
      this.nodeEdgeGroup.selectAll('.node')
        .data(this.nodes)
        .enter().append('circle')
        .attr('class', 'node')
        .attr('id', (d: Vertex) => `vertex-${d.id}`)
        .attr('cx', (d: any) => this.projection(d.coordinates)[0])
        .attr('cy', (d: any) => this.projection(d.coordinates)[1])
        .attr('r', this.nodeSize)
        .style('fill', (d: Vertex) => HelperService.RBCScoreToRiskColor(d.score))
        .on('mouseover', (event: MouseEvent, d: any) => {

          this.nodeTooltip = document.getElementById('node-tooltip')!;
          var nodeName = document.getElementById('detail-node-name')!;
          var nodeImg = document.getElementById('detail-node-img')! as HTMLImageElement;
          var nodeScore = document.getElementById('detail-node-score')!;

          //var nodeSegment = document.getElementById('detail-node-segment')!;
          nodeName.innerText = d.name;
          nodeImg.src = `assets/images/location-${d.riskLevel}.svg`
          nodeScore.innerText = String(d.score.toFixed(1)) + "/10";
          if(this.isFacilityEnable) {
            var nodeFacility = document.getElementById('detail-node-facility')!;
            nodeFacility.innerText = "Facilities: " + String(5);
          }
          //nodeSegment.innerText = "Segments: " + String(10);
          this.nodeTooltip.style.display = 'initial';
          // change node size
          d3.select(componentView.querySelector(`#vertex-${d.id}`))
            .transition()
            .attr('r', this.newNodeSize * 2);
        })
        .on('mouseout', (event: MouseEvent, d: any) => {
          // disable nodeToolTip
          this.nodeTooltip.style.display = 'none';
          // change node size
          d3.select(componentView.querySelector(`#vertex-${d.id}`))
            .transition()
            .attr('r', this.newNodeSize);
        })
        .on('click', (event: MouseEvent, d: any) => {
          //console.log('any clicked:', element, this, event, d);
          const element = componentView.querySelector(`#vertex-${d.id}`);
          if (element) {
            element.style.fill = 'black';
          }
          this.router.navigate([`/dashboard/${d.id}`])
        });

      // Draw edges
      this.nodeEdgeGroup.selectAll('.edge')
        .data(this.edges)
        .enter().append('path')
        .attr('class', 'edge')
        .attr('d', (d: Edge) => {
          const source = this.projection(d[0]);
          const target = this.projection(d[1]);

          // Calculate control points for a Bézier curve
          const dx = target[0] - source[0];
          const dy = target[1] - source[1];
          const dr = Math.sqrt(dx * dx + dy * dy);

          return `M${source[0]},${source[1]}A${dr},${dr} 0 0,1 ${target[0]},${target[1]}`;
        })
        .attr('stroke-width', this.nodeSize / 16)
        .attr('stroke', (d: Edge) => {
          const index = this.findEdgeRiskLevelByCoordinates(d[0], d[1]);
          // console.log("level", riskLevel);
          return HelperService.RBCScoreToRiskColor(index!);
        })
        .attr('fill', 'none');

        this.isMapReady = true;
    });
  }
  private setupZoom() {
    this.zoom = d3.zoom()
      .scaleExtent([1, 32]) // Set the scale extent as needed
      .translateExtent([[0, 0], [this.mapWidth, this.mapHeight]]) // Set the allowed translation range
      .on('zoom', this.zoomed.bind(this));

    this.svg.call(this.zoom);
  }

  private zoomed(event: d3.D3ZoomEvent<Element, unknown>) {

    this.svg.selectAll('.node, .country-path, .edge')
      .attr('transform', event.transform);

    // Change size based on the current zoom scale
    this.newNodeSize = this.nodeSize / event.transform.k;
    //console.log("new size", this.newNodeSize);
    this.svg.selectAll('.node')
      .attr('r', this.newNodeSize);
    this.svg.selectAll('.edge')
      .attr('stroke-width', this.newNodeSize / 32);
  }

  /**
   * Calculate risk level of edge.
   *
   * @param {Coordinate} sourceCoordinates - Coordinate of source of an edge.
   * @param {Coordinate} targetCoordinates - Coordinate of target of an edge.
   * @returns {number} - Edge risk level (low, medium, high)
   */
  private findEdgeRiskLevelByCoordinates(sourceCoordinates: Coordinate, targetCoordinates: Coordinate): number | undefined {
    var source!: Vertex;
    var target!: Vertex;
    this.nodes.find(node => {
      if (source == undefined &&
        node.coordinates[0] === sourceCoordinates[0] &&
        node.coordinates[1] === sourceCoordinates[1]
      ) {
        source = node;
      }
      if (target == undefined &&
        node.coordinates[0] === targetCoordinates[0] &&
        node.coordinates[1] === targetCoordinates[1]
      ) {
        target = node;
      }
      if (target && source) {
        return;
      }
    });

    //console.log("matched", source, target);

    // get the higher riskLevel
    return (target && source) ? source.score > target.score ? source.score : target.score : undefined;
  }

  // /**
  //  * Map risk level to a color.
  //  *
  //  * @param {string} riskLevel - Risk level (low, medium, high).
  //  * @returns {string} - Color associated with risk level.
  //  */
  // private riskLevelToColor(riskLevel: string | undefined): string {
  //   // Define a set of colors
  //   const colors = ['#AFCA0B', '#F19100', '#D10C15'];
  //   if (riskLevel == "low")
  //     return colors[0];
  //   else if (riskLevel == "medium")
  //     return colors[1];
  //   else
  //     return colors[2];
  // }
}

