import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Observable } from "rxjs";
import { FacilitySortEvent, NgbdSortableHeader } from "../../directives/sortable.directive";
import { FacilityTableService } from "./facility-table.service";
import { Facility } from "../../core/models/facility";
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-facility-table',
  templateUrl: './facility-table.component.html',
  styleUrls: ['./facility-table.component.scss']
})
export class FacilityTableComponent implements OnInit {

  facilities$!: Observable<Facility[]>;
  total$: Observable<number>;

  @Input()
  selectedLocationId?: string;

  @Output() onFacilitySelect: EventEmitter<any> = new EventEmitter();

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  constructor(
    public service: FacilityTableService
  ) {
    this.facilities$ = service.facilities$;
    this.total$ = service.total$;
  }

  ngOnInit() {

  }

  onSort({ column, direction }: FacilitySortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  facilityWasClicked(event: any): void {
    this.onFacilitySelect.emit(event)
  }

  imgSourceForLevelWithRisk(risk: string) {
    return HelperService.imgSourceForLevelWithRisk('segment', risk)
  }
}
