<div class="container-fluid p-4">
    <!-- menu -->
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs border-bottom-0">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/dashboard', locationId]">Location</a>
                </li>
                <li class="nav-item" *ngIf="isFacilityEnable">
                    <a class="nav-link" [routerLink]="['/dashboard', locationId, facilityId]">Facility</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active">< Segment</a>
                </li>
            </ul>
        </div>
        <div class="col col-auto pe-0">
            <app-search></app-search>
        </div>
    </div>
    <div class="row shadow position-relative">
        <div class="col">
          <!-- segment name and reload -->
            <div class="row bg-white p-2 border-top">
                <div class="col-auto" *ngIf="segment">
                  <img  [src]="indexToImgSrc(segment.Score, 'segment')" height="40" width="40" alt="location-image"/>
                </div>
                <div class="col me-auto">
                    <div class="segment-name" *ngIf="segment">
                        <h3 class="mb-0">{{segment.Network}}</h3>
                    </div>
                </div>
                <div class="col col-auto">
                    <app-spinner (onSpinnerClick)="refreshData(true)"></app-spinner>
                </div>
                <div class="col col-auto">
                  <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end" (click)="generateReport()">Generate Report</button>
                </div>
            </div>
            <div class="row p-2 bg-white">
              <!-- segment graph -->
              <div class="col-9 position-relative">
                <div class="row">
                  <app-graph [width]="1200" [height]="600" id="node-graph"  (onNodeSelect)="selectAsset($event)"></app-graph>
                </div>
              </div>
              <!-- right side panel to the segment graph -->
              <div class="col-3 shadow bg-gray position-relative" *ngIf="segment; else segmentMeta">
                <!-- current segment information -->
                <div class="row mt-1" *ngIf="segment">
                  <div class="col-auto">
                      <img [src]="indexToImgSrc(segment.Score, 'segment')" height="64" width="64" alt="segment-image"/>
                  </div>
                  <div class="col me-auto">
                      <h4>
                          {{segment.Network}}
                      </h4>
                      <h4 *ngIf="segment.Score! > 7" class="text-danger">High Risk</h4>
                      <h4 *ngIf="segment.Score! > 3  && segment.Score! <= 7" class="text-warning">Medium Risk</h4>
                      <h4 *ngIf="segment.Score! <= 3" class="text-green">Low Risk</h4>
                  </div>
                </div>
                <!-- current segment metadata table -->
                <div class="row mt-0" *ngIf="segment">
                  <div class="col">
                      <table class="table table-borderless table-gray" style="font-size: 22px;">
                          <thead class="border-bottom">
                          <tr>
                              <th>RBC-Index</th>
                              <th>{{segment!.Score | number: '1.1-1'}}</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr class="border-bottom">
                              <td>Segment Group:</td>
                              <td>{{segment!.NetworkGroup}}</td>
                          </tr>
                          <tr class="border-bottom">
                              <td>Type:</td>
                              <td>{{segment!.NetworkType}}</td>
                          </tr>
                          <tr class="border-bottom">
                              <td>Address:</td>
                              <td>{{segment!.NetworkAddress}}</td>
                          </tr>
                          <!-- <tr class="border-bottom">
                              <td>Location Id:</td>
                              <td>{{segment!.NetworkLocationId}}</td>
                          </tr> -->
                          <tr class="border-bottom">
                              <td>Located at:</td>
                              <td>{{segment!.NetworkLocation}}</td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
                </div>
                <!-- node-tooltip -->
                <div class="row mt-0" id="node-tooltip">
                    <div class="col col-12 mb-12">
                      <div class="card">
                          <div class="card-body">
                              <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-2" id="detail-node-name">Name</a>
                              <div class="row mb-4">
                                  <div class="col col-6">
                                      <img src="" class="card-img-top" height="94" width="150" alt="device-image" id="detail-node-img" />
                                  </div>
                                  <div class="col col-6 ">
                                      <span class="fw-bold">RBC-Index2</span>
                                      <h4 class="" id="detail-node-score">Score/10</h4>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col col-auto">
                                      <p class="mb-0 small" id="detail-node-child-number">Vulnerabilities: </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
              <!-- placeholder right side panel to the location graph  -->
              <ng-template #segmentMeta>
                <div class="col-3 position-relative placeholder-glow">
                  <!-- current location img, name and risk level -->
                  <div class="row mt-3">
                      <div class="col-auto">
                        <span class="placeholder col-12" style="height: 64px; width: 64px;"></span>
                      </div>
                      <div class="col me-auto">
                          <span class="placeholder col-8" >location.Name</span>
                          <span class="placeholder col-6">High Risk</span>
                      </div>
                  </div>
                  <!-- current location metadata table -->
                  <div class="row mt-2">
                      <div class="col">
                          <table class="table table-borderless table-gray">
                              <thead class="border-bottom">
                              <tr>
                                  <th><span class="placeholder col-3"></span></th>
                                  <th><span class="placeholder col-3"></span></th>
                              </tr>
                              </thead>
                              <tbody>
                                <tr class="border-bottom">
                                    <td><span class="placeholder col-3"></span></td>
                                    <td><span class="placeholder col-3"></span></td>
                                </tr>
                                <tr class="border-bottom">
                                  <td><span class="placeholder col-3"></span></td>
                                  <td><span class="placeholder col-3"></span></td>
                                </tr>
                                <tr class="border-bottom">
                                  <td><span class="placeholder col-3"></span></td>
                                  <td><span class="placeholder col-3"></span></td>
                                </tr>
                                <tr class="border-bottom">
                                  <td><span class="placeholder col-3"></span></td>
                                  <td><span class="placeholder col-3"></span></td>
                                </tr>
                                <tr class="border-bottom">
                                  <td><span class="placeholder col-3"></span></td>
                                  <td><span class="placeholder col-3"></span></td>
                                </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              </ng-template>
            </div>
            <div class="row bg-white p-2 border-top">
              <div class="col me-auto">
                  <div class="segment-name">
                      <h3 class="mb-0">Most critical Devices to watch</h3>
                  </div>
              </div>
            </div>
            <!-- critical devices row  -->
            <div class="row p-2 bg-white">
                <!-- <div class="col-8">
                    <app-device-table [selectedSegmentId]="segmentId" (onDeviceSelect)="selectAsset($event)"></app-device-table>
                </div> -->
                <!-- show all critical devices as cards on canvas -->
                <div class="col-8 position-relative">
                  <div class="row" id="segment" *ngIf="segment">
                    <ng-container *ngFor="let device of segment.DeviceList">
                      <div class="col col-3 mb-3" *ngIf="device.Score >7">
                          <div class="card" (click)="selectAsset({nodeId: device.id})"  style="cursor: pointer;">
                              <div class="card-body">
                                  <a class="d-block w-100 fs-4 text-center border-bottom border-2 mb-3">{{device.DeviceRef}}</a>
                                  <div class="row mb-4">
                                      <div class="col col-6">
                                          <img [src]="indexToImgSrc(device.Score, 'device')" class="card-img-top" height="94" width="150" alt="device-image" />
                                          <!-- <img *ngIf="device.Score! > 7" src="assets/images/asset-high.svg" class="card-img-top" height="94" width="150" alt="device-image" />
                                          <img *ngIf="device.Score! > 3  && device.Score! <= 7" src="assets/images/asset-medium.svg" class="card-img-top" height="94" width="150" alt="device-image"/>
                                          <img *ngIf="device.Score! <= 3" src="assets/images/asset-low.svg" class="card-img-top" height="94" width="150" alt="device-image"/> -->
                                      </div>
                                      <div class="col col-6 ">
                                          <span class="fw-bold">RBC-Index</span>
                                          <h4 class="">{{device.Score | number:'1.1-1'}}/10</h4>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class="col col-auto">
                                        <!-- <p class="mb-0 small">Segments: {{facility.High+facility.Medium+facility.Low}}</p> -->
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-4 shadow bg-gray position-relative justify-content-center">
                    <!-- Show selected device on right side -->
                    <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto" *ngIf="selectedAsset">
                        <div class="col mt-3">
                            <div class="row">
                                <div class="col-auto">
                                    <img [src]="indexToImgSrc(selectedAsset.Score, 'device')" height="64" width="64" alt="asset-image"/>
                                    <!-- <img *ngIf="selectedAsset.Score! > 7" ngSrc="assets/images/asset-detail-high.svg" height="64" width="64" alt="asset-image"/>
                                    <img *ngIf="selectedAsset.Score! > 3  && selectedAsset.Score! <= 7" ngSrc="assets/images/asset-detail-medium.svg" height="64" width="64" alt="asset-image"/>
                                    <img *ngIf="selectedAsset.Score! <= 3" ngSrc="assets/images/asset-detail-low.svg" height="64" width="64" alt="asset-image"/> -->
                                </div>
                                <div class="col me-auto">
                                    <h4>
                                        {{selectedAsset.DeviceRef}}
                                    </h4>
                                    <h4 *ngIf="selectedAsset.Score! >= 7" class="text-danger">High Risk</h4>
                                    <h4 *ngIf="selectedAsset.Score! >=4  && selectedAsset.Score! < 7" class="text-warning">Medium Risk</h4>
                                    <h4 *ngIf="selectedAsset.Score! < 4" class="text-green">Low Risk</h4>
                                </div>
                                <div class="col-auto ">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="selectedAsset=undefined"></button>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="selectedAsset != undefined">
                                <table class="table table-borderless table-gray" style="font-size: 22px;">
                                    <thead class="border-bottom">
                                    <tr>
                                        <th>RBC-Index</th>
                                        <th>{{selectedAsset!.Score | number: '1.1-1'}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="border-bottom">
                                        <td>Device ID:</td>
                                        <td>{{selectedAsset!.DeviceId}}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td>CVEs</td>
                                        <td>{{selectedAsset!.Vulnerabilities?.length || 0}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row mt-2" *ngIf="recommendations.length > 0;">
                                <div class="col">
                                    <h4>Security Recommendations</h4>
                                    <div *ngFor="let recommendation of recommendations">
                                        <div class="row align-items-center">
                                            <div class="col col-auto">
                                                <img src="/assets/images/icon-{{recommendation.Name}}.svg" width="35" height="35" alt="{{recommendation.Name | translate}}"/>
                                            </div>
                                            <div class="col">
                                                <h5 class="p-0 m-0">{{recommendation.Title}}</h5>
                                                <p class="p-0 m-0 text-light-gray">{{recommendation.Description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5" >
                                <div class="col">
                                    <table class="table table-borderless table-gray" *ngIf="assetHistory != undefined; else loading" style="font-size: 22px;">
                                        <tbody class="" >
                                        <tr *ngFor="let assetParam of assetHistory!.Parameters" class="border-bottom">
                                            <td class="col-9">{{assetParam.Name | translate}}:</td>
                                            <td class="col-3">
                                              <div class="progress" role="button" (click)="rbcImage = assetParam.Name" style="height: 30px; font-size: 16px;">
                                                <div class="progress-bar progress-bar-striped" [style.background-color]="indexToColor(assetParam.Score?assetParam.Score:0)" role="progressbar" [style.width.%]="assetParam.Score?assetParam.Score*10:0"  aria-valuemin="0" aria-valuemax="100">{{assetParam.Score | number: '1.1-1'}}</div>
                                              </div>
                                              <!-- <span class="param-value text-green" role="button" (click)="rbcImage = assetParam.Name">{{assetParam.Score | number: '1.1-1'}}</span> -->
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div class="col-auto m-auto rounded bg-white shadow p-2" *ngIf="rbcImage">
                                        <img src="/assets/images/{{rbcImage}}.jpg" width="200" height="200" alt="{{rbcImage | translate}}"/>
                                    </div>
                                    <ng-template #loading>
                                        <div class="placeholder-glow" aria-hidden="true">
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Show current segment details on right side -->
                    <div>
                        <!-- <div class="row mt-3" *ngIf="segment">
                            <div class="col-auto">
                                <img [src]="indexToImgSrc(segment.Score, 'segment')" height="64" width="64" alt="segment-image"/>
                            </div>
                            <div class="col me-auto">
                                <h4>
                                    {{segment.Network}}
                                </h4>
                                <h4 *ngIf="segment.Score! > 7" class="text-danger">High Risk</h4>
                                <h4 *ngIf="segment.Score! > 3  && segment.Score! <= 7" class="text-warning">Medium Risk</h4>
                                <h4 *ngIf="segment.Score! <= 3" class="text-green">Low Risk</h4>
                            </div>
                        </div>
                        <div class="row mt-2" *ngIf="segment">
                            <div class="col">
                                <table class="table table-borderless table-gray">
                                    <thead class="border-bottom">
                                    <tr>
                                        <th>RBC-Index</th>
                                        <th>{{segment!.Score | number: '1.1-1'}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="border-bottom">
                                        <td>Network Group:</td>
                                        <td>{{segment!.NetworkGroup}}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td>Type:</td>
                                        <td>{{segment!.NetworkType}}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td>Address:</td>
                                        <td>{{segment!.NetworkAddress}}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td>Location Id:</td>
                                        <td>{{segment!.NetworkLocationId}}</td>
                                    </tr>
                                    <tr class="border-bottom">
                                        <td>Located at:</td>
                                        <td>{{segment!.NetworkLocation}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                        <div class="row mt-3">
                            <div class="col">
                                <h4 class="text-gray">SEGMENT RISK-BY-CONTEXT</h4>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <app-doughnut-chart></app-doughnut-chart>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                              <!-- <canvas id="deviceHistoryInSegmentChart"></canvas> -->
                              <app-risk-level-history-chart></app-risk-level-history-chart>
                            </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col">
                              <!-- <canvas id="segmentScoreHistoryChart"></canvas> -->
                              <app-index-history-chart></app-index-history-chart>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div id="asset-detail" class="bg-white p-0 rounded" >
        <div class="col" *ngIf="selectedAsset != undefined">
            <div class="rounded-top p-2 text-white"
                 [ngClass]="selectedAsset!.Score! > 7 ? 'asset-critical' : selectedAsset!.Score! <= 3 ? 'asset-low' : 'asset-medium'"
            >
                <h4 class="m-0 d-inline">Device Details:</h4>
                <h4 class="float-end" role="button" (click)="unzoomCanvas()">X</h4>
            </div>
            <div class="p-2">
                <table class="table table-borderless">
                    <thead class="border-bottom">
                        <tr>
                            <td>RBC-Index</td>
                            <td>{{selectedAsset!.Score | number: '1.1-1'}}</td>
                        </tr>
                    </thead>
                    <tbody class="border-bottom">
                        <tr>
                            <td>Device ID:</td>
                            <td>{{selectedAsset!.DeviceId}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td>CVEs</td>
                        <td>{{selectedAsset!.Vulnerabilities?.length || 0}}</td>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div class="p-2 float-end">
                <a class="btn btn-secondary" [routerLink]="['/segment', segment.id, 'asset', selectedAsset!.id]">Show Asset</a>
                <button type="button" class="btn btn-secondary ms-2" (click)="unzoomCanvas()">Back</button>

            </div>
        </div>
    </div> -->
    <!-- Risk-By-Context tabs -->
    <div class="row mt-5">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab"  [destroyOnHide]="true" class="nav-tabs">
          <li [ngbNavItem]="1">
              <a ngbNavLink>Device Risk-By-Context</a>
              <ng-template ngbNavContent>
                  <app-device-table [selectedSegmentId]="segmentId" (onDeviceSelect)="selectAsset($event)"></app-device-table>
              </ng-template>
          </li>
          <li [ngbNavItem]="2" *ngIf="selectedAsset != undefined">
              <a ngbNavLink>Vulnerability Risk-By-Context</a>
              <ng-template ngbNavContent>
                  <app-vulnerability-table></app-vulnerability-table>
              </ng-template>
          </li>
      </ul>
    </div>
    <div class="row shadow position-relative">
        <div [ngbNavOutlet]="nav" class="pt-2 bg-white border-top"></div>
    </div>


</div>

