import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Observable } from "rxjs";
import { Device } from "../../core/models/device";
import { AssetSortEvent, NgbdSortableHeader } from "../../directives/sortable.directive";
import { DeviceTableService } from "./device-table.service";
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-device-table',
  templateUrl: './device-table.component.html',
  styleUrls: ['./device-table.component.scss']
})
export class DeviceTableComponent implements OnInit {

  // used in table placeholder
  public numberArray: number[] = Array.from({ length: 25 }, (_, index) => index + 1);
  assets$!: Observable<Device[]>;
  total$: Observable<number>;

  @Input()
  selectedSegmentId?: string;

  @Output() onDeviceSelect: EventEmitter<any> = new EventEmitter();

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;

  constructor(
    public service: DeviceTableService
  ) {
    this.assets$ = service.assets$;
    this.total$ = service.total$;
  }


  ngOnInit() {

  }

  onSort({ column, direction }: AssetSortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  assetWasClicked(event: any): void {
    this.onDeviceSelect.emit(event)
  }

  indexToColor(score: number) {
    return HelperService.RBCScoreToRiskColor(score);
  }

  imgSourceForLevelWithRisk(risk: string) {
    return HelperService.imgSourceForLevelWithRisk('vulnerability', risk)
  }
}
