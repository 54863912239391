<!-- doughnut placeholder -->
<div class="row justify-content-center" *ngIf="!isDoughnutChartReady">
  <div class="col-7 placeholder-glow">
    <!-- placeholder global locations doughnut chart-->
      <div class="placeholder col-12 mb-2" style="height: 309px;">
      </div>
  </div>
  <div class="col-5 placeholder-glow" style="display: flex; align-items: center;" aria-hidden="true">
     <!-- placeholder global locations doughnut chart legend-->
     <div class="row">
      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
      <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
     </div>
  </div>
</div>

<!-- doughnut chart -->
<div class="row">
  <div class="col-7">
    <!-- doughnut chart -->
    <canvas id="doughnutDashboardChart" [class.d-none]="!isDoughnutChartReady" #doughnutCanvas></canvas>
    <!-- <p>{{isGlobalDoughnutChartReady}}</p> -->
  </div>
   <!-- doughnut chart legend -->
  <div class="col-5" id="donut-dashboard-legend" style="display: flex; align-items: center;" [class.d-none]="!isDoughnutChartReady">
  </div>
</div>

