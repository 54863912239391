import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Observable } from "rxjs";
import { Segment } from "../../core/models/segment";
import { NgbdSortableHeader, SegmentSortEvent } from "../../directives/sortable.directive";
import { SegmentTableService } from "./segment-table.service";
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-segment-table',
  templateUrl: './segment-table.component.html',
  styleUrls: ['./segment-table.component.scss']
})
export class SegmentTableComponent implements OnInit {

  // used in table placeholder
  public numberArray: number[] = Array.from({ length: 25 }, (_, index) => index + 1);
  segments$!: Observable<Segment[]>;
  total$: Observable<number>;

  @Output() onSegmentSelect: EventEmitter<any> = new EventEmitter();

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  constructor(
    public service: SegmentTableService
  ) {
    this.segments$ = service.segments$;
    this.total$ = service.total$;
  }

  ngOnInit() {

  }

  onSort({ column, direction }: SegmentSortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  segmentWasClicked(event: any) {
    this.onSegmentSelect.emit(event)
  }

  indexToColor(score: number) {
    return HelperService.RBCScoreToRiskColor(score);
  }

  imgSourceForLevelWithRisk(risk: string) {
    return HelperService.imgSourceForLevelWithRisk('device', risk)
  }
}
