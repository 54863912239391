<div class="row">
    <div class="col">
        <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Filter:</label>
                <div class="col-auto me-auto">
                    <input
                            id="table-complete-search"
                            type="text"
                            class="form-control"
                            name="searchTerm"
                            [(ngModel)]="service.searchTerm"
                    />
                </div>
                <div class="col-auto align-self-end">
                    <ngb-pagination
                            [collectionSize]="(total$ | async)!"
                            [(page)]="service.page"
                            [pageSize]="service.pageSize"
                            [maxSize]="10"
                            [rotate]="true"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
            </div>
            <!-- device table -->
            <table class="table table-striped table-hover" *ngIf="(assets$|async)?.length; else loadingTable">
                <thead>
                <tr>
                    <th scope="col" sortable="Score" (sort)="onSort($event)">RBC-Index</th>
                    <th scope="col" sortable="DeviceId" (sort)="onSort($event)">Device ID</th>
                    <th scope="col">Device Ref</th>
    <!--                <th scope="col">Network Address</th>-->
    <!--                <th scope="col">Located At</th>-->
                    <th scope="col" sortable="High" (sort)="onSort($event)">
                        <img [ngSrc]="imgSourceForLevelWithRisk('high')" alt="vulnerability-high"
                             height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Medium" (sort)="onSort($event)">
                        <img [ngSrc]="imgSourceForLevelWithRisk('medium')" alt="vulnerability-medium"
                                         height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Low" (sort)="onSort($event)">
                        <img [ngSrc]="imgSourceForLevelWithRisk('low')" alt="vulnerability-low"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let asset of assets$| async; index as i">

                    <td>{{ asset.Score | number: '1.1-1' }}/10</td>
    <!--                <td>{{ asset.id }}</td>-->
                    <td *ngIf="service.selectedSegmentId; else no_link">
                      <div  class="text-green asset-detail"  [attr.data-asset] = asset.DeviceId>
                          <ngb-highlight role="button" [ngStyle]="{'color': indexToColor(asset.Score)}" (click)="assetWasClicked($event)" [result]=" asset.DeviceId" [term]="service.searchTerm" [attr.data-asset] = asset.id></ngb-highlight>
                      </div>
                    </td>
                    <ng-template #no_link>
                      <td><ngb-highlight [result]="asset.DeviceId" [term]="service.searchTerm"></ngb-highlight></td>
                    </ng-template>
                    <td><ngb-highlight [result]="asset.DeviceRef" [term]="service.searchTerm"></ngb-highlight></td>
    <!--                <td><ngb-highlight [result]=" asset.address" [term]="filter.value"></ngb-highlight></td>-->
    <!--                <td><ngb-highlight [result]=" asset.location" [term]="filter.value"></ngb-highlight></td>-->
                    <td>{{ asset.High | number }}</td>
                    <td>{{ asset.Medium | number }}</td>
                    <td>{{ asset.Low | number }}</td>
                    <td>{{ asset.Low! + asset.Medium! + asset.High!}} </td>
                </tr>
                </tbody>
            </table>
            <!-- placeholder for location table -->
            <ng-template #loadingTable>
              <table class="table table-striped" >
                <thead>
                <tr>
                    <th scope="col" >RBC-Index</th>
                    <th scope="col" >Location</th>
                    <th scope="col" >
                        <img [ngSrc]="imgSourceForLevelWithRisk('high')" alt="vulnerability-high" height="24" width="24"/>
                    </th>
                    <th scope="col" >
                        <img [ngSrc]="imgSourceForLevelWithRisk('medium')" alt="vulnerability-medium"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">
                        <img [ngSrc]="imgSourceForLevelWithRisk('low')" alt="vulnerability-low"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let i of numberArray" class="placeholder-glow" [class.no-hover]=true aria-hidden="true">
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-3"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                  </tr>
                </tbody>
              </table>
              </ng-template>
        </form>
    </div>
</div>
