import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Chart, registerables } from "chart.js";
import Konva from "konva";
import { Subscription } from "rxjs";
import { DeviceParam } from "../../core/models/device-param";
import { Facility } from "../../core/models/facility";
import { Recommendation } from "../../core/models/recommendation";
import { Segment } from "../../core/models/segment";
import { SegmentHistory } from "../../core/models/segment-history";
import { SnapshotData } from "../../core/models/snapshot-data";
import { ApiService } from "../../services/api.service";
import { HelperService } from "../../services/helper.service";
import { LoadingService } from "../../services/loading.service";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit, OnDestroy {


  @ViewChild('lowNetworksTab') lowNetworksTab!: ElementRef
  @ViewChild('mediumNetworksTab') mediumNetworksTab!: ElementRef
  @ViewChild('highNetworksTab') highNetworksTab!: ElementRef
  @ViewChild('allNetworksTab') allNetworksTab!: ElementRef

  activeTab = 1;

  recommendations: Recommendation[] = []

  private _segmentHistory?: SegmentHistory;
  get segmentHistory(): SegmentHistory | undefined {
    return this._segmentHistory;
  }

  set segmentHistory(value: SegmentHistory | undefined) {
    this._segmentHistory = value;
    this.recommendations = []
    if (value != undefined) {
      for (let param in value.Parameters) {
        if (value.Parameters[param].Score! > 7) {
          this.apiService.getSegmentSecurityRecommendationByName(value.Parameters[param].Name)
            .subscribe((response) => {
              this.recommendations.push(response)
            })
        }
      }
    }
  }

  private _facility!: Facility
  get facility(): Facility {
    return this._facility;
  }

  set facility(value: Facility) {
    if (this._facility !== value) {
      this._facility = value;
      this.segmentRanking = { 'critical': 0, 'medium': 0, 'low': 0 }
      for (let segment in value.SegmentList) {
        if (value.SegmentList[segment].Score! > 7) {
          this.segmentRanking.critical++
        } else if (value.SegmentList[segment].Score! > 3) {
          this.segmentRanking.medium++
        } else {
          this.segmentRanking.low++
        }
      }
      this.loadImages()
    }
  }

  locationId!: string;
  facilityId!: string;
  rbcImage?: string;

  private graph1!: Chart<any>
  private graph2!: Chart<any>
  private stage!: Konva.Stage;
  private circles: { low: Array<Konva.Node>, medium: Array<Konva.Node>, critical: Array<Konva.Node> } = { critical: [], medium: [], low: [] }
  private segmentRanking = { 'critical': 0, 'medium': 0, 'low': 0 }
  private snapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }

  private facilitySubscription!: Subscription;
  private historySubscription!: Subscription;

  private _zoomValue = 1;
  set zoomValue(value: number) {
    this._zoomValue = value;
    this.stage.scale({ x: value, y: value })
  }
  get zoomValue(): number {
    return this._zoomValue;
  }

  private _selectedSegment?: Segment

  set selectedSegment(value: Segment | undefined) {
    this._selectedSegment = value;
    this.rbcImage = undefined;
    this.apiService.changeSegment(value);
    if (value != undefined) {
      this.activeTab = 2
      this.historySubscription = this.apiService.getLastSegmentHistoryBySegmentId(value.id).subscribe(
        response => {
          response.Parameters.sort(function (a: DeviceParam, b: DeviceParam) {
            if (a.Name < b.Name) { return -1; }
            if (a.Name > b.Name) { return 1; }
            return 0;
          })
          this.segmentHistory = response
        }
      )
    } else {
      this.activeTab = 1
    }

  }
  get selectedSegment(): Segment | undefined {
    return this._selectedSegment;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.facilitySubscription = this.apiService.currentFacility.subscribe(
      response => {
        this.locationId = this.route.snapshot.paramMap.get('locationId')!;
        this.facilityId = this.route.snapshot.paramMap.get('facilityId')!;
        if (response != undefined && response.id != undefined && response.id == this.facilityId) {
          this.facility = response
          var graph = HelperService.formateGraphData(response, "Facility");
          this.apiService.changeGraph(graph);
          this.updateSnapshotData(HelperService.getDateString(7), HelperService.getDateString())
        } else {
          this.refreshData();
        }
      }
    )
  }


  ngOnDestroy() {
    this.graph1?.destroy()
    this.graph2?.destroy()
    this.facilitySubscription.unsubscribe()
    this.historySubscription?.unsubscribe()
  }

  refreshData(manual = false) {
    this.loadingService.addRequest();
    this.apiService.getFacilityById(this.facilityId)
      .subscribe(response => {
        this.apiService.changeFacility(response.Data);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        }
        this.loadingService.removeRequest();
      });
  }

  indexToImgSrc(score: number, type: string) {
    return HelperService.RBCIndexToImageSrc(score, type);
  }

  imgSourceForLevelWithRisk (level: string, risk: string) {
    return HelperService.imgSourceForLevelWithRisk(level, risk)
  }

  updateSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getFacilitySnapshotByFacilityIdAndDate(this.facilityId, fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          for (const snap in response.reverse()) {
            this.snapshotData.dates.push(response[snap]['Day'])
            this.snapshotData.low.push(response[snap]['Low'])
            this.snapshotData.medium.push(response[snap]['Medium'])
            this.snapshotData.high.push(response[snap]['High'])
            this.snapshotData.score.push(response[snap]['Score'])
          }
          this.drawHistoryGraph();
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
  }

  loadImages(): void {
    const sources: Record<number, string> = {
      0: '/assets/images/segment-circle-low.svg',
      1: '/assets/images/segment-circle-medium.svg',
      2: '/assets/images/segment-circle-high.svg'
    };
    let images: Record<string, HTMLImageElement> = {};
    let loadedImages = 0;
    let numImages = 0;
    const returnImages: Array<Record<string, HTMLImageElement>> = []

    let sizes = [{ w: 50, h: 50 }, { w: 75, h: 75 }, { w: 100, h: 100 }]

    for (const src in sources) {
      for (let size in sizes) {
        numImages++;
      }
    }
    for (const src in sources) {
      for (let size in sizes) {
        images[size] = new Image(sizes[size].w, sizes[size].h);
        images[size].onload = () => {
          if (++loadedImages >= numImages) {
            this.drawSubLocationCanvas(returnImages)
          }
        };
        images[size].src = sources[src];

      }
      returnImages[src] = images
      images = {}
    }
  }

  private drawSubLocationCanvas(images: Array<Record<string, HTMLImageElement>>) {
    // const canvas = document.getElementById('facility-canvas');
    // if (this.facility.SegmentList.length > 0) {
    //   if (canvas !== null) {
        // this.stage = new Konva.Stage({
        //   width: canvas!.offsetWidth,
        //   height: canvas!.offsetHeight,
        //   container: 'facility-canvas',
        //   draggable: true,
        //   scale: { x: this.zoomValue, y: this.zoomValue }
        // });

        // const mainLayer = new Konva.Layer();
        // this.stage.add(mainLayer);
        // mainLayer.draw();

        //let circlePos = { x: 0, y: 0, rowHeight: 0, offsetY: 0, width: 0 }
        // for (let segment in this.facility.SegmentList) {
        //   let segmentObj = this.facility.SegmentList[segment];
        //   //let imgSize = segmentObj.Devices!.length > 50 ? 2 : segmentObj.Devices!.length > 30 ? 1 : 0;
        //   let imgSize = 2

        //   let imgRanking = segmentObj.Score > 7 ? 2 : segmentObj.Score > 3 ? 1 : 0;
        //   let tempImg = images[imgRanking][imgSize];

        //   circlePos = this.calculatePosition(circlePos, tempImg)
        //   const circle = new Konva.Image({
        //     x: circlePos.x,
        //     y: circlePos.y,
        //     image: tempImg,
        //     id: segmentObj.id,
        //   });

        //   circle.on('click', () => {
        //     this.apiService.getSegmentById(circle.id())
        //       .subscribe((response) => {
        //         this.selectedSegment = response;
        //       })
        //   })

        //   switch (imgRanking) {
        //     case 0:
        //       this.circles.low.push(circle);
        //       break;
        //     case 1:
        //       this.circles.medium.push(circle);
        //       break;
        //     case 2:
        //       this.circles.critical.push(circle);
        //       break;
        //   }

          //mainLayer.add(circle)
       // }

        // var menuNode = document.getElementById('menu')!;
        // var segmentName = document.getElementById('detail-segment-name')!;
        // var locationName = document.getElementById('detail-location-name')!;
        // var rbcIndex = document.getElementById('detail-rbc-index')!;
        // var assetCount = document.getElementById('detail-segment-assets')!;

        // this.stage.on('mouseover', (e) => {
        //   // prevent default behavior
        //   //e.evt.preventDefault();
        //   if (e.target === this.stage) {
        //     // if we are on empty place of the stage we will do nothing
        //     return;
        //   }

        //   // ignore if clicked not on shape
        //   const clickOnShape = e.target instanceof Konva.Image;
        //   if (!clickOnShape) {
        //     return;
        //   }
        //   this.stage.container().style.cursor = 'pointer';

        //   let selectedSegment = this.facility.SegmentList.filter(obj => {
        //     return obj.id === e.target.id();
        //   })[0]

        //   locationName.innerText = selectedSegment.NetworkLocation
        //   segmentName.innerText = selectedSegment.Network
        //   //assetCount.innerText = String(selectedSegment.Devices.length)
        //   assetCount.innerText = "?"
        //   rbcIndex.innerText = selectedSegment.Score.toLocaleString('en-us', { maximumFractionDigits: 1 })

        //   // show menu
        //   menuNode.style.display = 'initial';
        //   menuNode.style.left =
        //     (e.target.getClientRect().x + e.target.getClientRect().width) + 'px';
        //   menuNode.style.top =
        //     (e.target.getClientRect().y + e.target.getClientRect().height) + 'px';
        // });

        // this.stage.on('mouseout', (e) => {
        //   if (e.target === this.stage) {
        //     // if we are on empty place of the stage we will do nothing
        //     return;
        //   }
        //   this.stage.container().style.cursor = 'default';
        //   menuNode.style.display = 'none';
        // });
      // }
      this.drawDashboardGraphs()
    //}
  }

  drawDashboardGraphs() {
    this.graph1 != undefined ? this.graph1.destroy() : '';

    const ctx = <HTMLCanvasElement>document.getElementById('doughnutFacilityChart')!;

    this.graph1 = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['High', 'Medium', 'Low'],
        datasets: [{
          data: [this.segmentRanking.critical, this.segmentRanking.medium, this.segmentRanking.low],
          borderWidth: 1,
          backgroundColor: [
            '#D10C15',
            '#F19100',
            '#AFCA0B'
          ],
          hoverOffset: 4
        }]
      },
      plugins: [{
        id: "doughnutSubLocationChart",
        beforeInit: function (chart, args, options) {
          const datasets = chart.data.datasets!;
          if (chart.canvas.id === "doughnutFacilityChart") {
            const ul = document.createElement('ul');
            chart?.data?.labels?.forEach((label, index) => {
              const labelString = label as string;
              ul.innerHTML += `
              <li>
                <img src="assets/images/segment-circle-${labelString.toLowerCase()}.svg" style="width:30px; height:30px"></img>
                ${label}: ${datasets[0].data[index]}
              </li>
            `;
            });
            return document!.getElementById("donut-facility-legend")!.innerHTML = ul.outerHTML;
          }

          return;
        }
      }],
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }

    });
  }

  private drawHistoryGraph() {
    this.graph2 != undefined ? this.graph2.destroy() : '';
    const ctx2 = <HTMLCanvasElement>document.getElementById('historyFacilityChart')!;
    this.graph2 = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: this.snapshotData.dates,
        datasets: [
          {
            label: ' Critical',
            data: this.snapshotData.high,
            fill: true,
            backgroundColor: [
              '#D10C15',
            ],
          },
          {
            label: 'Medium',
            data: this.snapshotData.medium,
            fill: true,
            backgroundColor: [
              '#F19100',
            ],
          },
          {
            label: 'Low',
            data: this.snapshotData.low,
            fill: true,
            backgroundColor: [
              '#AFCA0B'
            ],
          },
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: true,
          },
          tooltip: {
            mode: 'index'
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        scales: {
          x: {
            title: {
              display: false,
            }
          },
          y: {
            stacked: true,
            title: {
              display: false,
            }
          }
        }
      }
    });
  }

  private calculatePosition(polygonPos: { x: number; y: number; rowHeight: number; offsetY: number, width: number }, image: HTMLImageElement) {
    if (polygonPos.x + image.width + 100 >= this.stage.width()) {
      polygonPos.offsetY = polygonPos.rowHeight;
      polygonPos.x = HelperService.getRandomArbitrary(10, 75);
      polygonPos.y = HelperService.getRandomArbitrary(20, 75) + polygonPos.offsetY;
    } else {
      polygonPos.x += polygonPos.width + HelperService.getRandomArbitrary(10, 50);
      polygonPos.y = HelperService.getRandomArbitrary(20, 75) + polygonPos.offsetY;
    }

    if (image.height + polygonPos.y > polygonPos.rowHeight) {
      polygonPos.rowHeight = image.height + polygonPos.y
    }

    polygonPos.width = image.width
    return polygonPos
  }

  selectSegment($event: any) {
    var segmentId: any
    if ($event.target)
      segmentId = $event.target.getAttribute('data-segment')
    else
      segmentId = $event.segmentId
    this.apiService.getSegmentById(segmentId)
      .subscribe((response) => {
        this.selectedSegment = response
      })
  }

  selectDevice($event: any) {
    this.router.navigate(['/dashboard/', this.locationId, this.facilityId, this.selectedSegment!.id, $event.target.getAttribute('data-asset')])
  }

  showAllNetworks() {
    this.allNetworksTab.nativeElement.classList.add('active')
    this.highNetworksTab.nativeElement.classList.remove('active')
    this.mediumNetworksTab.nativeElement.classList.remove('active')
    this.lowNetworksTab.nativeElement.classList.remove('active')

    this.showCircles(this.circles.low)
    this.showCircles(this.circles.medium)
    this.showCircles(this.circles.critical)
  }

  showLowRiskNetworks() {
    this.allNetworksTab.nativeElement.classList.remove('active')
    this.highNetworksTab.nativeElement.classList.remove('active')
    this.mediumNetworksTab.nativeElement.classList.remove('active')
    this.lowNetworksTab.nativeElement.classList.add('active');

    this.hideCircles(this.circles.medium)
    this.hideCircles(this.circles.critical)
    this.showCircles(this.circles.low)
  }
  showMediumRiskNetworks() {
    this.allNetworksTab.nativeElement.classList.remove('active')
    this.highNetworksTab.nativeElement.classList.remove('active')
    this.mediumNetworksTab.nativeElement.classList.add('active')
    this.lowNetworksTab.nativeElement.classList.remove('active')

    this.hideCircles(this.circles.low)
    this.hideCircles(this.circles.critical)
    this.showCircles(this.circles.medium)
  }
  showHighRiskNetworks() {
    this.allNetworksTab.nativeElement.classList.remove('active')
    this.highNetworksTab.nativeElement.classList.add('active')
    this.mediumNetworksTab.nativeElement.classList.remove('active')
    this.lowNetworksTab.nativeElement.classList.remove('active')

    this.hideCircles(this.circles.low)
    this.hideCircles(this.circles.medium)
    this.showCircles(this.circles.critical)
  }

  hideCircles(circles: Konva.Node[]) {
    for (let circle in circles) {
      circles[circle].hide()
    }
  }
  showCircles(circles: Konva.Node[]) {
    for (let circle in circles) {
      circles[circle].show()
    }
  }
}
