import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { ToastService } from './toast.service';
import { LoadingService } from './loading.service';

@Injectable()

export class GlobalErrorHandler implements ErrorHandler {
  //private opened = false;
  //private modalService!: NgbModal;
  constructor(private injector: Injector, private zone: NgZone, private router: Router,
    private toastService: ToastService,
    private loadingService: LoadingService) {

  }
  handleError(error: Error) {
    //this.modalService = this.injector.get(NgbModal);
    if (error.message) {
      // this.zone.run(() =>
      //   this.openDialog(
      //     error.message || 'Undefined client error'
      //     // "Undefined client error"
      //   )
      // );
      this.loadingService.removeRequest();
      this.toastService.showSuccess('An error occurred!!');
      console.error("in error handler:", error.message);
    }
  }

  // openDialog(message: string, status?: number): void {
  //   if (!this.opened) {
  //     this.opened = true;
  //     // const dialogRef = this.dialog.open(ErrorDialogComponent, {
  //     //   data: { message, status },
  //     //   maxHeight: '100%',
  //     //   width: '540px',
  //     //   maxWidth: '100%',
  //     //   disableClose: true,
  //     //   hasBackdrop: true,
  //     // });

  //     const modelRef = this.modalService.open(ErrorDialogComponent);
  //     modelRef.componentInstance.data = {message, status};

  //   }
  // }
}
