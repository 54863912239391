import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthGuard } from './guards/auth.guard';
import { DashboardOldComponent } from "./pages/dashboard-old/dashboard-old.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { DeviceComponent } from "./pages/device/device.component";
import { FacilityComponent } from "./pages/facility/facility.component";
import { LocationComponent } from "./pages/location/location.component";
import { DemoComponent } from "./pages/demo/demo.component";
import { LoginComponent } from "./pages/login/login.component";
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SegmentComponent } from "./pages/segment/segment.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { WidgetDashboardComponent } from './pages/widget-dashboard/widget-dashboard.component';
import { ReportComponent } from './pages/report/report.component';
import { InterfaceComponent } from './pages/interface/interface.component';
import { AddInterfaceComponent } from './components/add-interface/add-interface.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'location',
    component: LocationComponent,
  },
  {
    path: 'demo',
    component: DemoComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    //component: WidgetDashboardComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'dashboard/:locationId',
    component: LocationComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'dashboard/:locationId/:facilityId',
    component: FacilityComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'dashboard/:locationId/:facilityId/:segmentId',
    component: SegmentComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'dashboard/:locationId/:facilityId/:segmentId/:assetId',
    component: SegmentComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'dashboard-old',
    component: DashboardOldComponent,
  },
  {
    path: 'dashboard-old/segment/:segmentId/asset/:assetId',
    component: DeviceComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: 'reports',
    component: ReportComponent
  },
  {
    path: 'interfaces',
    component: InterfaceComponent
  },
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
