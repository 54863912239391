<div class="">
  <div class="step" [class.active]="currentStep === 1">
    <div class="step-header" (click)="setStep(1)">
      <div class="step-number" *ngIf="currentStep === 1">1</div>
      <div class="step-number check-symbol" *ngIf="currentStep === 2"></div>
      <div class="step-title">Interface Details</div>
    </div>
    <div class="step-body" *ngIf="currentStep === 1">
      <form [formGroup]="accessTokenFormGroup">
        <div class="form-group">
          <label for="serverAddress">Server Address</label>
          <input
            id="serverAddress"
            type="text"
            class="form-control m-1"
            formControlName="serverAddress"
            placeholder="https://my-server.com"
            required
          />
        </div>
        <div
          *ngIf="
            serverAddress!.invalid &&
            (serverAddress!.dirty || serverAddress!.touched)
          "
          class="text-danger"
        >
          <div *ngIf="serverAddress!.errors!['required']">
            server address is required.
          </div>
          <div *ngIf="serverAddress!.errors!['pattern']">
            enter valid https address.
          </div>
        </div>
        <div class="form-group">
          <label for="accessToken">Access Token</label>
          <input
            id="accessToken"
            type="text"
            class="form-control m-1"
            formControlName="accessToken"
            placeholder="enter your access token"
            required
          />
        </div>
        <div
          *ngIf="
            accessToken!.invalid && (accessToken!.dirty || accessToken!.touched)
          "
          class="text-danger"
        >
          <div *ngIf="accessToken!.errors!['required']">
            access token is required.
          </div>
        </div>
        <div>
          <span class="text-green" *ngIf="interfaceTested"
            >Test successful</span
          >
          <app-spinner
            *ngIf="!interfaceTested && testingInterface"
          ></app-spinner>
        </div>
        <button
          type="button"
          class="btn btn-green m-2"
          (click)="testInterface()"
          [disabled]="accessTokenFormGroup.invalid"
        >
          Test
        </button>
        <button
          type="button"
          class="btn btn-green m-2"
          (click)="addInterface()"
          [disabled]="accessTokenFormGroup.invalid"
        >
          Add
        </button>
      </form>
    </div>
  </div>
  <div class="step mt-2" [class.active]="currentStep === 2">
    <div class="step-header">
      <div class="step-number" *ngIf="!interfaceAdded">2</div>
      <div class="step-number check-symbol" *ngIf="interfaceAdded"></div>
      <div class="step-title">Status</div>
    </div>
    <div class="step-body" *ngIf="currentStep === 2">
      <span class="text-green" *ngIf="interfaceAdded"
        >Interface Added successfully</span
      >
      <app-spinner *ngIf="!interfaceAdded && addingInterface"></app-spinner>
      <!-- <form [formGroup]="secondFormGroup">
    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input
        id="lastName"
        type="text"
        class="form-control"
        formControlName="lastName"
        required
      />
    </div>
    <button
      type="button"
      class="btn btn-green m-2"
      (click)="previousStep()"
    >
      Back
    </button>
    <button
      type="button"
      class="btn btn-green m-2"
      (click)="nextStep()"
    >
      Add
    </button>
  </form> -->
    </div>
  </div>
</div>
