<div class="row">
    <div class="col">
        <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Filter:</label>
                <div class="col-auto me-auto">
                    <input
                            id="table-complete-search"
                            type="text"
                            class="form-control"
                            name="searchTerm"
                            [(ngModel)]="service.searchTerm"
                    />
                </div>
                <div class="col-auto align-self-end">
                    <ngb-pagination
                            [collectionSize]="(total$ | async)!"
                            [(page)]="service.page"
                            [pageSize]="service.pageSize"
                            [maxSize]="10"
                            [rotate]="true"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
            </div>
            <!-- segment table -->
            <table class="table table-striped table-hover" *ngIf="(segments$|async)?.length; else loadingTable">
                <thead>
                <tr>
                    <th scope="col" sortable="Score" (sort)="onSort($event)">RBC-Index</th>
                    <th scope="col" sortable="Network" (sort)="onSort($event)">Segment</th>
                    <th scope="col" sortable="NetworkGroup" (sort)="onSort($event)">Segment Group</th>
                    <th scope="col" sortable="NetworkLocation" (sort)="onSort($event)">Located At</th>
                    <th scope="col" sortable="High" (sort)="onSort($event)">
                        <img [src]="imgSourceForLevelWithRisk('high')" alt="device-high"
                             height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Medium" (sort)="onSort($event)">
                        <img [src]="imgSourceForLevelWithRisk('medium')" alt="device-medium"
                                         height="24" width="24"/>
                    </th>
                    <th scope="col" sortable="Low" (sort)="onSort($event)">
                        <img [src]="imgSourceForLevelWithRisk('low')" alt="device-low"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let segment of segments$|async; index as i">
                    <td>{{ segment.Score | number: '1.1-1' }}/10</td>
                    <td>
                        <div  class="text-green asset-detail">
                            <ngb-highlight role="button" [ngStyle]="{'color': indexToColor(segment.Score)}" (click)="segmentWasClicked($event)" [result]=" segment.Network" [term]="service.searchTerm" [attr.data-segment] = segment.id></ngb-highlight>
                        </div>
                    </td>
                    <td><ngb-highlight [result]=" segment.NetworkGroup" [term]="service.searchTerm"></ngb-highlight></td>
                    <td><ngb-highlight [result]=" segment.NetworkLocation" [term]="service.searchTerm"></ngb-highlight></td>
                    <td>{{ segment.High | number }}</td>
                    <td>{{ segment.Medium | number }}</td>
                    <td>{{ segment.Low | number }}</td>
                    <td>{{ segment.Low! + segment.Medium! + segment.High!}} </td>
                </tr>
                </tbody>
            </table>
            <!-- placeholder for location table -->
            <ng-template #loadingTable>
              <table class="table table-striped" >
                <thead>
                <tr>
                    <th scope="col" >RBC-Index</th>
                    <th scope="col" >Location</th>
                    <th scope="col" >
                        <img [ngSrc]="imgSourceForLevelWithRisk('high')" alt="vulnerability-high" height="24" width="24"/>
                    </th>
                    <th scope="col" >
                        <img [ngSrc]="imgSourceForLevelWithRisk('medium')" alt="vulnerability-medium"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">
                        <img [ngSrc]="imgSourceForLevelWithRisk('low')" alt="vulnerability-low"
                             height="24" width="24"/>
                    </th>
                    <th scope="col">Total</th>
                </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let i of numberArray" class="placeholder-glow" [class.no-hover]=true aria-hidden="true">
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-3"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                    <td> <span class="placeholder col-2"></span></td>
                  </tr>
                </tbody>
              </table>
              </ng-template>
        </form>
    </div>
</div>
