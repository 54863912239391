<div class="container-fluid p-4">
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs border-bottom-0">
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link" [routerLink]="['/dashboard']">Dashboard</a>
                </li>
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link" [routerLink]="['/dashboard', locationId]">Location</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" href="#">< Facility</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">Segment</a>
                </li>
            </ul>
        </div>
        <div class="col col-auto pe-0">
            <app-search></app-search>
        </div>
    </div>
    <div class="row shadow position-relative">
        <div class="col" *ngIf="facility">
            <div class="row bg-white p-2 border-top">
                <div class="col-auto">
                  <img  [src]="indexToImgSrc(facility.Score, 'facility')" height="40" width="40" alt="location-image"/>
                </div>
                <div class="col me-auto">
                    <div class="location-name">
                        <h3 class="mb-0">{{facility.Name}}</h3>
                    </div>
                </div>
                <div class="col col-auto">
                    <app-spinner (onSpinnerClick)="refreshData(true)"></app-spinner>
                </div>
            </div>
            <div class="row p-2 bg-white">
              <div class="col-9 position-relative">
                <div class="row">
                  <app-graph [width]="1200" [height]="600" id="facility-graph"></app-graph>
                </div>
              </div>
              <div class="col-3 shadow bg-gray position-relative">
                <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto">
                    <div class="col col-12 mb-12">
                      <div class="card">
                          <div class="card-body"  id="node-tooltip">
                              <a class="d-block w-100 fs-3 text-center border-bottom border-2 mb-3" id="detail-node-name">Name</a>
                              <div class="row mb-4">
                                  <div class="col col-6">
                                      <img src="assets/images/facility-high.svg" class="card-img-top" height="94" width="150" alt="facility-image" id="detail-node-img" />
                                  </div>
                                  <div class="col col-6 ">
                                      <span class="fw-bold">RBC-Index</span>
                                      <h4 class="" id="detail-node-score">Score/10</h4>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col col-auto">
                                      <p class="mb-0 small" id="detail-node-child-number">Devices: </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="row bg-white p-2 border-top">
              <div class="col me-auto">
                  <div class="location-name">
                      <h3 class="mb-0">Most critical Segments to watch</h3>
                  </div>
              </div>
            </div>
            <div class="row p-2 bg-white">
                <div class="col-8 position-relative">
                    <!-- <div id="facility-canvas"></div>
                    <div id="menu" class="rounded bg-white shadow p-2">
                        <div>
                            <div id="detail-location-name" class="fw-bold">Location Name</div>
                            <div id="detail-segment-name">Segment Name</div>
                            <div>DEVICES: <span id="detail-segment-assets">Facility Count</span></div>
                            <div>RBC-INDEX: <span id="detail-rbc-index">RBX Index</span></div>
                        </div>
                    </div> -->
                    <div class="row" id="facility-canvas" *ngIf="facility">
                      <ng-container *ngFor="let segment of facility.SegmentList">
                        <div class="col col-3 mb-3" *ngIf="segment.Score >7">
                            <div class="card" (click)="selectSegment({segmentId: segment.id})"  style="cursor: pointer;">
                                <div class="card-body">
                                    <a class="d-block w-100 fs-4 text-center border-bottom border-2 mb-3">{{segment.Network}}</a>
                                    <div class="row mb-4">
                                        <div class="col col-6">
                                            <img [src]="indexToImgSrc(facility.Score, 'facility')" class="card-img-top" height="94" width="150" alt="facility-image" />
                                            <!-- <img *ngIf="facility.Score! > 7" src="assets/images/segment-circle-high.svg" class="card-img-top" height="94" width="150" alt="facility-image" />
                                            <img *ngIf="facility.Score! > 3  && facility.Score! <= 7" src="assets/images/segment-circle-medium.svg" class="card-img-top" height="94" width="150" alt="facility-image"/>
                                            <img *ngIf="facility.Score! <= 3" src="assets/images/segment-circle-low.svg" class="card-img-top" height="94" width="150" alt="facility-image"/> -->
                                        </div>
                                        <div class="col col-6 ">
                                            <span class="fw-bold">RBC-Index</span>
                                            <h4 class="">{{segment.Score | number:'1.1-1'}}/10</h4>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col col-auto">
                                          <!-- <p class="mb-0 small">Segments: {{facility.High+facility.Medium+facility.Low}}</p> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </ng-container>
                    </div>
                </div>
                <div class="col-4 shadow bg-gray position-relative">
                    <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto" *ngIf="selectedSegment">
                        <div class="col mt-3">
                            <div class="row">
                                <div class="col-auto">
                                    <img [ngSrc]="indexToImgSrc(selectedSegment.Score, 'segment')" height="64" width="64" alt="asset-image"/>
                                    <!-- <img *ngIf="selectedSegment.Score! > 7" ngSrc="assets/images/segment-circle-high.svg" height="64" width="64" alt="asset-image"/>
                                    <img *ngIf="selectedSegment.Score! > 3  && selectedSegment.Score! <= 7" ngSrc="assets/images/segment-circle-medium.svg" height="64" width="64" alt="asset-image"/>
                                    <img *ngIf="selectedSegment.Score! <= 3" ngSrc="assets/images/segment-circle-low.svg" height="64" width="64" alt="asset-image"/> -->
                                </div>
                                <div class="col me-auto">
                                    <h4>
                                        {{selectedSegment.Network}}
                                    </h4>
                                    <h4 *ngIf="selectedSegment.Score! >= 7" class="text-danger">High Risk</h4>
                                    <h4 *ngIf="selectedSegment.Score! >= 4  && selectedSegment.Score! < 7" class="text-warning">Medium Risk</h4>
                                    <h4 *ngIf="selectedSegment.Score! < 4" class="text-green">Low Risk</h4>
                                </div>
                                <div class="col-auto ">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="selectedSegment=undefined"></button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <table class="table table-borderless table-gray">
                                        <thead class="border-bottom">
                                        <tr>
                                            <th>RBC-Index</th>
                                            <th>{{selectedSegment!.Score | number: '1.1-1'}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="border-bottom">
                                            <td>Network Group:</td>
                                            <td>{{selectedSegment!.NetworkGroup}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Type:</td>
                                            <td>{{selectedSegment!.NetworkType}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Address:</td>
                                            <td>{{selectedSegment!.NetworkAddress}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Location Id:</td>
                                            <td>{{selectedSegment!.NetworkLocationId}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Located at:</td>
                                            <td>{{selectedSegment!.NetworkLocation}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-2" *ngIf="recommendations.length > 0;">
                                <div class="col">
                                    <h4>Security Recommendations</h4>
                                    <div *ngFor="let recommendation of recommendations">
                                        <div class="row align-items-center">
                                            <div class="col col-auto">
                                                <img src="/assets/images/icon-{{recommendation.Name}}.svg" width="35" height="35" alt="{{recommendation.Name | translate}}"/>
                                            </div>
                                            <div class="col">
                                                <h5 class="p-0 m-0">{{recommendation.Title}}</h5>
                                                <p class="p-0 m-0 text-light-gray">{{recommendation.Description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-5" >
                                <div class="col">
                                    <table class="table table-borderless table-gray" *ngIf="segmentHistory != undefined; else history_loading">
                                        <tbody >
                                        <tr *ngFor="let assetParam of segmentHistory!.Parameters" class="border-bottom">
                                            <td>{{assetParam.Name | translate}}:</td>
                                            <td>
                                                <!-- <span class="param-value">{{assetParam.Score | number: '1.1-1'}}</span> -->
                                                <span class="param-value text-green" role="button" (click)="rbcImage = assetParam.Name">{{assetParam.Score | number: '1.1-1'}}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div class="col-auto m-auto rounded bg-white shadow p-2" *ngIf="rbcImage && rbcImage == 'NETWORK_SEGMENT_DIMENSION'" >
                                    </div>
                                    <div class="col-auto m-auto rounded bg-white shadow p-2" *ngIf="rbcImage && rbcImage != 'NETWORK_SEGMENT_DIMENSION'">
                                      <img src="/assets/images/{{rbcImage}}.jpg" width="200" height="200" alt="{{rbcImage | translate}}"/>
                                    </div>

                                    <ng-template #history_loading>
                                        <div class="placeholder-glow" aria-hidden="true">
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                            <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-auto">
                                    <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end mt-3" [routerLink]="['/dashboard/', locationId, facilityId, selectedSegment!.id]">Show Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h4 class="text-gray">FACILITY RISK-BY-CONTEXT</h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-7">
                            <canvas id="doughnutFacilityChart"></canvas>
                        </div>
                        <div class="col-5" id="donut-facility-legend" style="display: flex; align-items: center;">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <canvas id="historyFacilityChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="facility">
        <div class="col col-auto ps-0">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item" (click)="showAllNetworks()">
                    <a id="all-networks-tab" class="nav-link active" #allNetworksTab>Show All</a>
                </li>
            </ul>
        </div>
        <div class="col col-auto">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item" (click)="showHighRiskNetworks()">
                    <a id="filter-high-tab" class="nav-link" #highNetworksTab>
                        <img [ngSrc]="imgSourceForLevelWithRisk('segment', 'high')" alt="risk-high"
                             height="24" width="24"/>
                    </a>
                </li>
                <li class="nav-item" (click)="showMediumRiskNetworks()">
                    <a id="filter-medium-tab" class="nav-link" #mediumNetworksTab>
                        <img [ngSrc]="imgSourceForLevelWithRisk('segment', 'low')" alt="risk-medium"
                             height="24" width="24"/>
                    </a>
                </li>
                <li class="nav-item" (click)="showLowRiskNetworks()">
                    <a id="filter-low-tab" class="nav-link" #lowNetworksTab>
                        <img [ngSrc]="imgSourceForLevelWithRisk('segment', 'low')" alt="risk-low"
                             height="24" width="24"/>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col col-auto offset-1">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item">
                    <a class="nav-link pt-1 pb-0" style="height: 42px;">
                        <input class="form-range" type="range" min="0.3" max="3" step="0.01" [(ngModel)]="zoomValue">
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-5">
        <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="true" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Segment Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-segment-table (onSegmentSelect)="selectSegment($event)"></app-segment-table>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="selectedSegment != undefined">
                <a ngbNavLink>Device Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-device-table (onDeviceSelect)="selectDevice($event)"> </app-device-table>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="row shadow position-relative">
        <div [ngbNavOutlet]="nav" class="pt-2 bg-white border-top"></div>
    </div>


</div>
