<link rel="stylesheet" href="settings.component.scss">
<div class="container-fluid p-4">
    <div class="row">
        <div class="col ps-0">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="true" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Device Settings</a>
                    <ng-template ngbNavContent>
                        <app-device-param-settings></app-device-param-settings>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Segment Settings</a>
                  <ng-template ngbNavContent>
                      <app-segment-param-settings></app-segment-param-settings>
                  </ng-template>
              </li>
            </ul>
        </div>
    </div>

    <div class="row shadow position-relative">
        <div [ngbNavOutlet]="nav" class="pt-2 bg-white border-top"></div>
    </div>
</div>
