import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, delay, of } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-integration-password',
  templateUrl: './integration-password.component.html',
  styleUrls: ['./integration-password.component.scss']
})
export class IntegrationPasswordComponent {
  @Output() integrationPasswordStatus = new EventEmitter<boolean>();
  currentStep: number = 1;
  testingInterface = false;
  interfaceTested = false;
  addingInterface = false;
  interfaceAdded = false;
  usernameFormGroup: FormGroup;
  currentIntegrationMethod = "";
  // public activeModal: NgbActiveModal,
  constructor(private _formBuilder: FormBuilder, private loadingService: LoadingService) {
    this.usernameFormGroup = this._formBuilder.group({
      serverAddressUsername: ['', [Validators.required, Validators.pattern('^https://\\S*$')]],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get serverAddressUsername() {
    return this.usernameFormGroup.get('serverAddressUsername');
  }

  get username() {
    return this.usernameFormGroup.get('username');
  }

  get password() {
    return this.usernameFormGroup.get('password');
  }

  setStep(step: number) {
    this.currentStep = step;
  }

  nextStep() {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
    this.interfaceTested = false;
  }

  async addInterface() {
    if (this.currentStep < 2) {
      this.currentStep++;
    }
    this.interfaceTested = false;
    this.loadingService.addRequest();
    this.addingInterface = true
    await new Promise(r => setTimeout(r, 2000));
    this.interfaceAdded = true;
    this.integrationPasswordStatus.emit(this.interfaceAdded);
    this.loadingService.removeRequest();
    this.addingInterface = false;

  }

  async testInterface() {
    this.loadingService.addRequest();
    this.testingInterface = true
    await new Promise(r => setTimeout(r, 2000));
    this.interfaceTested = true;
    this.loadingService.removeRequest();
    this.testingInterface = false;
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  resetSteps() {
    this.currentStep = 1;
  }
}
