import { ChangeDetectorRef, Component, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Chart, registerables } from 'chart.js';
import { Subscription } from 'rxjs';
import { DoughnutChartComponent } from 'src/app/components/doughnut-chart/doughnut-chart.component';
import { LocationTableComponent } from 'src/app/components/location-table/location-table.component';
import { PdfViewerComponent } from 'src/app/components/pdf-viewer/pdf-viewer.component';
import { RiskLevelHistoryChartComponent } from 'src/app/components/risk-level-history-chart/risk-level-history-chart.component';
import { WorldMapComponent } from 'src/app/components/world-map/world-map.component';
import { Location } from 'src/app/core/models/location';
import { RiskLevel } from 'src/app/core/models/risk-level';
import { SnapshotData } from 'src/app/core/models/snapshot-data';
import { ApiService } from 'src/app/services/api.service';
import { HelperService } from 'src/app/services/helper.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PDFGeneratorService } from 'src/app/services/pdfgenerator.service';
import { ToastService } from 'src/app/services/toast.service';
import { CounterComponent } from 'src/app/widgets/counter/counter.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-widget-dashboard',
  templateUrl: './widget-dashboard.component.html',
  styleUrls: ['./widget-dashboard.component.scss']
})
export class WidgetDashboardComponent {
  isFacilityEnable: boolean = environment.FACILITY_ENABLE;
  tileText = 'Add Widget';
  isHovered = false;
  //widgets: string[] = ['device-count', 'device-history', 'segment-history', 'location-donut-chart'];
  gridCells: number[] = Array.from({ length: 3 }, (_, i) => i + 1);
  movingCell: number = 0;
  isEditingDashboard = false;

  @ViewChildren('widgetContainers', { read: ViewContainerRef }) widgetContainers!: QueryList<ViewContainerRef>;

  // @ViewChildren('widgetContainers', { read: ViewContainerRef })
  // set _widgetContainers(value: QueryList<ViewContainerRef>) {
  //   this.widgetContainers = value;
  //   if (value) {
  //     // Widget containers are initialized here
  //     // You can safely access widgetContainers in this setter method
  //     this.loadWidgets();
  //   }
  // }
  onHover() {
    //this.tileText = 'Hovered!';
    this.isHovered = true;
    //document.querySelector('.box')!.classList.add('bg-info');
  }

  onHoverOut() {
    this.tileText = 'Add widget';
    this.isHovered = false;
    //document.querySelector('.box')!.classList.remove('bg-info');
  }

  addWidget(type: string, cell: number) {
    this.gridContent[cell] = type;
    this.widgetContainers.get(cell - 1)!.clear();
    this.loadWidget(cell);
    // if (type == "loc-dnt-crt") {
    //   this.apiService.changeRiskLevelDoughnut(this.locationRanking)
    //   const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(DoughnutChartComponent);
    //   // componentRef.instance.width = type;
    //   // componentRef.instance.data = 23
    // }
    // else if (type == "gbl-loc-map") {
    //   const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(WorldMapComponent);
    //   componentRef.instance.width = 300;
    //   componentRef.instance.height = 300;
    // }
    // else {
    //   const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(CounterComponent);
    //   componentRef.instance.type = type;
    //   componentRef.instance.data = this.locationRanking.high
    // }


  }


  gridContent: { [key: number]: string } = {};
  draggedWidget: string | null = null; // Track the currently dragged widget

  activeTab = 1;
  //isGlobalDoughnutChartReady = false;
  // used in table placeholder
  public numberArray: number[] = Array.from({ length: 2 }, (_, index) => index + 1);

  private locationSubscription!: Subscription;
  private facilitySubscription!: Subscription;
  private locationsSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' };
  // private graph1!: Chart<any>;
  // private locationHistoryDashboardChart!: Chart<any>;
  // private locationSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
  public locationRanking: RiskLevel = { 'high': 0, 'medium': 0, 'low': 0, type: '' }

  private _locations: Location[] = [];
  get locations(): Location[] {
    return this._locations;
  }

  set locations(value: Location[]) {
    if (value !== this._locations) {
      this._locations = value;
      this.updateAllLocationsSnapshotData(HelperService.getDateString(7), HelperService.getDateString());
      //this.locationRanking = { 'high': 0, 'medium': 0, 'low': 0, type: '' };
      for (let location in value) {
        var riskLevel = HelperService.RBCIndexToRiskLevel(value[location].Score!);
        if (riskLevel == "high") {
          this.locationRanking.high++
        } else if (riskLevel == "medium") {
          this.locationRanking.medium++
        } else {
          this.locationRanking.low++
        }
      }
      this.locationRanking.type = 'location'
      // update doughnut chart
      this.apiService.changeRiskLevelDoughnut(this.locationRanking)
      // load all dashboard widgets

      //this.apiService.changeCounter({ type: 'cr-loc-ct', data: this.locationRanking.low })
    }
    // delay it until the AfterViewInit so that VieChild variables get initialized
    setTimeout(() => {
      this.loadWidgets()
    }, 10);

  }

  private _selectedLocation?: Location
  get selectedLocation(): Location | undefined {
    return this._selectedLocation;
  }

  set selectedLocation(value: Location | undefined) {
    this._selectedLocation = value;
    this.apiService.changeLocation(value);
    if (value != undefined) {
      this.activeTab = 2
    } else {
      this.activeTab = 1
    }
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private pdfGeneratorService: PDFGeneratorService,
    private sanitizer: DomSanitizer
  ) {
    Chart.register(...registerables);
    var gridContent = JSON.parse(this.localStorageService.get('dashboard')!);
    if (gridContent) {
      this.gridContent = gridContent;
    }

  }

  pdfDataUri = "";
  safeUrl!: SafeResourceUrl;
  ngOnInit(): void {
    this.locationSubscription = this.apiService.currentLocations.subscribe(
      response => {
        if (response.length > 0) {

          this.locations = response;
          //var graph = HelperService.formateGraphData(response, "Locations");
          var map = HelperService.formateWorldData(response);
          //this.apiService.changeGraph(graph);
          this.apiService.changeMap(map);
        } else {
          this.refreshData()
        }
      }
    )
  }

  ngAfterViewInit() {
    // viewchild variables get initialized in this hook
    //this.loadWidgets()
  }

  loadWidgets() {
    console.log("in widgets")
    for (const cell in this.gridContent) { //cell is type of string in for..in loop
      if (this.gridContent.hasOwnProperty(cell)) {
        this.loadWidget(+cell);
      }
    }
  }

  loadWidget(cell: number) {
    const widgetType = this.gridContent[cell];
    if (widgetType == "loc-dnt-crt") {
      this.apiService.changeRiskLevelDoughnut(this.locationRanking)
      const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(DoughnutChartComponent);
      // componentRef.instance.width = type;
      // componentRef.instance.data = 23
    }
    else if (widgetType == "gbl-loc-map") {
      const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(WorldMapComponent);
      //componentRef.instance.width = 300;
      componentRef.instance.height = 300;
    }
    else if (widgetType == "gbl-loc-tbl") {
      const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(LocationTableComponent);
      //componentRef.instance.width = 300;
      //componentRef.instance.height = 300;
    }
    else if (widgetType == "gbl-loc-rl-crt") {
      // update the stacked chart
      this.apiService.changeRiskLevelSnapshot(this.locationsSnapshotData)
      const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(RiskLevelHistoryChartComponent);
      //componentRef.instance.width = 300;
      //componentRef.instance.height = 300;
    }
    else {
      //this.apiService.changeCounter({ type: widgetType, data: this.locationRanking.high })
      const componentRef = this.widgetContainers!.get(cell - 1)!.createComponent(CounterComponent);
      componentRef.instance.type = widgetType;
      componentRef.instance.data = this.locationRanking.high
    }
    this.cdr.detectChanges()
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
    if (!this.isFacilityEnable && this.facilitySubscription)
      this.facilitySubscription.unsubscribe();
  }

  refreshData(manual = false) {
    this.loadingService.addRequest()
    this.apiService.getAllLocations()
      .subscribe(response => {
        this.apiService.changeLocations(response.Data);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        }
        this.loadingService.removeRequest()
      });
  }

  /**
   * Get Snapshot data for the selected location id with given from and to date.
   * The snapshot data contains number of low, mid and high facilities in the location and its
   * RBC-Index.
   * @param fromDateString - From Date
   * @param toDateString - To Date
   */
  updateSelectedLocationSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getLocationSnapshotByLocationIdAndDate(this.selectedLocation!.id, fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          var locationSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
          for (const snap in response.reverse()) {
            locationSnapshotData.dates.push(response[snap]['Day'].substring(5))
            if (this.isFacilityEnable) {
              locationSnapshotData.low.push(response[snap]['Low'])
              locationSnapshotData.medium.push(response[snap]['Medium'])
              locationSnapshotData.high.push(response[snap]['High'])
            }
            locationSnapshotData.score.push(response[snap]['Score'])
          }
          // update the RBC-Index line chart
          this.apiService.changeIndexSnapshot(locationSnapshotData)
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
  }

  /**
   * Get Snapshot data for all locations from and to date. The snapshot data contains
   * number of low, mid and high locations.
   * @param fromDateString - From date
   * @param toDateString - To date
   */
  updateAllLocationsSnapshotData(fromDateString: string, toDateString: string): void {
    this.loadingService.addRequest()
    this.apiService
      .getAllLocationSnapshotByDate(fromDateString, toDateString)
      .subscribe({
        next: (response) => {
          //var locationSnapshotData: SnapshotData = { high: [], dates: [], low: [], medium: [], score: [], type: '' }
          for (const snap in response) {
            this.locationsSnapshotData.dates.push(response[snap]['Day'].substring(5))
            this.locationsSnapshotData.low.push(response[snap]['Low'])
            this.locationsSnapshotData.medium.push(response[snap]['Medium'])
            this.locationsSnapshotData.high.push(response[snap]['High'])
          }
          this.locationsSnapshotData.type = "Locations"
          // update the stacked chart
          this.apiService.changeRiskLevelSnapshot(this.locationsSnapshotData)
          this.loadingService.removeRequest()
        },
        error: (e) => this.loadingService.removeRequest()
      });
  }

  indexToImgSrc(score: number, type: string) {
    return HelperService.RBCIndexToImageSrc(score, type);
  }

  selectLocation($event: any) {
    var locationId: any
    if ($event.target)
      locationId = $event.target.getAttribute('data-location')
    else
      locationId = $event.locationId
    this.apiService.getLocationById(locationId)
      .subscribe((response) => {
        this.selectedLocation = response.Data;
        this.updateSelectedLocationSnapshotData(HelperService.getDateString(7), HelperService.getDateString())
        // get 1st facility of the location if facility level is disabled
        if (!this.isFacilityEnable) {
          this.facilitySubscription = this.apiService.getFacilityById(this.selectedLocation!.FacilityList[0].id).subscribe(resFacility => {
            this.apiService.changeFacility(resFacility.Data);
          })
        }
      })
  }
  selectFacility($event: any) {
    this.router.navigate(['/dashboard/', this.selectedLocation!.id, $event.target.getAttribute('data-facility')])
  }

  // used when Facility level is disabled
  selectSegment($event: any) {
    this.router.navigate(['/dashboard/', this.selectedLocation!.id, this.selectedLocation!.FacilityList[0].id, $event.target.getAttribute('data-segment')])
  }

  generateReport(): void {
    // this.pdfGeneratorService.pdfGenerated$.subscribe(pdfDataUri => {
    //   console.log("hello", pdfDataUri);
    //   this.pdfDataUri = pdfDataUri;
    //   // Sanitize the PDF data URI
    //   this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfDataUri);
    //   const modalRef = this.modalService.open(PdfViewerComponent);
    //   modalRef.componentInstance.safeUrl = this.safeUrl;

    // });

    const modalRef = this.modalService.open(PdfViewerComponent);
    modalRef.componentInstance.reportUrl = '/assets/reports/RiskByContext_Report_Global.pdf';
    //this.pdfGeneratorService.generateReport(this.locationsSnapshotData);
  }

  /**
   * Sums up segments in a location
   * @param location - Location
   * @returns {number} - Number of segments in a given location
   */
  segmentsInLocation(location: any): number {
    var segments = 0;
    if (location.FacilityList) {
      location.FacilityList.forEach((facility: any) => {
        if (facility.Segments)
          segments = segments + facility.Segments.length;
      })
    }
    return segments;
  }

  /**
   * It is called when drag event is started. It sets the source cell number.
   * @param event - Drag event
   * @param cell - Cell number
   */
  onDragStart(event: DragEvent, cell: number) {
    //const target = event.target as HTMLElement;
    //console.log("dragstrat", target.dataset)
    //this.draggedWidget = gridContent;
    //console.log("widget", this.draggedWidget);
    //event.dataTransfer!.setData('text/plain', this.draggedWidget);
    this.movingCell = cell
  }

  onDrop(event: DragEvent, cell: number) {
    event.preventDefault();
    // const widgetType = event.dataTransfer!.getData('text/plain');
    // console.log("type", widgetType);
    if (cell == this.movingCell) {
      return;
    }
    const existingWidgetType = this.gridContent[cell];
    this.gridContent[cell] = this.gridContent[this.movingCell];
    delete this.gridContent[this.movingCell]
    this.widgetContainers.get(this.movingCell - 1)!.clear();
    this.widgetContainers.get(cell - 1)!.clear();
    this.loadWidget(cell);
    if (existingWidgetType) {
      this.gridContent[this.movingCell] = existingWidgetType;
      this.loadWidget(this.movingCell);
    }


    // if (widgetType == "loc-dnt-crt") {
    //   this.apiService.changeRiskLevelDoughnut(this.locationRanking)
    //   const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(DoughnutChartComponent);
    //   // componentRef.instance.width = type;
    //   // componentRef.instance.data = 23
    // }
    // else if (widgetType == "gbl-loc-map") {
    //   const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(WorldMapComponent);
    //   // componentRef.instance.width = type;
    //   // componentRef.instance.data = 23
    // }
    // else {
    //   const componentRef = this.widgetContainers.get(cell - 1)!.createComponent(CounterComponent);
    //   componentRef.instance.type = widgetType;
    //   componentRef.instance.data = this.locationRanking.high
    // }

  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  removeWidgetFromCell(cell: number) {
    this.widgetContainers.get(cell - 1)!.clear()
    delete this.gridContent[cell];
  }

  editDashboard() {
    this.isEditingDashboard = true;
  }

  saveDashboard() {
    this.isEditingDashboard = false;
    this.localStorageService.set('dashboard', JSON.stringify(this.gridContent))
  }

}
