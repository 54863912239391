import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { environment } from 'src/environments/environment';
import { LoggedInUser } from "../core/models/user";
import { ApiService } from "./api.service";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private currentUserBehaviorSubject = new BehaviorSubject({} as any);
  private currentUser: Observable<LoggedInUser> = this.currentUserBehaviorSubject.asObservable();
  private username = "";
  private password = "";

  private authUser = "1"

  private users = [
    {
      'id': "",
      "Username": "",
      "Email": "",
      "Password": ""
    }
  ]

  private authUserKey = 'authUser';
  private authTokenKey = 'authToken';
  private refreshTokenKey = 'refreshToken';

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private cookieService: CookieService,
    private router: Router
  ) { }

  private getCurrentUser1(): string | null {
    return this.localStorageService.get(this.authUserKey)
  }

  public getRequestToken(): string | null {
    let authToken = this.localStorageService.get(this.authTokenKey);
    return authToken!;
  }

  public refreshToken(callback: CallableFunction) {
    this.requestToken().subscribe((response) => {
      this.localStorageService.set(this.authTokenKey, response.Token)
      callback();
    })
  }

  private requestToken(): Observable<any> {
    let user = this.users.filter(obj => {
      return obj.id === this.getCurrentUser1();
    })[0];
    if (user == undefined) {
      this.changeUser(this.authUser)
      user = this.users[0]
    }
    const loginParam = {
      username: user.Username,
      password: user.Password
    };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');
    return this.http.post(environment.API_BASE_URL + '/Token', JSON.stringify(loginParam), { 'headers': headers });
  }

  public changeUser(id: string) {
    this.localStorageService.set(this.authUserKey, id);
    this.logout();
    this.loginOld();
  }

  public logout() {
    this.localStorageService.remove(this.authTokenKey);
    this.changeCurrentUser({} as LoggedInUser);
    this.router.navigateByUrl('/login');
  }

  public isLoggedIn(): boolean {
    return !!this.getRequestToken();
  }

  public loginOld() {
    if (this.isLoggedIn()) {
      //console.log("logged in");
      //this.apiService.getAllSegments().subscribe(result => {
      //  this.apiService.changeSegments(result);
      //});
    }
    else {
      //console.log("not logged in");
      this.requestToken().pipe(
        switchMap(response => {
          this.localStorageService.set(this.authTokenKey, response.Token);
          return this.apiService.getAllSegments();
        })
      ).subscribe(segments => {
        //this.apiService.changeSegments(segments);
      });
    }
  }

  public login(username: string, password: string) {
    const loginParam = {
      username: username,
      password: password
    };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');
    return this.http.post(environment.API_BASE_URL + '/Token', JSON.stringify(loginParam), { 'headers': headers });
  }

  public isLoggedInNew() {
    // Check if a cookie exists
    const sessionExists = this.cookieService.check(environment.SESSION_COOKIE_NAME);
    if (!sessionExists) {
      window.location.href = `${environment.BEEHIVE_BASE_URL}?redirectTo=${environment.RBC_BASE_URL}`;
    }
    console.log('Cookie Exists:', sessionExists);
    this.apiService.isLoggedInNew().subscribe(session => {
      console.log("sessionstatus", session);
      if (session.status == "authenticated") {
        console.log("logged-in");
      }
      else {
        console.log("not logged-in");
        window.location.href = `${environment.BEEHIVE_BASE_URL}?redirectTo=${environment.RBC_BASE_URL}`;
      }
    })
  }


  public getCurrentUser() {
    return this.currentUser;
  }

  public changeCurrentUser(user: LoggedInUser) {
    this.currentUserBehaviorSubject.next(user);
  }

}
