import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';


import { DecimalPipe } from '@angular/common';
import { switchMap, tap } from 'rxjs/operators';
import { Device } from "../../core/models/device";
import { Segment } from "../../core/models/segment";
import { Vulnerability } from "../../core/models/vulnerability";
import { AssetSortColumn, SortColumn, SortDirection } from "../../directives/sortable.directive";
import { ApiService } from "../../services/api.service";

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

interface AssetSearchResult {
  assets: Device[];
  total: number;
}

const compare = (v1: number | Array<Vulnerability> | string | Array<string>, v2: number | Array<Vulnerability> | string | Array<string>) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);



@Injectable({ providedIn: 'root' })
export class DeviceTableService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _assets$ = new BehaviorSubject<Device[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  DEVICES: Device[] = []
  SEGMENTS: Segment[] = []

  selectedSegmentId?: string;
  selectedSegments?: Segment[];

  // private segmentSubscription!: Subscription;

  private _state: State = {
    page: 1,
    pageSize: 20,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
  };

  constructor(
    private apiService: ApiService,
    private pipe: DecimalPipe
  ) {

    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        switchMap(() => this._search()),
        tap(() => this._loading$.next(false)),
      )
      .subscribe((result) => {
        this._assets$.next(result.assets);
        this._total$.next(result.total);
      });

    this.apiService.currentSegment.subscribe(
      response => {
        if (response != undefined && response.id != undefined) {
          this.selectedSegmentId = response.id;
          this.DEVICES.length = 0;
          this.DEVICES.push(...response.DeviceList)
          this._search$.next();
        }
      }
    )
  }

  get assets$() {
    return this._assets$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<AssetSearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let devices = this.sort(this.DEVICES, sortColumn, sortDirection);

    // 2. filter
    devices = devices.filter((asset) => this.matches(asset, searchTerm, this.pipe));
    const total = devices.length;

    // 3. paginate
    devices = devices.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ assets: devices, total });
  }

  sort(devices: Device[], column: AssetSortColumn, direction: string): Device[] {
    if (direction === '' || column === '') {
      return [...devices].sort((a, b) => {
        const res = compare(a['Score']!, b['Score']!);
        return -res;
      });
    } else {
      return [...devices].sort((a, b) => {
        const res = compare(a[column]!, b[column]!);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  matches(device: Device, term: string, pipe: PipeTransform) {
    return (
      device.DeviceId.toLowerCase().includes(term.toLowerCase()) ||
      device.DeviceRef.toLowerCase().includes(term.toLowerCase())
    );
  }
}
