import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Device } from "../core/models/device";
import { DeviceParam } from "../core/models/device-param";
import { Facility } from "../core/models/facility";
import { Graph } from "../core/models/graph";
import { Location } from "../core/models/location";
import { Map } from "../core/models/map";
import { Segment } from "../core/models/segment";
import { SegmentParam } from "../core/models/segment-param";
import { SnapshotData } from "../core/models/snapshot-data";
import { RiskLevel } from "../core/models/risk-level";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private locationsBehaviorSubject = new BehaviorSubject({} as any);
  currentLocations: Observable<[Location]> = this.locationsBehaviorSubject.asObservable();

  private segmentsBehaviorSubject = new BehaviorSubject({} as any);
  currentSegments: Observable<[Segment]> = this.segmentsBehaviorSubject.asObservable();

  private locationBehaviorSubject = new BehaviorSubject({} as any);
  currentLocation: Observable<Location> = this.locationBehaviorSubject.asObservable();

  private facilityBehaviorSubject = new BehaviorSubject({} as any);
  currentFacility: Observable<Facility> = this.facilityBehaviorSubject.asObservable();

  private segmentBehaviorSubject = new BehaviorSubject({} as any);
  currentSegment: Observable<Segment> = this.segmentBehaviorSubject.asObservable();

  private deviceBehaviorSubject = new BehaviorSubject({} as any);
  currentDevice: Observable<Device> = this.deviceBehaviorSubject.asObservable();

  private graphBehaviorSubject = new BehaviorSubject([] as any);
  private currentGraph: Observable<any> = this.graphBehaviorSubject.asObservable();

  private mapBehaviorSubject = new BehaviorSubject([] as any);
  private currentMap: Observable<any> = this.mapBehaviorSubject.asObservable();

  private indexSnapshotBehaviorSubject = new BehaviorSubject({} as any);
  private currentIndexSnapshot: Observable<any> = this.indexSnapshotBehaviorSubject.asObservable();

  private riskLevelSnapshotBehaviorSubject = new BehaviorSubject({} as any);
  private currentRiskLevelSnapshot: Observable<any> = this.riskLevelSnapshotBehaviorSubject.asObservable();

  private riskLevelDoughnutBehaviorSubject = new BehaviorSubject({} as any);
  private currentRiskLevelDoughnut: Observable<any> = this.riskLevelDoughnutBehaviorSubject.asObservable();

  private counterSubject = new BehaviorSubject({} as any);
  private currentCounter: Observable<any> = this.counterSubject.asObservable();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private put(url: string, params: string, auth: boolean = true) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');
    return this.http.put(url, params, { 'headers': headers });
  }

  private post(url: string, params: string, auth: boolean = true) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain');

    return this.http.post(url, params, { 'headers': headers });
  }

  private get(url: string, auth: boolean = true): Observable<any> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('accept', 'text/plain')

    return this.http.get(url, { 'headers': headers });
  }

  public getAllLocations(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Location/user');
  }
  public getLocationById(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Location/' + id);
  }
  public getFacilityById(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Facility/' + id);
  }
  public getAllSegments(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Segment/user');
  }
  public getSegmentById(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Segment/' + id);
  }
  public getDeviceById(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Device/' + id);
  }
  public getAllAssets(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/Device');
  }

  public getAllSegmentParams(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/SegmentParam/user');
  }
  public getAllSegmentParamNames(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/SegmentParam/names');
  }
  public createSegmentParam(segmentParam: SegmentParam): Observable<any> {
    return this.post(environment.API_BASE_URL + '/SegmentParam', JSON.stringify(segmentParam));
  }
  public saveSegmentParam(segmentParams: SegmentParam[]): Observable<any> {
    const paramS = "{\"Data\":" + JSON.stringify(segmentParams, ['id', 'Threshold', 'Relevance', 'Name', 'Active']) + "}"
    return this.put(
      environment.API_BASE_URL + '/SegmentParam/updateMultiple',
      paramS.toString()
    )
  }
  public getAllAssetParams(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/DeviceParam/user');
  }
  public getAllAssetParamNames(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/DeviceParam/names');
  }
  public createAssetParam(deviceParam: DeviceParam): Observable<any> {
    return this.post(environment.API_BASE_URL + '/DeviceParam', JSON.stringify(deviceParam));
  }
  public saveAssetParam(deviceParams: DeviceParam[]): Observable<any> {
    const paramS = "{\"Data\":" + JSON.stringify(deviceParams, ['id', 'Threshold', 'Relevance', 'Name', 'Active']) + "}"
    return this.put(
      environment.API_BASE_URL + '/DeviceParam/updateMultiple',
      paramS.toString()
    )
  }
  public getSegmentHistory(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/connection/user/all')
  }
  public getSegmentHistoryById(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/connection/' + id)
  }
  public getLastSegmentHistoryBySegmentId(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/connection/last/' + id)
  }
  public getAssetHistory(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/device/user/all')
  }
  public getAssetHistoryById(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/device/' + id)
  }
  public getLastAssetHistoryByAssetId(id: string): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/device/last/' + id)
  }
  public createHistory(): Observable<any> {
    return this.get(environment.API_BASE_URL + '/History/connection/create')
  }
  public getSegmentSnapshotBySegmentIdAndDate(id: string, startDate: string, endDate: string): Observable<any> {
    return this.post(
      environment.API_BASE_URL + '/Snapshot/segment/' + id + '/user/dates',
      JSON.stringify({ "StartDay": startDate, "EndDay": endDate })
    )
  }
  public getFacilitySnapshotByFacilityIdAndDate(id: string, startDate: string, endDate: string): Observable<any> {
    return this.post(
      environment.API_BASE_URL + '/Snapshot/facility/' + id + '/user/dates',
      JSON.stringify({ "StartDay": startDate, "EndDay": endDate })
    )
  }
  public getLocationSnapshotByLocationIdAndDate(id: string, startDate: string, endDate: string): Observable<any> {
    return this.post(
      environment.API_BASE_URL + '/Snapshot/location/' + id + '/user/dates',
      JSON.stringify({ "StartDay": startDate, "EndDay": endDate })
    )
  }
  public getAllLocationSnapshotByDate(startDate: string, endDate: string): Observable<any> {
    return this.post(
      environment.API_BASE_URL + '/Snapshot/location/all/user/dates',
      JSON.stringify({ "StartDay": startDate, "EndDay": endDate })
    )
  }
  public searchByTerm(term: string) {
    return this.get(environment.API_BASE_URL + '/Search/user?limit=5&term=' + term)
  }
  public getDeviceSecurityRecommendationByName(name: string) {
    return this.getSecurityRecommendationByTypeAndName('DEVICE', name)
  }
  public getSegmentSecurityRecommendationByName(name: string) {
    return this.getSecurityRecommendationByTypeAndName('SEGMENT', name)
  }
  public getSecurityRecommendationByTypeAndName(type: string, name: string) {
    return this.get(environment.API_BASE_URL + '/Recommendation/getByType/' + type + '/and/Name/' + name)
  }
  changeLocations(connectionData: any) {
    this.locationsBehaviorSubject.next(connectionData)
  }
  changeSegments(connectionData: any) {
    this.segmentsBehaviorSubject.next(connectionData)
  }
  changeFacility(connectionData: any) {
    this.facilityBehaviorSubject.next(connectionData)
  }
  changeLocation(connectionData: any) {
    this.locationBehaviorSubject.next(connectionData)
  }
  changeSegment(connectionData: any) {
    this.segmentBehaviorSubject.next(connectionData)
  }
  changeDevice(connectionData: any) {
    this.deviceBehaviorSubject.next(connectionData)
  }

  public changeGraph(graphData: Graph) {
    this.graphBehaviorSubject.next(graphData);
  }

  public changeMap(mapData: Map) {
    this.mapBehaviorSubject.next(mapData);
  }

  public changeIndexSnapshot(snapData: SnapshotData) {
    this.indexSnapshotBehaviorSubject.next(snapData);
  }

  public getIndexSnapshot() {
    return this.currentIndexSnapshot;
  }

  public changeRiskLevelSnapshot(snapData: SnapshotData) {
    this.riskLevelSnapshotBehaviorSubject.next(snapData);
  }

  public getRiskLevelSnapshot() {
    return this.currentRiskLevelSnapshot;
  }

  public changeRiskLevelDoughnut(riskLevelData: RiskLevel) {
    this.riskLevelDoughnutBehaviorSubject.next(riskLevelData);
  }

  public getRiskLevelDoughnut() {
    return this.currentRiskLevelDoughnut;
  }

  public changeCounter(counterData: any) {
    this.counterSubject.next(counterData);
  }

  public getCounter() {
    return this.currentCounter;
  }

  public getGraph() {
    return this.currentGraph;
  }
  public getMap() {
    return this.currentMap;
  }

  // public getLocations(): Observable<[Location]> {
  //   return this.currentLocations;
  // }

  public isLoggedInNew(): Observable<any> {
    //let sessionId = this.localStorageService.get("asvin");
    //const sessionId = "ojnfhch7vsvi5o141v4ubtli8m";
    const sessionId = this.cookieService.get(environment.SESSION_COOKIE_NAME)
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
    return this.http.post(
      environment.BEEHIVE_BASE_URL + '/api/rbc',
      JSON.stringify({ "sessionId": sessionId }),
      { 'headers': headers }
    )
  }
}
