<div class="container-fluid sidebar h-100 pt-3">
  <h6 class="fw-semibold p-4">MENU</h6>

  <ul class="main-menu">
    <!-- <li><a class="home"
          href="/dashboard" [routerLink]="['/dashboard']">Home</a></li> -->
    <li><a class="rbc"
            [routerLink]="['/dashboard']" [routerLinkActive]="['active']">Risk By Context&trade;</a></li>
    <li><a class="reports"
            [routerLink]="['/reports']" [routerLinkActive]="['active']">Reports</a></li>
    <li><a class="interfaces"
            [routerLink]="['/interfaces']" [routerLinkActive]="['active']">Interfaces</a></li>
    <li><a class="settings"
            [routerLink]="['/settings']" [routerLinkActive]="['active']">Settings</a></li>
    <!-- <li><a class="demo"
            [routerLink]="['/demo']" [routerLinkActive]="['active']">Demo</a></li> -->
    <li><a class="account"
            href="https://app.asvin.io/plan/my" target="_blank">My Account</a></li>
    <li><a class="support"
            href="https://app.asvin.io/support/get-started" target="_blank">Help</a></li>

    <li><a href="#" class="logout" (click)="logout()">Logout</a></li>
  </ul>
</div>
