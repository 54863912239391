import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-integration-token',
  templateUrl: './integration-token.component.html',
  styleUrls: ['./integration-token.component.scss']
})
export class IntegrationTokenComponent {
  @Output() integrationTokenStatus = new EventEmitter<boolean>();
  currentStep: number = 1;
  testingInterface = false;
  interfaceTested = false;
  addingInterface = false;
  interfaceAdded = false;
  accessTokenFormGroup: FormGroup;
  currentIntegrationMethod = "";
 
  constructor(private _formBuilder: FormBuilder, private loadingService: LoadingService) {
    this.accessTokenFormGroup = this._formBuilder.group({
      serverAddress: ['', [Validators.required, Validators.pattern('^https://\\S*$')]],
      accessToken: ['', Validators.required]
    });
  }

  get serverAddress() {
    return this.accessTokenFormGroup.get('serverAddress');
  }

  get accessToken() {
    return this.accessTokenFormGroup.get('accessToken');
  }

  setStep(step: number) {
    this.currentStep = step;
  }

  nextStep() {
    if (this.currentStep < 3) {
      this.currentStep++;
    }
    this.interfaceTested = false;
  }

  async addInterface() {
    if (this.currentStep < 2) {
      this.currentStep++;
    }
    this.interfaceTested = false;
    this.loadingService.addRequest();
    this.addingInterface = true
    await new Promise(r => setTimeout(r, 2000));
    this.interfaceAdded = true;
    this.integrationTokenStatus.emit(true);
    this.loadingService.removeRequest();
    this.addingInterface = false;
  }

  async testInterface() {
    this.loadingService.addRequest();
    this.testingInterface = true
    await new Promise(r => setTimeout(r, 2000));
    this.interfaceTested = true;
    this.loadingService.removeRequest();
    this.testingInterface = false;
  }

  previousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  resetSteps() {
    this.currentStep = 1;
  }

}
