
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">An error has occurred!</h4>
  <!-- <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button> -->
</div>
<div class="modal-body">
  <p *ngIf="data.status">
    <b>Status Code: {{ data.status }}</b>
  </p>
  <p>Something went wrong!</p>
  <p class="error-message">
    {{ data.message }}
  </p>
  <button type="button" (click)="closeDialog()">Close</button>
</div>
