<div class="card" style="width: 300px">
  <div class="card-header">{{ header }}</div>
  <div class="card-body row">
    <div class="col">
      <img
        [src]="imgPath"
        class="card-img-top"
        style="height: 64px;"
        alt="critical"
      />
    </div>
    <div class="col">
      <h2 class="card-text">{{ data }}</h2>
    </div>
  </div>
</div>
