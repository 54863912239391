<div class="modal-content bg-white" style="width: 900px">
  <div class="modal-header">
    <h4 class="modal-title">{{ name }}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <!-- <span aria-hidden="true">&times;</span> -->
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid p-0">

      <!-- integration using access token -->
      <div class="row">
        <div
          role="button"
          class="modal-header integration-header"
          (click)="toggleCollapse(1)"
        >
          <h3>Access Token</h3>
          <h3 *ngIf="!isCollapsed1">&#94;</h3>
          <h3 *ngIf="isCollapsed1">&#711;</h3>
        </div>
      </div>
      <div class="row mt-2" *ngIf="!isCollapsed1">
        <app-integration-token
          (integrationTokenStatus)="handleAddInterface($event)"
        ></app-integration-token>
      </div>

      <!-- integration using username/password -->
      <div class="row mt-2">
        <div
          role="button"
          class="modal-header integration-header"
          (click)="toggleCollapse(2)"
        >
          <h3>Username/Password</h3>
          <h3 *ngIf="!isCollapsed2">&#94;</h3>
          <h3 *ngIf="isCollapsed2">&#711;</h3>
        </div>
      </div>
      <div class="row mt-2" *ngIf="!isCollapsed2">
        <app-integration-password
          (integrationPasswordStatus)="handleAddInterface($event)"
        ></app-integration-password>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-green m-2"
        (click)="closeModal()"
        [disabled]="interfaceAdded"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-green m-2"
        (click)="interfaceSuccessful()"
        [disabled]="!interfaceAdded"
      >
        Save
      </button>
    </div>
  </div>
</div>
