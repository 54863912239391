import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { map, Observable, startWith, Subscription } from "rxjs";
import { Vulnerability } from "../../core/models/vulnerability";
import { VulnerabilityTableService } from "./vulnerability-table.service";
import { NgbdSortableHeader, VulnerabilitySortEvent } from "../../directives/sortable.directive";

@Component({
  selector: 'app-vulnerability-table',
  templateUrl: './vulnerability-table.component.html',
  styleUrls: ['./vulnerability-table.component.scss']
})
export class VulnerabilityTableComponent implements OnInit, OnDestroy {

  vulnerabilities$!: Observable<Vulnerability[]>;
  total$: Observable<number>;

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;

  constructor(
    public service: VulnerabilityTableService,
  ) {
    this.vulnerabilities$ = service.vulnerabilitys$;
    this.total$ = service.total$;
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  onSort({ column, direction }: VulnerabilitySortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
}
