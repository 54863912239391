<div class="row">
    <div class="col">
        <form>
            <div class="mb-3 row">
                <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Filter:</label>
                <div class="col-auto me-auto">
                    <input
                            id="table-complete-search"
                            type="text"
                            class="form-control"
                            name="searchTerm"
                            [(ngModel)]="service.searchTerm"
                    />
                </div>
                <div class="col-auto align-self-end">
                    <ngb-pagination
                            [collectionSize]="(total$ | async)!"
                            [(page)]="service.page"
                            [pageSize]="service.pageSize"
                            [maxSize]="10"
                            [rotate]="true"
                            [boundaryLinks]="true"
                    ></ngb-pagination>
                </div>
            </div>

            <table class="table table-striped table-hover">
                <thead>
                <tr>
                    <th scope="col" sortable="Score" (sort)="onSort($event)">RBC-Index</th>
                    <th scope="col" sortable="CveId" (sort)="onSort($event)">CVE ID</th>
                    <th scope="col" sortable="DatePublished" (sort)="onSort($event)">Date Published</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let vulnerability of vulnerabilities$| async; index as i">

                    <td>{{ vulnerability.Score | number: '1.1-1' }} / 10</td>
                    <td><ngb-highlight [result]=" vulnerability.CveId" [term]="service.searchTerm"></ngb-highlight></td>
                    <td>{{ vulnerability.DatePublished }}</td>
                </tr>
                </tbody>
            </table>
        </form>
    </div>
</div>
