import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  @Input() public data!: { message: string; status?: number };
  constructor(
    public activeModal: NgbActiveModal, private router: Router
  ) {
    console.log("in error dialog");
  }

  public closeDialog() {
    console.log("helo")
    this.activeModal.dismiss('Cross click');
    this.router.navigate(['/location']);
  }
}
