<div class="container-fluid p-4">
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs">
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">Location</a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
                        <a ngbDropdownItem href="#" (click)="$event.preventDefault();locationName = 'Stuttgart'">Stuttgart</a>
                        <a ngbDropdownItem href="#" (click)="$event.preventDefault();locationName = 'Munich'">Munich</a>
                        <a ngbDropdownItem href="#" (click)="$event.preventDefault();locationName = 'Cologne'">Cologne</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link disabled" href="#">Segments</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row shadow position-relative">
        <div class="col">
            <div class="row bg-white p-2 border-top">
                <div class="col me-auto">
                    <div class="location-name">
                        <h3 class="mb-0">{{locationName}}</h3>
                    </div>
                </div>
                <div class="col col-auto">
                    <app-spinner (onSpinnerClick)="refreshData(true)"></app-spinner>
                </div>
            </div>
            <div class="row p-2 bg-white">
                <div class="col-8 position-relative">
                    <div id="dashboard-canvas" class="h-100"></div>
                    <div id="menu" class="rounded bg-white shadow p-2">
                        <div>
                            <div id="detail-location-name" class="fw-bold">Location Name</div>
                            <div id="detail-segment-name">Segment Name</div>
                            <div>ASSETS: <span id="detail-segment-assets">Asset Count</span></div>
                            <div>RBC-INDEX: <span id="detail-rbc-index">RBX Index</span></div>
                        </div>
                    </div>
                </div>
                <div class="col-4 shadow bg-gray position-relative">
                    <div class="row position-absolute bg-gray w-100 h-100 p-2 overflow-auto" *ngIf="selectedSegment">
                        <div class="col mt-3">
                            <div class="row">
                                <div class="col-auto">
                                    <img *ngIf="selectedSegment.Score! > 7" ngSrc="assets/images/segment-circle-high.svg" height="64" width="64" alt="asset-image"/>
                                    <img *ngIf="selectedSegment.Score! > 3  && selectedSegment.Score! <= 7" ngSrc="assets/images/segment-circle-medium.svg" height="64" width="64" alt="asset-image"/>
                                    <img *ngIf="selectedSegment.Score! <= 3" ngSrc="assets/images/segment-circle-low.svg" height="64" width="64" alt="asset-image"/>
                                </div>
                                <div class="col me-auto">
                                    <h4>
                                        {{selectedSegment.Network}}
                                    </h4>
                                    <h4 *ngIf="selectedSegment.Score! > 7" class="text-danger">High Risk</h4>
                                    <h4 *ngIf="selectedSegment.Score! > 3  && selectedSegment.Score! <= 7" class="text-warning">Medium Risk</h4>
                                    <h4 *ngIf="selectedSegment.Score! <= 3" class="text-green">Low Risk</h4>
                                </div>
                                <div class="col-auto ">
                                    <button type="button" class="btn-close" aria-label="Close" (click)="selectedSegment=undefined"></button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col">
                                    <table class="table table-borderless table-gray">
                                        <thead class="border-bottom">
                                        <tr>
                                            <th>RBC-Index</th>
                                            <th>{{selectedSegment!.Score | number: '1.1-1'}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr class="border-bottom">
                                            <td>Network Group:</td>
                                            <td>{{selectedSegment!.NetworkGroup}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Type:</td>
                                            <td>{{selectedSegment!.NetworkType}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Address:</td>
                                            <td>{{selectedSegment!.NetworkAddress}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Location Id:</td>
                                            <td>{{selectedSegment!.NetworkLocationId}}</td>
                                        </tr>
                                        <tr class="border-bottom">
                                            <td>Located at:</td>
                                            <td>{{selectedSegment!.NetworkLocation}}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="row mt-5" >

                                <table class="table table-borderless table-gray" *ngIf="segmentHistory != undefined; else loading">
                                    <tbody >
                                    <tr *ngFor="let assetParam of segmentHistory!.Parameters">
                                        <td>{{assetParam.Name | translate}}:</td>
                                        <td>
                                            <span class="param-value text-green" role="button" (click)="rbcImage = assetParam.Name">{{assetParam.Score | number: '1.1-1'}}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>



                                <div class="col-auto m-auto rounded bg-white shadow p-2" *ngIf="rbcImage">
                                    <img src="/assets/images/{{rbcImage}}.jpg" width="200" height="200" alt="{{rbcImage | translate}}"/>
                                </div>
                                <ng-template #loading>
                                    <div class="placeholder-glow" aria-hidden="true">
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                        <span class="placeholder placeholder-lg col-12 rounded bg-secondary mb-2">&nbsp;</span>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="row justify-content-end">
                                <div class="col-auto">
                                    <button class="btn btn-green ps-3 pe-3 pt-2 pb-2 float-end mt-3" [routerLink]="['/dashboard/segment/', selectedSegment!.id]">Show Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col">
                            <h4 class="text-gray">LOCATION RISK-BY-CONTEXT</h4>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-7">
                            <canvas id="doughnutDashboardChart"></canvas>
                        </div>
                        <div class="col-5" id="donut-dashboard-legend" style="display: flex; align-items: center;">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <canvas id="historyDashboardChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="segments">
        <div class="col col-auto ps-0">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item" (click)="showAllNetworks()">
                    <a id="all-networks-tab" class="nav-link active">Show All</a>
                </li>
            </ul>
        </div>
        <div class="col col-auto">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item" (click)="showHighRiskNetworks()">
                    <a id="filter-high-tab" class="nav-link">
                        <img ngSrc="/assets/images/segment-circle-high.svg" alt="risk-high"
                             height="24" width="24"/>
                    </a>
                </li>
                <li class="nav-item" (click)="showMediumRiskNetworks()">
                    <a id="filter-medium-tab" class="nav-link">
                        <img ngSrc="/assets/images/segment-circle-medium.svg" alt="risk-medium"
                             height="24" width="24"/>
                    </a>
                </li>
                <li class="nav-item" (click)="showLowRiskNetworks()">
                    <a id="filter-low-tab" class="nav-link">
                        <img ngSrc="/assets/images/segment-circle-low.svg" alt="risk-low"
                             height="24" width="24"/>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col col-auto offset-1">
            <ul class="nav nav-tabs reversed">
                <li class="nav-item">
                    <a class="nav-link pt-1 pb-0" style="height: 42px;">
                        <input class="form-range" type="range" min="0.3" max="3" step="0.01" [(ngModel)]="zoomValue">
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row align-items-center mt-3">
        <div class="col col-3">
            <img ngSrc="assets/images/segment-circle-gray.svg" alt="Asset" height="100" width="100" />
            <span class="small ms-1">Segment Size > 50 Assets</span>
        </div>
        <div class="col col-3">
            <img ngSrc="assets/images/segment-circle-gray.svg" alt="Asset" height="75" width="75" />
            <span class="small ms-1">Segment Size 31-49 Assets</span>
        </div>
        <div class="col col-3">
            <img ngSrc="assets/images/segment-circle-gray.svg" alt="Asset" height="50" width="50" />
            <span class="small ms-1">Segment Size 1-30 Assets</span>
        </div>

    </div>
    <div class="row mt-5">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="true" class="nav-tabs">
            <li [ngbNavItem]="1">
                <a ngbNavLink>Segment Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-segment-table></app-segment-table>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" *ngIf="selectedSegment != undefined">
                <a ngbNavLink>Device Risk-By-Context</a>
                <ng-template ngbNavContent>
                    <app-device-table [selectedSegmentId]="selectedSegment!.id" (onDeviceSelect)="selectSegment($event)"> </app-device-table>
                </ng-template>
            </li>
        </ul>
    </div>
    <div class="row shadow position-relative">
        <div [ngbNavOutlet]="nav" class="pt-2 bg-white border-top"></div>
    </div>


</div>
