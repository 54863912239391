import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { LoggedInUser } from './core/models/user';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Risk By Context';

  isLoggedIn = false;
  constructor(private translate: TranslateService, private authService: AuthService) {
    translate.setDefaultLang('en');
    translate.use('en');

    // login simulation
    // this.authService.loginOld();
    // this.authService.isLoggedInNew();
    this.authService.getCurrentUser().subscribe((user: LoggedInUser) => {
      this.isLoggedIn = this.authService.isLoggedIn();
    })
  }
}
