<div class="container-fluid p-4">
  <div class="row">
    <div class="col ps-0">
      <ul class="nav nav-tabs border-bottom-0">
        <li class="nav-item">
          <a class="nav-link active">Interfaces</a>
        </li>
      </ul>
    </div>
    <div class="col col-auto pe-0">
      <input
        type="text"
        class="form-control"
        placeholder="Search..."
        style="min-width: 300px"
        [(ngModel)]="searchTerm"
        (input)="searchInterfaces()"
      />
    </div>
  </div>
  <div class="row shadow position-relative p-2">
    <table
      class="table table-borderless table-striped table-hover"
      style="font-size: 18px"
    >
      <thead class="border-bottom">
        <tr>
          <th role="button" (click)="sort('name')">Name</th>
          <th>Action</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let interface of pagedInterfaces| orderBy : key : reverse ; let i = index;"
          class="border-bottom"
        >
          <td>{{ interface.name }}</td>

          <td>
            <button
              class="btn btn-green ps-3 pe-3 pt-2 pb-2"
              (click)="addInterface(i)"
              *ngIf="!interface.status"
            >
              Add
            </button>
            <button
              class="btn btn-green ps-3 pe-3 pt-2 pb-2"
              (click)="deleteInterface(i)"
              *ngIf="interface.status"
            >
              Delete
            </button>
          </td>
          <td>{{ interface.createdAt }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
