import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { Location } from "../../core/models/location";
import { LocationSortEvent, NgbdSortableHeader } from "../../directives/sortable.directive";
import { LocationTableService } from "./location-table.service";
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-location-table',
  templateUrl: './location-table.component.html',
  styleUrls: ['./location-table.component.scss']
})
export class LocationTableComponent {
  // used in table placeholder
  public numberArray: number[] = Array.from({ length: 25 }, (_, index) => index + 1);
  locations$!: Observable<Location[]>;
  total$: Observable<number>;
  public isLocations = false;

  public isFacilityEnable: boolean = environment.FACILITY_ENABLE

  @Output() onLocationSelect: EventEmitter<any> = new EventEmitter();

  @ViewChildren(NgbdSortableHeader) headers!: QueryList<NgbdSortableHeader>;
  constructor(
    public service: LocationTableService
  ) {
    this.locations$ = service.locations$;
    this.total$ = service.total$;
  }

  ngOnInit() {

  }

  onSort({ column, direction }: LocationSortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  getImageSrc(risk: string) {
    if (this.isFacilityEnable) {
      return HelperService.imgSourceForLevelWithRisk('facility', risk);
    }
    else {
      return HelperService.imgSourceForLevelWithRisk('segment', risk);
    }
  }

  locationWasClicked(event: any) {
    this.onLocationSelect.emit(event)
  }

  indexToColor(score: number) {
    return HelperService.RBCScoreToRiskColor(score);
  }
}
