import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-add-interface',
  templateUrl: './add-interface.component.html',
  styleUrls: ['./add-interface.component.scss']
})
export class AddInterfaceComponent {
  @Input() name: string = "";
  currentStep: number = 1;
  interfaceAdded = false;
  isCollapsed1 = true;
  isCollapsed2 = true;
  currentIntegrationMethod = "";
  // public activeModal: NgbActiveModal,
  constructor(public activeModal: NgbActiveModal, private loadingService: LoadingService) {
  }

  toggleCollapse(sectionId: number): void {
    if (!this.interfaceAdded) {
      if (sectionId == 1) {
        this.isCollapsed1 = !this.isCollapsed1;
        if (!this.isCollapsed1) {
          this.isCollapsed2 = true;
        }
      } else if (sectionId == 2) {
        this.isCollapsed2 = !this.isCollapsed2;
        if (!this.isCollapsed2) {
          this.isCollapsed1 = true;
        }
      }
    }
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }

  interfaceSuccessful(): void {
    this.activeModal.close({ success: true});
  }

  handleAddInterface(interfaceAdded: boolean): void {
    if (interfaceAdded) {
      this.interfaceAdded = true;
    }
    else {
      this.interfaceAdded = false;
    }
  }
}
