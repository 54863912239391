import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, catchError, of, throwError } from 'rxjs';
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // check if request URL contains Token
    if (request.url.indexOf('Token') == -1) { //no Token
      const idToken = this.authService.getRequestToken()
      if (idToken) {
        const cloned = request.clone({
          headers: request.headers.set("Authorization",
            "Bearer " + idToken)
        });
        // forward request with token
        return next.handle(cloned).pipe(
          catchError(x => this.handleAuthError(x))
        );
      } else {
        // forward request without token and handle auth errors
        return next.handle(request).pipe(
          catchError(x=> this.handleAuthError(x))
        );
      }
    } else { //Token request
      // forward request to server
      return next.handle(request);
    }
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {

    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      // this.authService.refreshToken(() => {
      //   window.location.reload()
      // })
      //this.router.navigateByUrl('/login');
      this.authService.logout();
      return of(null);
    }
    return throwError(() => err);
  }
}
