import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Graph } from "../core/models/graph";
import { Map } from '../core/models/map';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  static getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  static getDateString(subtractionDays: number = 0): string {
    if (environment.DEMO_ENABLE) {
      if (subtractionDays)
        return '2024-01-06';
      else
        return '2024-01-13';
    }
    else {
      const currentDate = new Date();
      const date = new Date(currentDate.getTime() - ((subtractionDays - 1) * 24 * 60 * 60 * 1000));
      const day = ("0" + (date.getDate())).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      return `${date.getFullYear()}-${month}-${day}`;
    }
  }

  static getLastWeekDates(): string[] {
    const today = new Date();
    const lastWeekDates: string[] = [];

    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);

      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      lastWeekDates.push(`${month}-${day}`);
    }
    return lastWeekDates;
  }

  /**
   *
   * @param unFormattedData - Unformatted Graph data
   * @param type - Type of data (Locations, Location, Segment)
   * @returns {Graph} - Graph data consist of Nodes and Links
   */
  static formateGraphData(unFormattedData: any, type: string): Graph {
    console.log("unformatted data", unFormattedData);
    var graph: Graph = { id: '', nodes: [], links: [] };
    var nodeIndex = 0, linkIndex = 0;
    if (type == "Segment") {
      for (let i = 0; i < unFormattedData.DeviceList.length; i = i + 1) {
        const device = unFormattedData.DeviceList[i];
        //console.log("facility", facility)
        graph.nodes[nodeIndex++] = { id: device.id, name: device.DeviceRef, score: device.Score, depth: 1, riskLevel: this.RBCIndexToRiskLevel(device.Score), type: "device", children: device.Low + device.Medium + device.High };
        for (let j = i + 1; j < unFormattedData.DeviceList.length; j = j + 3) {
          const targetDevice = unFormattedData.DeviceList[j];
          //console.log("facility2", targetFacility)
          graph.links[linkIndex++] = { source: device.id, target: targetDevice.id, color: this.RBCScoreToRiskColor(Math.max(device.Score, targetDevice.Score)) };
        }
      }
      if (unFormattedData.id == '64c7c5e07c53ed8f1087410c') {
        graph.links = [{ source: '6582f1d46d722433c2f7530d', target: '6582f1d46d722433c2f75301', color: '#D10C15' },
        { source: '6582f1d46d722433c2f7530d', target: '6582f1d46d722433c2f752ec', color: '#D10C15' },
        {source: '6582f1d46d722433c2f7530d', target: '6582f1d46d722433c2f752e3', color: '#D10C15'},
        {source: '6582f1d36d722433c2f752ce', target: '6582f1d36d722433c2f752db', color: '#F19100'},
        {source: '6582f1d46d722433c2f7530d', target: '6582f1d36d722433c2f752d4', color: '#D10C15'},
        {source: '6582f1d46d722433c2f7530d', target: '6582f1d36d722433c2f752c9', color: '#D10C15'},
        {source: '6582f1d36d722433c2f752d4', target: '6582f1d46d722433c2f752e3', color: '#D10C15'},
        {source: '6582f1d46d722433c2f752f6', target: '6582f1d36d722433c2f752db', color: '#AFCA0B'},
        {source: '6582f1d36d722433c2f752c9', target: '6582f1d46d722433c2f752e3', color: '#F19100'},
        {source: '6582f1d36d722433c2f752ce', target: '6582f1d46d722433c2f752e3', color: '#F19100'},
        {source: '6582f1d36d722433c2f752ce', target: '6582f1d46d722433c2f7530d', color: '#D10C15'},
        { source: '6582f1d46d722433c2f75301', target: '6582f1d46d722433c2f752ec', color: '#F19100' },
        ]
      }
      console.log("graphin ", graph);
    }
    if (type == "Facility") {
      for (let i = 0; i < unFormattedData.SegmentList.length; i = i + 1) {
        const segment = unFormattedData.SegmentList[i];
        //console.log("segment", segment)
        //console.log("segment", segment.Devices)

        // add facility id in the graph when facility level is disabled
        // it is used later in graph component for navigation
        if (!environment.FACILITY_ENABLE) {
          graph.id = unFormattedData.id;
        }
        graph.nodes[nodeIndex++] = { id: segment.id, name: segment.Network, score: segment.Score, depth: 1, riskLevel: this.RBCIndexToRiskLevel(segment.Score), type: "segment", children: segment.Low + segment.Medium + segment.High };
        for (let j = i + 1; j < unFormattedData.SegmentList.length; j = j + 4) {
          const targetSegment = unFormattedData.SegmentList[j];
          //console.log("facility2", targetFacility)
          graph.links[linkIndex++] = { source: segment.id, target: targetSegment.id, color: this.RBCScoreToRiskColor(Math.max(segment.Score, targetSegment.Score)) };
        }
      }
      console.log("graphin ", graph);
    }
    else if (type == "Location") {
      for (let i = 0; i < unFormattedData.FacilityList.length; i = i + 1) {
        const facility = unFormattedData.FacilityList[i];
        //console.log("facility", facility)
        graph.nodes[nodeIndex++] = { id: facility.id, name: facility.Name, score: facility.Score, depth: 1, riskLevel: this.RBCIndexToRiskLevel(facility.Score), type: "facility", children: facility.Low + facility.Medium + facility.High };
        for (let j = i + 1; j < unFormattedData.FacilityList.length; j = j + 4) {
          const targetFacility = unFormattedData.FacilityList[j];
          //console.log("facility2", targetFacility)
          graph.links[linkIndex++] = { source: facility.id, target: targetFacility.id, color: this.RBCScoreToRiskColor(Math.max(facility.Score, targetFacility.Score)) };
        }
      }
      //console.log("graphin ", graph);
    }
    else if (type == "Locations") {
      unFormattedData.forEach((location: any) => {
        graph.nodes[nodeIndex] = { id: location.id, name: location.Name, score: unFormattedData.Score, depth: 0, riskLevel: this.RBCIndexToRiskLevel(location.Score) };
        nodeIndex = nodeIndex + 1;
        location.FacilityList.forEach((facility: any) => {
          graph.nodes[nodeIndex] = { id: facility.id, name: facility.Name, score: unFormattedData.Score, depth: 1, riskLevel: this.RBCIndexToRiskLevel(facility.Score) };
          nodeIndex = nodeIndex + 1;
          graph.links[linkIndex] = { source: location.id, target: facility.id, color: this.RBCScoreToRiskColor(Math.max(location.Score, facility.Score)) };
          linkIndex = linkIndex + 1;
        })
      })
    }
    //console.log("graph in helper", graph);
    return graph;
  }

  /**
   *
   * @param locations - Unformatted Graph data
   * @returns {Graph} - Map data consist of Vertices and Edges
   */
  static formateWorldData(locations: any): Map {
    //console.log("unformatted data", locations);
    var map: Map = { vertices: [], edges: [] };
    var nodeIndex = 0

    locations.forEach((location: any) => {
      map.vertices[nodeIndex++] = { id: location.id, name: location.Name, score: location.Score, riskLevel: this.RBCIndexToRiskLevel(location.Score), coordinates: location.Coordinates };
    })

    for (let i = 0; i < map.vertices.length; i = i + 3) {
      for (let j = i + 1; j < map.vertices.length; j = j + 4) {
        map.edges.push([map.vertices[i].coordinates, map.vertices[j].coordinates]);
      }
    }

    //console.log("graph in helper", map);
    return map;
  }

  /**
   * Map RBC-Index to risk level
   *
   * @param score - RBC Index
   * @returns {string} - Risk level (low, medium, high)
   */
  static RBCIndexToRiskLevel(score: number): string {
    if (score < 4) {
      return "low";
    }
    else if (score < 7) {
      return "medium";
    }
    else {
      return "high";
    }
  }


  /**
   * Map RBC-Index to Img source
   *
   * @param score - RBC Index
   * @param level - level (device, segment, facility, location)
   * @returns {string} - Image source
   */
  static RBCIndexToImageSrc(score: number, level: string): string {
    const imgFolder = "assets/images/";
    if (level == "vulnerability") {
      return `${imgFolder}vulnerability-${this.RBCIndexToRiskLevel(score)}.svg`
    }
    else if (level == "device") {
      return `${imgFolder}device-${this.RBCIndexToRiskLevel(score)}.svg`
    }
    else if (level == "segment") {
      return `${imgFolder}segment-${this.RBCIndexToRiskLevel(score)}.svg`
    }
    else if (level == "facility") {
      return `${imgFolder}facility-${this.RBCIndexToRiskLevel(score)}.svg`
    }
    else {
      return `${imgFolder}location-${this.RBCIndexToRiskLevel(score)}.svg`;
    }
  }

  /**
 * Map RBC-Index to color
 *
 * @param score - RBC Score
 * @returns {string} - color hash
 */
  static RBCScoreToRiskColor(score: number): string {
    const colors = ['#AFCA0B', '#F19100', '#D10C15'];
    if (score < 4) {
      return colors[0];
    }
    else if (score < 7) {
      return colors[1];
    }
    else {
      return colors[2];
    }
  }

  /**
* Build img path based on type
*
* @param level - level (device, segment, facility, location)
* @param risk - risk (high, medium, low)
* @returns {string} - img path
*/
  static imgSourceForLevelWithRisk(level: string, risk: string): string {
    const imgFolder = "assets/images/";
    if (level == "vulnerability") {
      return `${imgFolder}vulnerability-${risk}.svg`
    }
    else if (level == "device") {
      return `${imgFolder}device-${risk}.svg`
    }
    else if (level == "segment") {
      return `${imgFolder}segment-${risk}.svg`
    }
    else if (level == "facility") {
      return `${imgFolder}facility-${risk}.svg`
    }
    else {
      return `${imgFolder}location-${risk}.svg`;
    }
  }
}

