import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string = '';
  password: string = '';
  isLoginError = false;

  constructor(private authService: AuthService, private router: Router, private localStorageService: LocalStorageService) {

  }
  login() {
    this.authService.login(this.email, this.password).subscribe({
      next: (user: any) => {
        //console.log("user", user)
        if (user.Token) {
          this.localStorageService.set("authToken", user.Token)
          this.authService.changeCurrentUser(user);
          this.router.navigateByUrl('/dashboard');
        }
        else {
          throwError(() => new Error("login error!"))
        }
      },
      error: (error: any) => { this.isLoginError = true; return throwError(() => error) }
    })
  }
}
