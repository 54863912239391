import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterLink, RouterLinkActive } from "@angular/router";



@NgModule({
  declarations: [
    SideBarComponent,
    HeaderComponent
  ],
  exports: [
    HeaderComponent,
    SideBarComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    RouterLinkActive
  ]
})
export class CoreModule { }
