import { Component } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { Router } from "@angular/router";
import { SearchResult } from "../../core/models/search-result";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  locationFilter = true
  segmentFilter = true
  facilityFilter = true
  deviceFilter = true
  vulnerabilityFilter = true
  searchResult?: SearchResult

  constructor(
    private router: Router,
    private apiService: ApiService,
  ) {
  }

  public onSearchInput(event: Event) {
    let searchTerm = (event.target as HTMLInputElement).value
    if (searchTerm.length > 0) {
      this.apiService.searchByTerm(searchTerm)
        .subscribe( response => {
          this.searchResult = response
        });
    } else {
      this.searchResult = undefined
    }
  }

  onLocationSelect($event: any) {
    $event.preventDefault()
    const id = $event.target.getAttribute('data-id')
    this.apiService.getLocationById(id).subscribe(
      value => this.apiService.changeLocation(value.Data)
    )
    this.router.navigate(['/dashboard/', id])
  }

  onFacilitySelect($event: any) {
    $event.preventDefault()
    const id = $event.target.getAttribute('data-id')

    let selectedFacility = this.searchResult!.Facilities.filter(obj => {
      return obj.id === id;
    })[0]

    this.apiService.getFacilityById(id).subscribe(
      value => this.apiService.changeFacility(value.Data)
    )
    this.router.navigate(['/dashboard/', selectedFacility.LocationId, selectedFacility.id])
  }

  onSegmentSelect($event: any) {
    $event.preventDefault()
    const id = $event.target.getAttribute('data-id')

    let selectedSegment = this.searchResult!.Segments.filter(obj => {
      return obj.id === id;
    })[0]

    this.apiService.getSegmentById(id).subscribe(
      value => this.apiService.changeSegment(value.Data)
    )
    this.router.navigate(['/dashboard/', selectedSegment.LocationId, selectedSegment.FacilityId, selectedSegment.id])
  }

  onDeviceSelect($event: any) {
    $event.preventDefault()
    const id = $event.target.getAttribute('data-id')

    let selectedDevice = this.searchResult!.Devices.filter(obj => {
      return obj.id === id;
    })[0]

    this.apiService.getSegmentById(selectedDevice.SegmentId).subscribe(
      value => this.apiService.changeSegment(value.Data)
    )

    this.router.navigate(['/dashboard/', selectedDevice.LocationId, selectedDevice.FacilityId, selectedDevice.SegmentId, selectedDevice.id])
  }



}
