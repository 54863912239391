import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Device } from "../../core/models/device";
import { Segment } from "../../core/models/segment";
import { ApiService } from "../../services/api.service";
import { LoadingService } from '../../services/loading.service';
import { ToastService } from "../../services/toast.service";

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})

export class DeviceComponent implements OnInit, OnDestroy {

  selectedSegment!: Segment;
  selectedAsset!: Device;
  private segmentId!: string;
  assetId!: string;
  private segmentSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastService,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.segmentId = this.route.snapshot.paramMap.get('segmentId')!
    this.assetId = this.route.snapshot.paramMap.get('assetId')!

    this.segmentSubscription = this.apiService.currentSegments.subscribe(
      response => {
        if (response.length != undefined && response.length > 0) {
          this.selectedSegment = response.filter(obj => {
            return obj.id === this.segmentId;
          })[0]
          this.selectedAsset = this.selectedSegment.DeviceList.filter(obj => {
            return obj.id === this.assetId;
          })[0]
          this.apiService.changeDevice(this.selectedAsset);
        } else {
          this.refreshData()
        }
      }
    )
  }

  ngOnDestroy() {
    this.segmentSubscription.unsubscribe();
  }

  refreshData(manual = false) {
    this.loadingService.addRequest();
    this.apiService.getSegmentById(this.segmentId)
      .subscribe(response => {
        this.selectedSegment = response
        this.apiService.changeSegment(response);

        this.selectedAsset = this.selectedSegment.DeviceList.filter(obj => {
          return obj.id === this.assetId;
        })[0]

        this.apiService.changeDevice(this.selectedAsset);
        if (manual) {
          this.toastService.showSuccess('Updating successful');
        }
        this.loadingService.removeRequest();
      });
  }
}
