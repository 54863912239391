import { Injectable } from '@angular/core';
import { jsPDF } from "jspdf";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PDFGeneratorService {

  private pdfGeneratedBS: BehaviorSubject<string> = new BehaviorSubject<string>("");
  currentPdfGenerated$: Observable<string> = this.pdfGeneratedBS.asObservable();
  constructor() { }

  generateHeader(doc: jsPDF) {
    doc.setFontSize(18);
    doc.setTextColor(40);
    //doc.setFon('bold');
    doc.text('RISK BY CONTEXT™ - REPORT', 10, 10);
    doc.line(10, 15, 200, 15);
    doc.setFontSize(12);
    doc.text('customer | account | report generated - Germany', doc.internal.pageSize.width / 2, 25, { align: 'center' });
    doc.line(10, 30, 200, 30);
  }

  // Function to generate footer
  generateFooter(doc: jsPDF, pageNumber: number, totalPages: number) {
    const footerText = `Page ${pageNumber} of ${totalPages}`;
    doc.setFontSize(10);
    doc.line(10, doc.internal.pageSize.height - 15, 200, doc.internal.pageSize.height - 15);
    doc.text(`RISK BY CONTEXT ™ - REPORT | powered by asvin GmbH | page ${pageNumber}/${totalPages}`, doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 10, { align: 'center' });
  }
  generateReport(data: any) {
    const doc = new jsPDF();
    doc.setFont('proxima_novaregular');
    this.generateHeader(doc)
    this.generateFooter(doc, 1, 1)
    const pdfDataUri = doc.output('datauristring');

    // Emit the PDF data
    this.pdfGeneratedBS.next(pdfDataUri);
    // Save PDF
    //doc.save('report.pdf');

    // // Open the PDF in a new browser tab or window
    // const newWindow = window.open();
    // newWindow!.document.write('<iframe width="100%" height="100%" src="' + pdfDataUri + '"></iframe>');
  }

  getCurrentPdfDataUri() {
    return this.currentPdfGenerated$;
  }

  setCurrentPdfDataUri(dataUri: string) {
    this.pdfGeneratedBS.next(dataUri);
  }

}
