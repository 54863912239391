<div class="container-fluid p-4">
    <div class="row">
        <div class="col ps-0">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/dashboard']">Location</a>
                </li>
                <li class="nav-item" *ngIf="selectedSegment">
                    <a class="nav-link" [routerLink]="['/segment', selectedSegment.id]">Segments</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active">< Devices</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row shadow position-relative">
        <div class="col">
            <div class="row bg-white p-2 border-top">
                <div class="col">
                    <div class="location-name" *ngIf="selectedAsset != undefined">
                        <h3 class="mb-0">Stuttgart / {{selectedSegment.Network}} / {{selectedAsset.DeviceRef}}</h3>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center bg-white">
                <div class="col-3 mt-3">
                    <div class="row" *ngIf="selectedAsset != undefined">
                        <div class="col-auto">
                            <img *ngIf="selectedAsset.Score! > 7" ngSrc="assets/images/vulnerability-detail-high.svg" height="64" width="64" alt="asset-image"/>
                            <img *ngIf="selectedAsset.Score! > 3  && selectedAsset.Score! <= 7" ngSrc="assets/images/vulnerability-detail-medium.svg" height="64" width="64" alt="asset-image"/>
                            <img *ngIf="selectedAsset.Score! <= 3" ngSrc="assets/images/vulnerability-detail-low.svg" height="64" width="64" alt="asset-image"/>
                        </div>
                        <div class="col">
                            <h4>
                                {{selectedAsset.DeviceRef}}
                            </h4>
                            <h4 *ngIf="selectedAsset.Score! > 7" class="text-danger">High Risk</h4>
                            <h4 *ngIf="selectedAsset.Score! > 3  && selectedAsset.Score! <= 7" class="text-warning">Medium Risk</h4>
                            <h4 *ngIf="selectedAsset.Score! <= 3" class="text-green">Low Risk</h4>
                        </div>
                    </div>
                    <div class="row mt-5" *ngIf="selectedAsset != undefined">
                        <table class="table table-borderless">
                            <thead class="border-bottom">
                            <tr>
                                <td>RBC-Index</td>
                                <td><a class="text-green" [routerLink]="['/segment', selectedSegment.id, 'asset', selectedAsset.id, 'history']">{{selectedAsset.Score | number: '1.1-1'}}</a></td>
                            </tr>
                            </thead>
                            <tbody class="border-bottom">
                              <!-- <tr>
                                   <td>Device Ref:</td>
                                   <td>{{selectedAsset.DeviceRef}}</td>
                                   </tr> -->
                            <tr>
                                <td>Device ID:</td>
                                <td>{{selectedAsset.DeviceId}}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td><h4>CVEs</h4></td>
                                <td><h4>{{selectedAsset.Vulnerabilities?.length || 0}}</h4></td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row align-items-center mt-3">
        <div class="col pe-0">
            <button class="btn btn-green ps-5 pe-5 pt-3 pb-3 float-end" (click)="refreshData(true)">Refresh</button>
        </div>
    </div>
    <div class="row mt-5">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link active">Device Risk-By-Context</a>
            </li>
        </ul>
    </div>
    <div class="row shadow position-relative">
        <div class="pt-2 bg-white border-top">
            <app-vulnerability-table></app-vulnerability-table>
        </div>
    </div>
</div>
